import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/list/consts/apiRoutes';
import { mockedCampaignDetails } from '@shared/mocks/requests/campaignDetails/campaignDetails.fixtures';

import { mockedCampaignsList } from './campaignsList.fixtures';

export const campaignsListRequests = [
  http.get(`*${apiRoutes.getCampaigns()}`, () => {
    return HttpResponse.json(mockedCampaignsList);
  }),
  http.post(`*${apiRoutes.duplicateCampaign(':campaignId')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
];
