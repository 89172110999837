import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import ErrorPage from '@shared/containers/ErrorPage/ErrorPage.tsx';
import { RolesDetailsProvider } from '@shared/providers/RolesDetailsProvider/RolesDetailsProvider';
import { UserDetailsProvider } from '@shared/providers/UserDetailsProvider/UserDetailsProvider.tsx';

function AuthWrapper() {
  const logError = (error: Error) => {
    console.error(error);
  };

  return (
    <ErrorBoundary fallback={<ErrorPage />} onError={logError}>
      <RolesDetailsProvider>
        <UserDetailsProvider>
          <Outlet />
        </UserDetailsProvider>
      </RolesDetailsProvider>
    </ErrorBoundary>
  );
}

export default AuthWrapper;
