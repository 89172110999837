import { Dispatch, Reducer as ReducerType } from 'react';

import { ValueOf } from '@shared/types/common';
import { DataTableStateWithFilters } from '@shared/types/table';

export type CampaignListReducerState = DataTableStateWithFilters & { campaignsCount?: number };

export const Actions = {
  CHANGE_BASIC_TABLE_DATA: 'change-basic-table-data',
  CHANGE_STATUS_FILTER: 'change-status-filter',
  CHANGE_START_DATE_FILTER: 'change-start-date-filter',
  CHANGE_END_DATE_FILTER: 'change-end-date-filter',
  CHANGE_FORMAT_FILTER: 'change-format-filter',
  CHANGE_SEARCH_QUERY: 'change-search-query',
  CLEAR_FILTERS: 'clear-filters',
  CHANGE_CAMPAIGNS_COUNT: 'change-campaigns-count',
} as const;

type Action = ValueOf<typeof Actions>;

export interface CampaignListReducerAction {
  type: Action;
  payload?: Partial<CampaignListReducerState>;
}

export type Reducer = ReducerType<CampaignListReducerState, CampaignListReducerAction>;

export interface ReducerProps {
  state: CampaignListReducerState;
  dispatch: Dispatch<CampaignListReducerAction>;
}
