import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/performance/consts/apiRoutes';
import {
  mockedAllFormatsReportingData,
  mockedDisplayFormatReportingData,
  mockedImpressionsDistributionData,
  mockedVideoFormatReportingData,
  mockedGeneralReportingData,
  mockedTopChartsData,
} from '@shared/mocks/requests/reporting/reporting.fixtures';

export const reportingRequests = [
  http.get(`*${apiRoutes.getCampaignPerformanceAllType(':id')}`, () => {
    return HttpResponse.json(mockedAllFormatsReportingData);
  }),
  http.get(`*${apiRoutes.getCampaignReportingGeneral(':id')}`, () => {
    return HttpResponse.json(mockedGeneralReportingData);
  }),
  http.get(`*${apiRoutes.getCampaignPerformanceVideoType(':id')}`, () => {
    return HttpResponse.json(mockedVideoFormatReportingData);
  }),
  http.get(`*${apiRoutes.getCampaignPerformanceDisplayType(':id')}`, () => {
    return HttpResponse.json(mockedDisplayFormatReportingData);
  }),
  http.get(`*${apiRoutes.getCampaignImpressionsDistribution(':id')}`, () => {
    return HttpResponse.json(mockedImpressionsDistributionData);
  }),
  http.get(`*${apiRoutes.getCampaignTopCharts(':id')}`, () => {
    return HttpResponse.json(mockedTopChartsData);
  }),
];
