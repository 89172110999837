import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface DropdownFooterTemplateProps {
  currentItemsCount?: number;
  totalItemsCount?: number;
}
export function DropdownFooterTemplate({ currentItemsCount = 0, totalItemsCount = 0 }: DropdownFooterTemplateProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className='dropdown-footer-template-wrapper'>
      <span className='results'>
        {currentItemsCount} {t('builder.formFields.common.of')} {totalItemsCount}
      </span>{' '}
      {t('builder.formFields.common.results')}
    </div>
  );
}
