import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess.tsx';
import ExportButton from '@features/campaigns/details/shared/components/ExportButton/ExportButton.tsx';
import { ExportMenu } from '@features/campaigns/details/shared/components/ExportMenu/ExportMenu.tsx';
import commonStyles from '@features/campaigns/shared/components/ExportMenu/ExportMenu.module.scss';
import ExportMenuItem from '@features/campaigns/shared/components/ExportMenu/ExportMenuItem/ExportMenuItem.tsx';
import {
  DownloadedFileExtension,
  DownloadedFileTypes,
} from '@features/campaigns/shared/types/exportFileDownloadType.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { Permissions } from '@shared/consts/permissions.ts';
import { useExportFileDownload } from '@shared/hooks/useExportFileDownload.ts';

export function ExportReport() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { setDownloadFileExtension, isLoading } = useExportFileDownload({
    fileType: DownloadedFileTypes.REPORT,
  });
  const menu = useRef<Menu>(null);

  const handleOnMenuItemClick = (fileExtension: DownloadedFileExtension) => {
    setDownloadFileExtension(fileExtension);
  };

  const itemRenderer = (item: MenuItem) => (
    <AllowAccess
      requiredPermissions={[
        Permissions.CAMPAIGN_EXPORT_ALL,
        Permissions.CAMPAIGN_EXPORT_OWNED,
        Permissions.CAMPAIGN_EXPORT_ASSIGNED_ADVERTISERS,
      ]}
    >
      <ExportMenuItem
        item={item}
        handleOnClick={() => handleOnMenuItemClick(item.data.fileExtension)}
        menuReference={menu}
      />
    </AllowAccess>
  );

  const items = [
    {
      items: [
        {
          label: t('page.campaignDetails.campaignDetailsTab.exportOptions.pptx'),
          className: commonStyles.menuItem,
          template: itemRenderer,
          data: {
            fileExtension: 'pptx',
          },
        },
        {
          label: t('page.campaignDetails.campaignDetailsTab.exportOptions.xlsx'),
          className: commonStyles.menuItem,
          template: itemRenderer,
          data: {
            fileExtension: 'xlsx',
          },
        },
      ],
    },
  ];

  return (
    <>
      <ExportButton
        menuReference={menu}
        isLoading={isLoading}
        label='page.campaignDetails.performanceTab.exportReportButton'
        dataTestId='performance-export-report-button'
      />
      <ExportMenu menuItems={items} menuRef={menu} dataTestId='export-report-menu' />
    </>
  );
}
