import { ValueOf } from './common';

export const ConfigResponseTypes = {
  JSON: 'json',
  ARRAY_BUFFER: 'arraybuffer',
  DOCUMENT: 'stream',
  BLOB: 'blob',
} as const;

export type ConfigResponseType = ValueOf<typeof ConfigResponseTypes>;

export const HttpMethods = {
  Get: 'GET',
  Post: 'POST',
  Patch: 'PATCH',
  Delete: 'DELETE',
  Put: 'PUT',
} as const;

export type HttpMethod = ValueOf<typeof HttpMethods>;
