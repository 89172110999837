import { DropdownItem } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { Advertiser } from '@features/campaigns/shared/types/campaign';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';

export const getNextParamFn =
  (debouncedQueryValue: string | undefined) =>
  (lastPage: AdManagerApiResponse<DropdownItem[] | Advertiser[]> | null) => {
    if (lastPage?.meta) {
      const { currentPage, totalPages } = lastPage.meta;
      const nextPage = currentPage + 1;

      return {
        page: nextPage <= totalPages ? nextPage : totalPages,
        search: debouncedQueryValue,
      };
    }
  };
