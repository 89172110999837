import { Dispatch, Reducer as ReducerType } from 'react';

import { ValueOf } from '@shared/types/common';
import { DataTableState } from '@shared/types/table';

export type ExternalUsersListReducerState = DataTableState & { usersCount?: number };

export const Actions = {
  CHANGE_BASIC_TABLE_DATA: 'change-basic-table-data',
  CHANGE_SEARCH_QUERY: 'change-search-query',
  CHANGE_USERS_COUNT: 'change-campaigns-count',
} as const;

type Action = ValueOf<typeof Actions>;

export interface ExternalUsersListReducerAction {
  type: Action;
  payload?: Partial<ExternalUsersListReducerState>;
}

export type Reducer = ReducerType<ExternalUsersListReducerState, ExternalUsersListReducerAction>;

export interface ReducerProps {
  state: ExternalUsersListReducerState;
  dispatch: Dispatch<ExternalUsersListReducerAction>;
}
