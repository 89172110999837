import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldErrorMessage } from '@features/campaigns/builder/components/FormFields/common/FieldErrorMessage/FieldErrorMessage';
import { EnhancedDropdown } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes.ts';
import { QUERY_KEYS } from '@features/campaigns/details/consts/queryKeys.ts';
import { fetchAgencies } from '@features/campaigns/details/services/formFields.ts';
import { DropdownWithSearch } from '@features/campaigns/shared/components/FormFields/Dropdown/components/DropdownWithSearch/DropdownWithSearch.tsx';
import { getNextParamFn } from '@features/campaigns/shared/components/FormFields/Dropdown/utils/getNextParamFn.ts';
import styles from '@features/campaigns/shared/components/FormFields/FormFields.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { useDebounce } from '@shared/hooks/useDebounce';

interface FieldAgencyProps {
  clearSelectedValuesHandler: () => void;
  restoredInputValue?: string;
}
export function FieldAgency({ restoredInputValue, clearSelectedValuesHandler }: FieldAgencyProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const componentRef = useRef<EnhancedDropdown>(null);
  const { control } = useFormContext();
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [searchQueryValue, setSearchQueryValue] = useState<string | undefined>(undefined);
  const debouncedQueryValue = useDebounce(searchQueryValue);

  const { data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.AGENCIES, debouncedQueryValue],
    queryFn: (queryParams) => {
      const search = debouncedQueryValue;
      const page = queryParams?.pageParam?.page || 1;

      return fetchAgencies({ pageParam: { page, search } });
    },
    initialPageParam: { page: 1, search: debouncedQueryValue },
    getNextPageParam: getNextParamFn(debouncedQueryValue),
    refetchOnMount: false,
    gcTime: 0,
  });

  return (
    <div className={styles.fieldWrapper}>
      <label htmlFor={BillingFormFieldTypes.AGENCY} className={styles.fieldLabel}>
        {t('page.billingForm.agencyField.label')}
      </label>
      <Controller
        control={control}
        name={BillingFormFieldTypes.AGENCY}
        render={({ field, formState: { errors } }) => {
          return (
            <>
              <DropdownWithSearch
                fieldName={BillingFormFieldTypes.AGENCY}
                componentRef={componentRef}
                placeholder={t('page.billingForm.agencyField.placeholder')}
                data={data}
                isFetching={isFetching}
                inputValue={inputValue}
                setInputValue={setInputValue}
                restoredInputValue={restoredInputValue}
                setSearchQueryValue={setSearchQueryValue}
                clearSelectedValuesHandler={clearSelectedValuesHandler}
                fieldController={field}
                fetchNextPage={fetchNextPage}
              />
              {errors?.[BillingFormFieldTypes.AGENCY] && (
                <FieldErrorMessage fieldErrorData={errors} fieldName={BillingFormFieldTypes.AGENCY} />
              )}
            </>
          );
        }}
      />
    </div>
  );
}
