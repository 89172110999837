import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '@shared/components/Icon/Icon';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  isFiltering?: boolean;
}

export function EmptyState({ isFiltering }: EmptyStateProps) {
  const { t } = useTranslation(i18nNameSpace.USERS);

  return (
    <div className={styles.emptyStateWrapper}>
      <Icon variant={'closeGray'} iconAltText={t('page.list.usersNotFound')} stylesClassName={'emptyStateIcon'} />
      <p className={styles.emptyStateTitle}>{t('page.list.usersNotFound')}</p>
      {isFiltering && (
        <p className={cn(styles.emptyStateSubtitle, isFiltering && styles.isFilteringEmptyState)}>
          {t('page.list.usersNotFoundActionSubtitle')}
        </p>
      )}
    </div>
  );
}
