import tvFrameImage from '@features/campaigns/builder/assets/images/creative-preview-tv-frame-opt.png';

import styles from './PreviewTVSimpleWrapper.module.scss';

interface PreviewTVSimpleWrapperProps {
  imageUrl: string;
}
export function PreviewTVSimpleWrapper({ imageUrl }: PreviewTVSimpleWrapperProps) {
  return (
    <div className={styles.container}>
      <img src={tvFrameImage} alt={'tv-frame'} className={styles.tvFrameBackground} />

      <div className={styles.creativePreviewBox}>
        <img src={imageUrl} alt={'creative-preview'} className={styles.creativeImage} />
      </div>
    </div>
  );
}
