import { TFunction } from 'i18next';
import { SelectItem } from 'primereact/selectitem';

import { MetricChartValue } from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { I18nNameSpaces } from '@shared/consts/i18n.ts';

export const getMetricTypeButtonsOptions = (t: TFunction<I18nNameSpaces['CAMPAIGNS']>) => {
  const options: SelectItem[] = [
    {
      value: MetricChartValue.IMPRESSIONS,
      label: t('page.campaignDetails.performanceTab.impressionsButton'),
    },
    {
      value: MetricChartValue.DEVICES,
      label: t('page.campaignDetails.performanceTab.devicesButton'),
    },
    {
      value: MetricChartValue.FREQUENCY,
      label: t('page.campaignDetails.performanceTab.frequencyButton'),
    },
  ];

  return options;
};
