import { ImpressionsDistributionData } from '@features/campaigns/performance/components/ImpressionsDistribution/types.ts';
import { apiRoutes } from '@features/campaigns/performance/consts/apiRoutes';
import { apiClient } from '@shared/api/ApiClient';
import { CampaignId } from '@shared/types/campaign';

export async function getCampaignImpressionsDistribution(
  campaignId: CampaignId,
): Promise<ImpressionsDistributionData | null> {
  return await apiClient.get<ImpressionsDistributionData>(apiRoutes.getCampaignImpressionsDistribution(campaignId));
}
