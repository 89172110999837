import cn from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import styles from './Search.module.scss';

interface SearchProps {
  onChangeHandler: (value: string) => void;
  placeholder: string;
  width?: `${number}%` | `${number}rem`;
  valueFromStorage?: string;
  disabled?: boolean;
  dataTestId: string;
}

export function Search({
  onChangeHandler,
  placeholder,
  width = '100%',
  valueFromStorage,
  disabled,
  dataTestId,
}: SearchProps) {
  const [inputValue, setInputValue] = useState(valueFromStorage || '');

  const onInputChangeHandler = (inputValue: string) => {
    onChangeHandler(inputValue);
    setInputValue(inputValue);
  };

  return (
    <div className={cn('p-input-icon-left', styles.searchWrapper, disabled && 'p-disabled')} style={{ width }}>
      <i className={cn('pi pi-search', styles.searchIcon)} />
      <InputText
        className={cn(styles.searchInput, disabled && styles.disabled)}
        placeholder={placeholder}
        onChange={(e) => onInputChangeHandler(e.target.value)}
        value={inputValue}
        aria-label='search'
        disabled={disabled}
        data-testid={`${dataTestId}-search-input`}
      />
      <i
        className={cn('pi pi-times-circle', styles.clearIcon)}
        onClick={() => onInputChangeHandler('')}
        data-testid={`${dataTestId}-clear-button`}
      />
    </div>
  );
}
