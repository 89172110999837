import { TFunction } from 'i18next';

import { AvailableAdProducts, CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';
import { I18nNameSpaces } from '@shared/consts/i18n';
import { objectKeys } from '@shared/utils/objectKeys';

interface FormatOptions {
  label: string;
  value: CampaignFormat;
}
export const getAvailableFormatOptions = (
  t: TFunction<I18nNameSpaces['CAMPAIGNS']>,
  availableAdProducts: AvailableAdProducts,
) => {
  const campaignFormatKeys = objectKeys(availableAdProducts);

  const statusOptions = campaignFormatKeys.reduce((acc: FormatOptions[], campaignFormatKey) => {
    if (availableAdProducts[campaignFormatKey]) {
      acc.push({
        label: t(`page.list.campaignFormat.${campaignFormatKey.toLowerCase()}`),
        value: campaignFormatKey,
      });
    }

    return acc;
  }, []);

  return statusOptions;
};
