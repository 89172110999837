import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { Dispatch, SetStateAction } from 'react';

import { Advertiser } from '@features/campaigns/shared/types/campaign.ts';
import { ChipsCounter } from '@features/users/details/components/TreeWithSearch/ChipsCounter/ChipsCounter.tsx';
import { ResultsCounter } from '@features/users/details/components/TreeWithSearch/ResultsCounter/ResultsCounter.tsx';
import { SearchInput } from '@features/users/details/components/TreeWithSearch/SearchInput/SearchInput.tsx';
import { TreePanel } from '@features/users/details/components/TreeWithSearch/TreePanel/TreePanel.tsx';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';

interface TreeWithSearchProps {
  data: Advertiser[] | [];
  chipsData?: Advertiser[] | [];
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<AdManagerApiResponse<Advertiser[]> | null, unknown>, Error>>;
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>> | ((value: string[]) => void);
  currentItemsCount: number;
  totalItems: number;
  isFetching?: boolean;
  setSearchInputValueCallback: Dispatch<SetStateAction<string | undefined>> | ((value: string | undefined) => void);
}
export function TreeWithSearch({
  data,
  chipsData,
  selectedIds,
  setSelectedIds,
  fetchNextPage,
  currentItemsCount,
  totalItems,
  isFetching,
  setSearchInputValueCallback,
}: TreeWithSearchProps) {
  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    let _collection: string[] = [...selectedIds];

    if (event.checked) {
      _collection.push(event.value);
    } else {
      _collection = _collection.filter((advertiserId) => advertiserId !== event.value);
    }

    setSelectedIds(_collection);
  };

  const removeAdvertiser = (id: string) => {
    setSelectedIds(selectedIds.filter((advertiserId) => advertiserId !== id));
  };

  return (
    <>
      <SearchInput setSearchInputValueCallback={setSearchInputValueCallback} />
      <ChipsCounter data={chipsData || data} selectedItems={selectedIds} removeAdvertiser={removeAdvertiser} />
      <TreePanel
        data={data}
        onCheckboxChange={onCheckboxChange}
        setSelectedItems={setSelectedIds}
        selectedItems={selectedIds}
        fetchNextPage={fetchNextPage}
        currentItemsCount={currentItemsCount}
        totalItems={totalItems}
        isFetching={isFetching}
      />
      <ResultsCounter currentItemsCount={currentItemsCount} totalItemsCount={totalItems} />
    </>
  );
}
