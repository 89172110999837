import { ExtendedTreeNodeType } from '@features/campaigns/builder/components/FormFields/common/Tree/types/treeTypes';
import { DropdownItem } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { GeoListType } from '@features/campaigns/builder/components/FormFields/Step02/FieldGeo/FieldGeo.types';
import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes.ts';
import { Advertiser } from '@features/campaigns/shared/types/campaign';
import { apiClient } from '@shared/api/ApiClient.ts';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';
import { apiRoutes as sharedApiRoutes } from '@shared/consts/apiRoutes.ts';
import { CampaignId } from '@shared/types/campaign.ts';
import { Status } from '@shared/types/common';
import { UserId } from '@shared/types/users.ts';

const PER_PAGE_LIMIT = 30;

export interface Item {
  id: string;
  name: string;
  status: Status;
  isPreliminaryBlocked?: boolean;
  code: string;
}

export function mapResponseToFieldData(
  response: AdManagerApiResponse<Item[]> | null,
): AdManagerApiResponse<DropdownItem[]> | null {
  if (response === null) {
    return response;
  }

  const mappedFields = response.items.map(
    ({ name, id, code, isPreliminaryBlocked = false, status }) =>
      ({
        name,
        value: id,
        disabled: isPreliminaryBlocked,
        code,
        nameWithCode: `${name} (${code})`,
        status,
      }) as const,
  );

  return {
    items: mappedFields,
    meta: response.meta,
  };
}

function mapProvincesResponseToFieldData(
  response: AdManagerApiResponse<GeoListType[]> | null,
): AdManagerApiResponse<GeoListType[]> | null {
  if (!response) {
    return null;
  }

  const mappedFields = response.items.map((item: GeoListType) => ({
    id: item.id,
    name: item.name,
    provinces: item.provinces,
  }));

  return {
    items: mappedFields,
    meta: response.meta,
  };
}

function mapTargetingItemsResponseToFieldData(
  response: AdManagerApiResponse<ExtendedTreeNodeType[]> | null,
): AdManagerApiResponse<ExtendedTreeNodeType[]> | null {
  if (!response) {
    return null;
  }

  const mappedFields = response.items.map((item) => ({
    name: item.name,
    id: item.id,
    status: item.status,
  }));

  return {
    items: mappedFields,
    meta: response.meta,
  };
}

function mapAdvertisersResponseToFieldData(
  response: AdManagerApiResponse<Advertiser[]> | null,
): AdManagerApiResponse<Advertiser[]> | null {
  if (response === null) {
    return response;
  }

  const mappedFields = response.items.map(
    ({ name, id, code, status, isPreliminaryBlocked }) =>
      ({
        id,
        name,
        status,
        code,
        isPreliminaryBlocked,
      }) as const,
  );

  return {
    items: mappedFields,
    meta: response.meta,
  };
}

export interface DefaultQueryParams {
  page: number;
  search: string | undefined;
}
const defaultPageParam: DefaultQueryParams = {
  page: 1,
  search: '',
};

interface DefaultTargetingQueryParams {
  page?: number;
  search?: string;
  limit?: number;
}

const defaultTargetingPageParam: DefaultTargetingQueryParams = {
  page: 1,
  search: '',
  limit: 10000,
};

export async function fetchAdvertisers({
  pageParam = defaultPageParam,
}): Promise<AdManagerApiResponse<DropdownItem[]> | null> {
  const { page, search } = pageParam;

  return await apiClient
    .get<AdManagerApiResponse<Item[]>>(apiRoutes.getAdvertisers(), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
    })
    .then((data) => mapResponseToFieldData(data));
}

export async function fetchAdvertisersForExternalUser({
  pageParam = defaultPageParam,
}): Promise<AdManagerApiResponse<Advertiser[]> | null> {
  const { page, search } = pageParam;

  return await apiClient
    .get<AdManagerApiResponse<Advertiser[]>>(apiRoutes.getAdvertisers(), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
    })
    .then((data) => mapAdvertisersResponseToFieldData(data));
}

export async function fetchAdvertisersAssignedToUser(
  userId: UserId,
  { pageParam = defaultPageParam },
  onlyActiveAdvertisers?: boolean,
): Promise<AdManagerApiResponse<DropdownItem[]> | null> {
  const { page, search } = pageParam;

  return await apiClient
    .get<AdManagerApiResponse<Item[]>>(sharedApiRoutes.getAdvertisersAssignedToUser(userId), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
      includeInactive: onlyActiveAdvertisers ? !onlyActiveAdvertisers : undefined,
    })
    .then((data) => mapResponseToFieldData(data));
}

export async function fetchAdvertisersAssignedToExternalUserWithAdvertiserResponseType(
  userId: UserId,
): Promise<AdManagerApiResponse<Advertiser[]> | null> {
  const { page } = defaultPageParam;

  return await apiClient
    .get<AdManagerApiResponse<Advertiser[]>>(sharedApiRoutes.getAdvertisersAssignedToUser(userId), {
      limit: PER_PAGE_LIMIT,
      page,
      includeInactive: true,
    })
    .then((data) => mapAdvertisersResponseToFieldData(data));
}

export async function fetchBrands(
  advertiserId: string,
  { pageParam = defaultPageParam },
): Promise<AdManagerApiResponse<DropdownItem[]> | null> {
  const { page, search } = pageParam;

  return await apiClient
    .get<AdManagerApiResponse<Item[]>>(apiRoutes.getBrands(advertiserId), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
    })
    .then((data) => mapResponseToFieldData(data));
}

export async function fetchContentPackages({
  campaignId,
}: {
  campaignId: CampaignId;
}): Promise<AdManagerApiResponse<ExtendedTreeNodeType[]> | null> {
  return await apiClient
    .get<
      AdManagerApiResponse<ExtendedTreeNodeType[]>
    >(apiRoutes.getContentPackages(), { ...defaultTargetingPageParam, campaignId })
    .then((data) => mapTargetingItemsResponseToFieldData(data));
}

export async function fetchAgeAndGenderGroups(): Promise<AdManagerApiResponse<ExtendedTreeNodeType[]> | null> {
  return await apiClient
    .get<AdManagerApiResponse<ExtendedTreeNodeType[]>>(apiRoutes.getAgeAndGenderGroups(), {
      ...defaultTargetingPageParam,
    })
    .then((data) => mapTargetingItemsResponseToFieldData(data));
}

export async function fetchInterestSegments(): Promise<AdManagerApiResponse<ExtendedTreeNodeType[]> | null> {
  return await apiClient
    .get<AdManagerApiResponse<ExtendedTreeNodeType[]>>(apiRoutes.getInterestSegments(), {
      ...defaultTargetingPageParam,
    })
    .then((data) => mapTargetingItemsResponseToFieldData(data));
}

export async function fetchProvinces(): Promise<AdManagerApiResponse<GeoListType[]> | null> {
  return await apiClient
    .get<AdManagerApiResponse<GeoListType[]>>(apiRoutes.getProvinces(), { ...defaultTargetingPageParam })
    .then((data) => mapProvincesResponseToFieldData(data));
}
