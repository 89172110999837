import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { ToastCloseButton } from '@shared/providers/ToastProvider/ToastCloseButton/ToastCloseButton';

import styles from './ToastProvider.module.scss';

export function ToastProvider() {
  return (
    <ToastContainer
      closeButton={<ToastCloseButton />}
      toastClassName={styles.toastWrapper}
      bodyClassName={styles.toastContent}
      position='top-center'
      autoClose={2000}
      hideProgressBar={true}
      theme='colored'
      closeOnClick
    />
  );
}
