import { useTranslation } from 'react-i18next';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { CampaignMediaTypes } from '@features/campaigns/shared/types/campaign';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Campaign } from '@shared/utils/interfaces/campaign';

function MediaTypeColumnBodyTemplate(campaign: Campaign) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const transformDisplayMediaTypeValue = () => {
    switch (campaign.mediaType) {
      case CampaignMediaTypes.CROSS:
        return t('page.list.campaignMediaType.cross');
      case CampaignMediaTypes.CTV:
        return t('page.list.campaignMediaType.ctv');
      default:
        return t('page.list.campaignMediaType.cross');
    }
  };

  return <span className={styles.columnValues}>{transformDisplayMediaTypeValue()}</span>;
}

export default MediaTypeColumnBodyTemplate;
