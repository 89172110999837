import cn from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import { CampaignMediaType } from '@features/campaigns/shared/types/campaign.ts';
import { Icon } from '@shared/components/Icon/Icon.tsx';
import { IconVariants } from '@shared/components/Icon/IconMapper.ts';

import styles from './MediaTypePanel.module.scss';

interface MediaTypePanelProps {
  iconVariant: IconVariants;
  iconLabel: string;
  description: string;
  label: string;
  mediaType: CampaignMediaType;
  changeHandler: Dispatch<SetStateAction<CampaignMediaType>>;
  isActive: boolean;
}

export function MediaTypePanel({
  iconVariant,
  iconLabel,
  label,
  changeHandler,
  isActive,
  mediaType,
  description,
}: MediaTypePanelProps) {
  const clickHandler = () => changeHandler(mediaType);

  return (
    <button
      className={cn(styles.wrapper, { [styles.active]: isActive })}
      type='button'
      onClick={clickHandler}
      data-testid={`create-${mediaType}-type-campaign`}
    >
      <span className={styles.indicator}></span>
      <div className={styles.iconWrapper}>
        <Icon variant={iconVariant} iconAltText={iconLabel} />
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.label}>{label}</p>
        <p className={styles.text}>{description}</p>
      </div>
    </button>
  );
}
