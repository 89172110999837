import { PointStyle, TooltipItem, TooltipModel } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { t } from 'i18next';

import { BarColor } from '@features/campaigns/performance/shared/components/HorizontalBarChart/consts.ts';
import { HorizontalBarChartData } from '@features/campaigns/performance/shared/components/HorizontalBarChart/HorizontalBarChart.types.ts';
import { commonChartTooltipStyles } from '@features/campaigns/performance/shared/styles/commonChartTooltipStyles.ts';
import { transformValueToFullPercentage } from '@features/campaigns/performance/shared/utils/transformValueToFullPercentage.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

const commonFontStyles = {
  family: 'Inter-Variable',
  size: 14,
  weight: 400,
};

const checkIfShouldDisplayDataLabelOutsideOfBar = (context: Context, data: number[]) => {
  const maxValue = Math.max(...data);
  const currentValue = data[context.dataIndex] || 0;
  const percentageUseOfChart = currentValue / maxValue;
  const minimumVisiblePercentageInsideBar = 0.06;

  return percentageUseOfChart < minimumVisiblePercentageInsideBar;
};

function transformChartValuesCollection(data: number[]): number[] {
  return data.map((value) => transformValueToFullPercentage(value));
}

export const generateChartData = (data: HorizontalBarChartData, barColor: BarColor) => {
  const formattedLabel = `% ${t('page.campaignDetails.performanceTab.chartLabels.impressions', { ns: i18nNameSpace.CAMPAIGNS })}`;

  return {
    labels: data.labels,
    datasets: [
      {
        label: formattedLabel,
        data: transformChartValuesCollection(data.values),
        backgroundColor: barColor,
        borderRadius: 6,
        barThickness: 35,
      },
    ],
  };
};

export const generateChartOptions = (data: HorizontalBarChartData, barColor: BarColor, isResponsive = true) => ({
  indexAxis: 'y' as const,
  responsive: isResponsive,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      anchor: 'end' as const,
      align: (context: Context) => {
        const shouldDisplayDataLabelOutsideOfBar = checkIfShouldDisplayDataLabelOutsideOfBar(context, data.values);

        const outsideOfBar = 'end';
        const insideBar = 'start';

        return shouldDisplayDataLabelOutsideOfBar ? outsideOfBar : insideBar;
      },
      formatter: (value: number) => {
        return `${transformValueToFullPercentage(value)}%`;
      },
      color: (context: Context) => {
        const shouldDisplayOutsideBar = checkIfShouldDisplayDataLabelOutsideOfBar(context, data.values);

        return shouldDisplayOutsideBar ? '#495057' : '#FFFFFF';
      },
      font: commonFontStyles,
    },
    legend: {
      position: 'bottom' as const,
      labels: {
        font: { family: 'Inter-Variable', size: 14, weight: 500 },
        color: '#495057',
        padding: 8,
        boxWidth: 12,
        boxHeight: 12,
        useBorderRadius: true,
      },
      display: true,
    },
    tooltip: {
      xAlign: 'left' as const,
      yAlign: 'center' as const,
      callbacks: {
        label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
          const value = tooltipItem.raw;

          if (typeof value === 'number' && value > 0) {
            const formattedValue = transformValueToFullPercentage(value);

            return `${t('page.campaignDetails.performanceTab.chartLabels.impressions', {
              ns: i18nNameSpace.CAMPAIGNS,
            })}: ${formattedValue}%`;
          } else {
            return ``;
          }
        },
        labelPointStyle: () => ({
          pointStyle: 'circle' as PointStyle,
          rotation: 0,
        }),
        labelColor: () => ({
          borderWidth: 0,
          backgroundColor: barColor,
          borderColor: barColor,
        }),
      },
      ...commonChartTooltipStyles,
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value: string | number) {
          const moreThanZero = Number(value) > 0;
          return moreThanZero ? `${value}%` : value;
        },
        color: '#818A92',
        font: commonFontStyles,
        padding: 0,
      },
      grid: {
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        crossAlign: 'far' as const,
        color: '#818A92',
        font: commonFontStyles,
        padding: 0,
      },
    },
  },
  layout: {
    padding: { right: 25 },
  },
});
