import { http, HttpResponse } from 'msw';

import { apiRoutes as detailsApiRoutes } from '@features/campaigns/details/consts/apiRoutes';
import { apiRoutes } from '@features/campaigns/shared/consts/apiRoutes';
import { CampaignStatuses } from '@features/campaigns/shared/types/campaign';

import { mockedCampaignDetails } from './campaignDetails.fixtures';

export const campaignDetailsRequests = [
  http.get(`*${apiRoutes.readCampaign(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.get(`*${detailsApiRoutes.downloadPptxDetailsPresentation(':id')}`, () => {
    const blob = new Blob([], {
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    });

    return HttpResponse.json(blob);
  }),
  http.post(`*${detailsApiRoutes.approveCampaign(':id')}`, () => {
    return HttpResponse.json({ ...mockedCampaignDetails, status: CampaignStatuses.APPROVED });
  }),
  http.post(`*${detailsApiRoutes.approveCampaignBudget(':id')}`, () => {
    return HttpResponse.json({ ...mockedCampaignDetails, status: CampaignStatuses.IN_APPROVAL });
  }),
];
