import { createContext } from 'react';

import { USER_ROLES } from '@features/auth/utils/constants.ts';
import { UserId } from '@shared/types/users.ts';

type PossibleUserRoles = keyof typeof USER_ROLES;

export interface UserDetails {
  role: PossibleUserRoles;
  id: UserId;
  isExternalUser: boolean;
  isPortfolioSalesUser: boolean;
}

export const UserDetailsContext = createContext<UserDetails | undefined>(undefined);
