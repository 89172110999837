import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import AuthenticatingScreen from '@features/auth/AuthenticatingScreen/AuthenticatingScreen.tsx';
import AuthWrapper from '@features/auth/AuthWrapper.tsx';
import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import BuilderPageLayout from '@features/campaigns/builder/components/PageLayout/PageLayout';
import CampaignDetailsPageLayout from '@features/campaigns/details/components/PageLayout/PageLayout';
import CampaignsListPage from '@features/campaigns/list/containers/CampaignsListPage/CampaignsListPage';
import Performance from '@features/campaigns/performance/containers/Performance/Performance';
import { CAMPAIGN_BUILDER_STEPS } from '@features/campaigns/shared/types/campaign.ts';
import UserDetailsPageLayout from '@features/users/details/components/PageLayout/PageLayout';
import UsersListPage from '@features/users/list/containers/UsersListPage.tsx';
import AppLayout from '@shared/components/AppLayout/AppLayout.tsx';
import { PlaceholderCard } from '@shared/components/PlaceholderCard/PlaceholderCard.tsx';
import { Permissions } from '@shared/consts/permissions';
import ErrorPage from '@shared/containers/ErrorPage/ErrorPage';
import { routePaths } from '@shared/router/routePaths.ts';

import config from '../../config.ts';

import { EnhancedFormPagesStep01 } from './EnhancedFormPagesStep01';
import { EnhancedFormPagesStep02 } from './EnhancedFormPagesStep02';
import { EnhancedFormPagesStep03 } from './EnhancedFormPagesStep03';
import { EnhancedFormPagesStep04 } from './EnhancedFormPagesStep04';
import { EnhancedFormPagesStep05 } from './EnhancedFormPagesStep05';
import { FormPagesStep01 } from './FormPagesStep01';

let createRouter = createBrowserRouter;

if (config.SENTRY_ENABLED === 'ACTIVE') {
  createRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
}

export const router = createRouter([
  {
    element: <AuthWrapper />,
    children: [
      {
        path: routePaths.notFound,
        element: <Navigate to={routePaths.campaigns} />,
      },
      {
        path: routePaths.serverDown,
        element: <ErrorPage statusCode={500} />,
      },
      {
        element: (
          <AllowAccess
            requiredPermissions={[
              Permissions.CAMPAIGN_LIST_ALL,
              Permissions.CAMPAIGN_LIST_OWNED,
              Permissions.CAMPAIGN_LIST_ASSIGNED_ADVERTISERS,
            ]}
            fallbackView={<AuthenticatingScreen />}
          >
            <AppLayout />
          </AllowAccess>
        ),
        children: [
          {
            path: routePaths.campaigns,
            element: <CampaignsListPage />,
          },
          {
            path: routePaths.users,
            element: (
              <AllowAccess
                requiredPermissions={[Permissions.USER_MODIFY]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <UsersListPage />
              </AllowAccess>
            ),
          },
        ],
      },
      {
        element: <BuilderPageLayout />,
        children: [
          {
            path: routePaths.campaignBuilderInitialize.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_CREATE_ALL_ADVERTISERS,
                  Permissions.CAMPAIGN_CREATE_ASSIGNED_ADVERTISERS,
                  Permissions.CAMPAIGN_CREATE_CROSS_AND_CTV_INTERNAL,
                  Permissions.CAMPAIGN_CREATE_CTV_SELF_SERVICE,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <Suspense fallback={<PlaceholderCard textKey={'placeholderLoading'} />}>
                  <FormPagesStep01 />
                </Suspense>
              </AllowAccess>
            ),
          },
          {
            path: routePaths.campaignBuilderStep01.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_UPDATE_CTV_OWNED,
                  Permissions.CAMPAIGN_UPDATE_ALL,
                  Permissions.CAMPAIGN_UPDATE_APPROVED_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <EnhancedFormPagesStep01 campaignStep={CAMPAIGN_BUILDER_STEPS.STEP_01} />
              </AllowAccess>
            ),
          },
          {
            path: routePaths.campaignBuilderStep02.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_UPDATE_CTV_OWNED,
                  Permissions.CAMPAIGN_UPDATE_ALL,
                  Permissions.CAMPAIGN_UPDATE_APPROVED_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <EnhancedFormPagesStep02 campaignStep={CAMPAIGN_BUILDER_STEPS.STEP_02} />
              </AllowAccess>
            ),
          },
          {
            path: routePaths.campaignBuilderStep03.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_UPDATE_CTV_OWNED,
                  Permissions.CAMPAIGN_UPDATE_ALL,
                  Permissions.CAMPAIGN_UPDATE_APPROVED_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <EnhancedFormPagesStep03 campaignStep={CAMPAIGN_BUILDER_STEPS.STEP_03} />
              </AllowAccess>
            ),
          },
          {
            path: routePaths.campaignBuilderStep04.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_UPDATE_CTV_OWNED,
                  Permissions.CAMPAIGN_UPDATE_ALL,
                  Permissions.CAMPAIGN_UPDATE_APPROVED_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <EnhancedFormPagesStep04 campaignStep={CAMPAIGN_BUILDER_STEPS.STEP_04} />
              </AllowAccess>
            ),
          },
          {
            path: routePaths.campaignBuilderStep05.routePath,
            element: (
              <AllowAccess
                requiredPermissions={[
                  Permissions.CAMPAIGN_UPDATE_CTV_OWNED,
                  Permissions.CAMPAIGN_UPDATE_ALL,
                  Permissions.CAMPAIGN_UPDATE_APPROVED_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL,
                  Permissions.CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS,
                ]}
                accessDeniedRedirectTo={routePaths.campaigns}
              >
                <EnhancedFormPagesStep05 campaignStep={CAMPAIGN_BUILDER_STEPS.STEP_05} />
              </AllowAccess>
            ),
          },
        ],
      },
      {
        path: routePaths.campaignDetails.routePath,
        element: <CampaignDetailsPageLayout />,
        children: [
          {
            path: routePaths.campaignPerformance.routePath,
            element: <Performance />,
          },
        ],
      },
      {
        path: routePaths.userDetails.routePath,
        element: <UserDetailsPageLayout />,
      },
    ],
  },
]);
