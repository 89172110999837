import { apiRoutes } from '@features/auth/consts/apiRoutes';
import { apiClient } from '@shared/api/ApiClient';
import { RoleDetails } from '@shared/types/role';

interface RolesDetailsResponse {
  definitions: RoleDetails[];
}

export async function getRolesDetails(): Promise<RolesDetailsResponse | null> {
  return await apiClient
    .get<RolesDetailsResponse>(apiRoutes.getRolesDetails())
    .then((data) => data)
    .catch((error) => {
      throw error;
    });
}
