import { addLocale, locale } from 'primereact/api';

import { primeReactLocaleIT } from '@shared/providers/PrimeReactConfiguration/locale/it';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import '@appTheme/theme.scss';

// mandatory locale for PrimeReact for Italy language" (got from official github PrimeReact: https://github.com/primefaces/primelocale/blob/main/it.json)
addLocale('it', primeReactLocaleIT);

// set default language for PrimeReact
locale('en');

export const primeReactConfiguration = {
  ripple: true,
};
