import cn from 'classnames';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DuplicateCampaignModal } from '@features/campaigns/list/components/Modals/DuplicateCampaignModal/DuplicateCampaignModal';
import { DeleteCampaignModal } from '@features/campaigns/shared/components/Modals/DeleteCampaignModal/DeleteCampaignModal';
import useDeleteCampaign from '@features/campaigns/shared/hooks/useDeleteCampaign.tsx';
import { AccessRights } from '@shared/api/commonApiInterfaces';
import { i18nNameSpace } from '@shared/consts/i18n';
import { routePaths } from '@shared/router/routePaths';
import { Campaign } from '@shared/utils/interfaces/campaign';

import styles from './ActionsPanel.module.scss';

interface ActionsPanelProps {
  referenceToActionsPanel: MutableRefObject<null | OverlayPanel>;
  campaign?: Campaign;
}

function ActionsPanel({ referenceToActionsPanel, campaign }: ActionsPanelProps) {
  const { t } = useTranslation([i18nNameSpace.CAMPAIGNS]);
  const navigate = useNavigate();
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const handleToggleDeleteModalVisibility = () => setDeleteModalVisible(!isDeleteModalVisible);
  const { deleteCampaignMutation, deleteCampaignIsPending } = useDeleteCampaign({
    handleToggleDeleteModalVisibility,
    refetchCampaignList: true,
  });

  const handleDeleteCampaign = () => {
    if (campaign) {
      deleteCampaignMutation(campaign.id);
    }
  };

  const handleToggleDuplicateModalVisibility = () => setIsDuplicateModalVisible(!isDuplicateModalVisible);
  const { accessRights } = campaign || {};
  const showEditButton =
    accessRights?.includes(AccessRights.EDIT_ALL) ||
    accessRights?.includes(AccessRights.EDIT_BUDGET) ||
    accessRights?.includes(AccessRights.EDIT_END_DATE) ||
    accessRights?.includes(AccessRights.EDIT_TARGETING) ||
    accessRights?.includes(AccessRights.EDIT_BRAND);

  const hideActionsPanel = () => {
    referenceToActionsPanel.current?.hide();
  };

  const onDetailsClicked = () => {
    if (campaign) {
      navigate(routePaths.campaignDetails.url(campaign.id));
    }
  };

  const onEditClicked = () => {
    if (campaign) {
      navigate(routePaths.campaignBuilderStep01.url(campaign.id));
    }
  };

  const onDuplicateClicked = () => {
    hideActionsPanel();
    handleToggleDuplicateModalVisibility();
  };

  const onDeleteClicked = () => {
    hideActionsPanel();
    handleToggleDeleteModalVisibility();
  };

  const menuList = [
    {
      template: () => {
        return (
          <>
            {accessRights?.includes(AccessRights.READ) && (
              <Button
                className={styles.menuButton}
                text
                onClick={onDetailsClicked}
                data-testid={'campaign-list-actions-menu-details-button'}
              >
                <i className={cn('pi pi-eye', styles.menuButtonIcon)}></i>
                {t('page.list.actions.details')}
              </Button>
            )}
            {showEditButton && (
              <Button
                className={styles.menuButton}
                text
                onClick={onEditClicked}
                data-testid={'campaign-list-actions-menu-edit-button'}
              >
                <i className={cn('pi pi-file-edit', styles.menuButtonIcon)}></i>
                {t('page.list.actions.edit')}
              </Button>
            )}
            {accessRights?.includes(AccessRights.DUPLICATE) && (
              <Button
                className={styles.menuButton}
                text
                onClick={onDuplicateClicked}
                data-testid={'campaign-list-actions-menu-duplicate-button'}
              >
                <i className={cn('pi pi-copy', styles.menuButtonIcon)}></i>
                {t('page.list.actions.duplicate')}
              </Button>
            )}
            {accessRights?.includes(AccessRights.DELETE) && (
              <Button
                className={styles.menuButton}
                text
                onClick={onDeleteClicked}
                data-testid={'campaign-list-actions-menu-delete-button'}
              >
                <i className={cn('pi pi-trash', styles.menuButtonIcon)}></i>
                {t('page.list.actions.delete')}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div data-testid='overlay-panel-wrapper'>
      <OverlayPanel className={styles.actionsPanelWrapper} ref={referenceToActionsPanel}>
        <Menu className={styles.wrapper} model={menuList} />
      </OverlayPanel>
      <DeleteCampaignModal
        isVisible={isDeleteModalVisible}
        toggleModalVisibility={handleToggleDeleteModalVisibility}
        deleteCallback={handleDeleteCampaign}
        deleteCallbackActionIsLoading={deleteCampaignIsPending}
        campaignName={campaign?.name}
      />
      <DuplicateCampaignModal
        isVisible={isDuplicateModalVisible}
        toggleModalVisibility={handleToggleDuplicateModalVisibility}
        campaignName={campaign?.name}
        campaignId={campaign?.id}
      />
    </div>
  );
}

export default ActionsPanel;
