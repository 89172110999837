import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { HeroSection } from '@features/users/details/components/HeroSection/HeroSection.tsx';
import { UserDetails } from '@features/users/details/components/UserDetails/UserDetails.tsx';
import { QUERY_KEYS } from '@features/users/details/consts/queryKeys.ts';
import { AdvertisersAssign } from '@features/users/details/containers/AdvertisersAssign/AdvertisersAssign.tsx';
import { fetchExternalUserDetails } from '@features/users/details/services/externalUserDetails';
import placeholderCommonStyles from '@shared/components/PlaceholderCard/PlaceholderCard.module.scss';
import { PlaceholderCard } from '@shared/components/PlaceholderCard/PlaceholderCard.tsx';

import styles from './UserDetailsPage.module.scss';

export default function UserDetailsPage() {
  const { userId } = useParams();
  const { data } = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_USER_DETAILS, userId],
    queryFn: () => fetchExternalUserDetails({ userId: userId! }),
  });

  if (!data) {
    return (
      <div className={placeholderCommonStyles.placeholderCardWrapper}>
        <PlaceholderCard textKey='placeholderLoading' />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <HeroSection />
      <div className={styles.innerWrapper}>
        <section className='content-wrapper'>
          <div className='grid'>
            <div className='col-4'>
              <UserDetails data={data} />
            </div>
            <div className='col-8'>
              <AdvertisersAssign />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
