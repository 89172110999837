import { InputNumber } from 'primereact/inputnumber';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldErrorMessage } from '@features/campaigns/builder/components/FormFields/common/FieldErrorMessage/FieldErrorMessage';
import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes';
import styles from '@features/campaigns/shared/components/FormFields/FormFields.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

export function FieldAuthorizedBudget() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { control } = useFormContext();

  return (
    <div className={styles.fieldWrapper}>
      <label htmlFor={BillingFormFieldTypes.AUTHORIZED_BUDGET} className={styles.fieldLabel}>
        {t('page.billingForm.authorizedBudgetField.label')}
      </label>

      <Controller
        name={BillingFormFieldTypes.AUTHORIZED_BUDGET}
        control={control}
        render={({ field, formState: { errors } }) => {
          return (
            <>
              <span className='p-input-icon-left'>
                <i className='pi pi-euro' />
                <InputNumber
                  {...field}
                  id={BillingFormFieldTypes.AUTHORIZED_BUDGET}
                  onChange={(event) => field.onChange(event.value)}
                  placeholder={t('page.billingForm.authorizedBudgetField.placeholder')}
                  data-testid='field-authorized-budget'
                  min={0}
                  maxLength={7}
                />
              </span>
              {errors?.[BillingFormFieldTypes.AUTHORIZED_BUDGET] && (
                <FieldErrorMessage fieldErrorData={errors} fieldName={BillingFormFieldTypes.AUTHORIZED_BUDGET} />
              )}
            </>
          );
        }}
      />
    </div>
  );
}
