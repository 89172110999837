import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './FieldErrorMessage.module.scss';

interface FieldErrorMessageProps<T> {
  fieldErrorData: T;
  fieldName: keyof T;
}

function parseErrorMessage<T>(errorData: T) {
  if (Array.isArray(errorData)) {
    return errorData;
  }

  return [errorData];
}

export function FieldErrorMessage<T>({ fieldErrorData, fieldName }: FieldErrorMessageProps<T>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const messages = parseErrorMessage(fieldErrorData[fieldName]);

  return (
    <>
      {messages.map((message, index) => (
        <div className={styles.wrapper} key={`message.message+${index}`}>
          <i className={cn('pi pi-exclamation-triangle', styles.icon)} />
          <span className={cn('p-error', styles.text)} data-testid={`${String(fieldName)}-error-message`}>
            {t(message.message)}
          </span>
        </div>
      ))}
    </>
  );
}
