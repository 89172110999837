import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes.ts';
import { BillingDetailsPayload } from '@features/campaigns/details/containers/BillingDetails/BillingDetails.dto.ts';
import { CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export async function updateBillingDetails(
  payload: BillingDetailsPayload,
  campaignId: CampaignId,
): Promise<CampaignDetails | null> {
  return await apiClient.put<BillingDetailsPayload, CampaignDetails>(
    apiRoutes.updateBillingDetails(campaignId),
    payload,
  );
}
