import { ValueOf } from '@shared/types/common.ts';

export const DownloadedFileTypes = {
  REPORT: 'report',
  DETAILS: 'details',
} as const;

export const DownloadedFileExtensions = {
  PPTX: 'pptx',
  XLSX: 'xlsx',
} as const;

export type DownloadedFileType = ValueOf<typeof DownloadedFileTypes>;

export type DownloadedFileExtension = ValueOf<typeof DownloadedFileExtensions>;
