import {
  Actions,
  CampaignListReducerAction,
  CampaignListReducerState,
} from '@features/campaigns/list/types/campaignListReducer';
import { DEFAULT_PER_PAGE_LIMIT } from '@shared/components/Table/consts/table';

export const initialState: CampaignListReducerState = {
  first: 0,
  limit: DEFAULT_PER_PAGE_LIMIT,
  page: 0,
  sortField: undefined,
  sortOrder: undefined,
  search: undefined,
  campaignsCount: 0,
  filters: {
    format: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
  },
};

export const reducer = (
  state: CampaignListReducerState,
  { payload, type }: CampaignListReducerAction,
): CampaignListReducerState => {
  switch (type) {
    case Actions.CHANGE_BASIC_TABLE_DATA:
      return {
        ...state,
        ...payload,
      };

    case Actions.CHANGE_FORMAT_FILTER:
      return {
        ...state,
        first: payload?.first || initialState.first,
        page: payload?.page || initialState.page,
        filters: {
          ...state.filters,
          format: payload?.filters?.format,
        },
      };

    case Actions.CHANGE_START_DATE_FILTER:
      return {
        ...state,
        first: payload?.first || initialState.first,
        page: payload?.page || initialState.page,
        filters: {
          ...state.filters,
          startDate: payload?.filters?.startDate,
        },
      };

    case Actions.CHANGE_END_DATE_FILTER:
      return {
        ...state,
        first: payload?.first || initialState.first,
        page: payload?.page || initialState.page,
        filters: {
          ...state.filters,
          endDate: payload?.filters?.endDate,
        },
      };

    case Actions.CHANGE_STATUS_FILTER:
      return {
        ...state,
        first: payload?.first || initialState.first,
        page: payload?.page || initialState.page,
        filters: {
          ...state.filters,
          status: payload?.filters?.status,
        },
      };

    case Actions.CHANGE_SEARCH_QUERY:
      return {
        ...state,
        first: initialState.first,
        page: initialState.page,
        search: payload?.search,
      };

    case Actions.CLEAR_FILTERS:
      return {
        ...state,
        first: initialState.first,
        page: initialState.page,
        filters: {},
      };

    case Actions.CHANGE_CAMPAIGNS_COUNT:
      return {
        ...state,
        campaignsCount: payload?.campaignsCount,
      };

    default:
      return state;
  }
};
