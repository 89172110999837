import { createContext } from 'react';

import config from '../../../config.ts';

export const FeatureFlagPossibleState = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

type FeatureFlagState = keyof typeof FeatureFlagPossibleState;

export const featureFlagsMap = {
  FeatureFlag_BonusImpressions: config.FF_BONUS_IMPRESSIONS,
} as const;

type FeatureFlagKey = keyof typeof featureFlagsMap;

export type FeatureFlags = Record<FeatureFlagKey, FeatureFlagState | undefined>;

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);
