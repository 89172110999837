import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';

import styles from '@features/users/list/components/Table/ExternalUsersListTable.module.scss';
import { ExternalUser } from '@features/users/list/types/externalUser';
import { i18nNameSpace } from '@shared/consts/i18n';

function RoleColumnBodyTemplate(user: ExternalUser) {
  const { t } = useTranslation(i18nNameSpace.USERS);

  return (
    <Tag
      className={styles.roleValue}
      value={user.role ? t(`page.roles.${user.role?.toLowerCase()}`) : 'External User'}
    />
  );
}

export default RoleColumnBodyTemplate;
