import { Button } from 'primereact/button';

interface DefaultActionsFooterProps {
  cancelLabel: string;
  cancelCallback: () => void;
  submitLabel: string;
  submitCallback: () => void;
  submitCallbackActionIsLoading?: boolean;
  severity?: 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help' | undefined;
}

export function DefaultActionsFooter({
  cancelCallback,
  submitCallback,
  submitLabel,
  cancelLabel,
  submitCallbackActionIsLoading = false,
  severity,
}: DefaultActionsFooterProps) {
  return (
    <div>
      <Button label={cancelLabel} onClick={cancelCallback} className='p-button-text' data-testid='cancel-button' />
      <Button
        label={submitLabel}
        onClick={submitCallback}
        autoFocus
        loading={submitCallbackActionIsLoading}
        disabled={submitCallbackActionIsLoading}
        severity={severity}
        data-testid='submit-button'
      />
    </div>
  );
}
