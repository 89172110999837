import styles from '@features/campaigns/performance/components/shared/styles/SelectButton.module.scss';

function SelectButtonItemTemplate(option: { value: string; label: string }) {
  return (
    <div className={styles.itemWrapper} data-testid={`${option.label}-type-button`}>
      {option.label}
    </div>
  );
}

export default SelectButtonItemTemplate;
