import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  chartColors,
  ChartData,
  Metric,
  MetricsData,
} from '@features/campaigns/performance/components/ImpressionsDistribution/types.ts';
import { getFormattedValue } from '@features/campaigns/performance/components/ImpressionsDistribution/utils.ts';
import { transformValueToFullPercentage } from '@features/campaigns/performance/shared/utils/transformValueToFullPercentage.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './ChartLegend.module.scss';

interface ChartLegendProps {
  chartData: ChartData;
  metricsData: MetricsData | null;
}

function ChartLegend({ chartData, metricsData }: ChartLegendProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className={styles.legendWrapper}>
      {chartData?.labels?.map((label, index) => {
        const legendItemValue = chartData?.datasets?.[0]?.data?.[index] ? chartData.datasets[0].data[index] : 0;
        const formattedLegendItemValue = transformValueToFullPercentage(legendItemValue);

        const metrics: Metric[] | null =
          metricsData?.[label.toLowerCase().replaceAll(' ', '') as keyof typeof metricsData] ?? null;
        return (
          <div key={label}>
            <div className={styles.legendItem}>
              <div className={styles.dot} style={{ backgroundColor: chartColors[index] }}></div>
              <div>
                <span className={styles.legendItemText}>{`${label}:`}</span>
                <span className={styles.legendItemValue}>{`${formattedLegendItemValue}%`}</span>
              </div>
            </div>
            {metricsData && (
              <div className={cn(styles.metricsWrapper, { [styles.singleColumn]: label === 'Video' })}>
                {metrics &&
                  (metrics as Metric[]).map((metric) => (
                    <div key={metric.label}>
                      <span className={styles.legendItemText}>{`${t(metric.label)}:`}</span>
                      <span className={styles.legendItemValue}>{`${getFormattedValue(metric)}`}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ChartLegend;
