import { useContext } from 'react';

import { LeaveGuardModalContext } from '@shared/providers/LeaveGuardModalProvider/providerSetup.ts';

export const useLeaveGuardModalContext = () => {
  const context = useContext(LeaveGuardModalContext);

  if (!context) {
    throw new Error('useLeaveGuardModalContext must be used within a LeaveGuardModalProvider');
  }

  return context;
};
