import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import { submitCampaign } from '@features/campaigns/shared/services/campaign/submitCampaign';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { routePaths } from '@shared/router/routePaths.ts';
import { CampaignId } from '@shared/types/campaign';

interface SubmitCampaignParams {
  campaignId: CampaignId;
  campaignName: string;
  handleToggleModalVisibility: () => void;
}

export default function useSubmitCampaign({
  campaignId,
  campaignName,
  handleToggleModalVisibility,
}: SubmitCampaignParams) {
  const { t } = useTranslation(i18nNameSpace.API);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state: locationState, pathname } = useLocation();
  const isSubmitFromCampaignDetails = pathname.includes(routePaths.campaignDetails.url(campaignId));

  const navigateToListOrDetails = () => {
    navigate(locationState || routePaths.campaigns);
  };

  const refetchCampaignDetails = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGN_DETAILS] });
  };

  return useMutation({
    mutationFn: () => submitCampaign(campaignId),
    onSuccess: async () => {
      handleToggleModalVisibility();
      toast.success(<SuccessMessage message={t('api.success.campaign.submitted', { campaignName })} />);

      if (isSubmitFromCampaignDetails) {
        return await refetchCampaignDetails();
      }

      return navigateToListOrDetails();
    },
  });
}
