import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { TargetData } from '@features/campaigns/shared/containers/Review/Review.dto';
import { ReviewSectionProps } from '@features/campaigns/shared/containers/Review/types';
import { CampaignMediaTypes, CampaignTypes } from '@features/campaigns/shared/types/campaign.ts';
import { Item } from '@shared/components/Review/Item/Item';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './Target.module.scss';

function formatItems(items?: TargetData['items']) {
  if (!items) {
    return '-';
  }

  return Array.isArray(items) ? items.join(', ') : items;
}

function formatTargetItemValue(el: TargetData): string {
  const itemsCount = el.items?.length;

  if (itemsCount === 0) {
    return '-';
  } else {
    const formattedItems = formatItems(el.items);

    return formattedItems;
  }
}

function formatItemsCount(items?: TargetData['items']) {
  if (!items) {
    return 0;
  }

  return Array.isArray(items) ? items.length : 1;
}

const isContentPackageLabel = (label: string) => {
  return label === 'review.labels.contentPackage';
};

export function Target({
  headerText,
  ctaButtonCallback,
  data,
  campaignType,
  mediaType,
}: ReviewSectionProps<TargetData[]>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const isCrossMediaType = mediaType === CampaignMediaTypes.CROSS;

  return (
    <SectionWrapper headerText={headerText} ctaButtonCallback={ctaButtonCallback}>
      <div className={'grid'} data-testid='target-section'>
        {data
          .filter((el) => {
            const isBlockedContentPackagesForExternalUser =
              campaignType === CampaignTypes.SELF_SERVICE && isContentPackageLabel(el.label);

            return !isBlockedContentPackagesForExternalUser;
          })
          .map((el, index) => {
            const formattedCount = formatItemsCount(el.items);
            const formattedLabel = t(el.label, { count: formattedCount });
            const formattedVariant = el.variant ? `${t(`builder.steps.step02.${el.variant.toLowerCase()}`)}: ` : '';
            const formattedValue = formatTargetItemValue(el);
            const isLastArrayElement = index === data.length - 1;

            if (isCrossMediaType && isContentPackageLabel(el.label)) {
              return (
                <div className={cn('col-12', { 'no-v-padding': isLastArrayElement })} key={index}>
                  <Item label={formattedLabel}>
                    <p className={styles.text}>
                      {formattedVariant && <span className={styles.label}>{formattedVariant}</span>}
                      {formattedValue}
                    </p>
                  </Item>
                </div>
              );
            }

            if (!isCrossMediaType && campaignType === CampaignTypes.INTERNAL) {
              return (
                <div className={cn('col-12', { 'no-v-padding': isLastArrayElement })} key={index}>
                  <Item label={formattedLabel}>
                    <p className={styles.text}>
                      {formattedVariant && <span className={styles.label}>{formattedVariant}</span>}
                      {formattedValue}
                    </p>
                  </Item>
                </div>
              );
            }

            if (!isCrossMediaType && campaignType === CampaignTypes.SELF_SERVICE && !isContentPackageLabel(el.label)) {
              return (
                <div className={cn('col-12', { 'no-v-padding': isLastArrayElement })} key={index}>
                  <Item label={formattedLabel}>
                    <p className={styles.text}>
                      {formattedVariant && <span className={styles.label}>{formattedVariant}</span>}
                      {formattedValue}
                    </p>
                  </Item>
                </div>
              );
            }
          })}
      </div>
    </SectionWrapper>
  );
}
