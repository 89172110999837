import cn from 'classnames';
import { ProgressSpinner } from 'primereact/progressspinner';

import { ValueOf } from '@shared/types/common';

import styles from './Spinner.module.scss';

const SpinnerVariants = {
  DEFAULT: 'default',
  FULL_SCREEN_OVERLAY: 'fullScreenOverlay',
  ELEMENT_OVERLAY: 'elementOverlay',
} as const;

interface SpinnerProps {
  variant?: ValueOf<typeof SpinnerVariants>;
}

export function Spinner({ variant = SpinnerVariants.DEFAULT }: SpinnerProps) {
  if (variant === SpinnerVariants.DEFAULT) {
    return <ProgressSpinner className={styles.spinner} />;
  }

  return (
    <div
      className={cn(styles.overlay, {
        [styles.elementOverlay]: variant === SpinnerVariants.ELEMENT_OVERLAY,
        [styles.fullScreenOverlay]: variant === SpinnerVariants.FULL_SCREEN_OVERLAY,
      })}
    >
      <ProgressSpinner className={styles.spinner} />
    </div>
  );
}
