import cn from 'classnames';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Campaign } from '@shared/utils/interfaces/campaign';

function FormatBodyTemplate(campaign: Campaign) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className={cn(styles.columnValues, styles.formatValuesWrapper)}>
      {(campaign.format ?? []).map((format) => {
        return (
          <Tag
            key={format}
            className={styles.formatValue}
            value={t(`page.list.campaignFormat.${format.toLowerCase()}`)}
          />
        );
      })}
    </div>
  );
}

export default FormatBodyTemplate;
