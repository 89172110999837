import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import { MediaTypeTrigger } from '@features/campaigns/list/components/MediaType/Trigger/MediaTypeTrigger.tsx';
import { Icon } from '@shared/components/Icon/Icon';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Permissions } from '@shared/consts/permissions';
import { routePaths } from '@shared/router/routePaths';

import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  isFiltering?: boolean;
}

export function EmptyState({ isFiltering }: EmptyStateProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className={styles.emptyStateWrapper}>
      <Icon
        variant={isFiltering ? 'filterGray' : 'closeGray'}
        iconAltText={t(isFiltering ? 'page.list.noFilteredCampaigns' : 'page.list.noCampaigns')}
        stylesClassName={'emptyStateIcon'}
      />
      <p className={styles.emptyStateTitle}>
        {t(isFiltering ? 'page.list.noFilteredCampaigns' : 'page.list.noCampaigns')}
      </p>
      <AllowAccess
        requiredPermissions={[
          Permissions.CAMPAIGN_CREATE_ALL_ADVERTISERS,
          Permissions.CAMPAIGN_CREATE_CROSS_AND_CTV_INTERNAL,
        ]}
      >
        <p className={cn(styles.emptyStateSubtitle, isFiltering && styles.isFilteringEmptyState)}>
          {t(isFiltering ? 'page.list.noFilteredCampaignsActionSubtitle' : 'page.list.noCampaignsActionSubtitle')}
        </p>

        {!isFiltering && <MediaTypeTrigger linkStyle className={styles.newCampaignButton} />}
      </AllowAccess>

      <AllowAccess requiredPermissions={[Permissions.CAMPAIGN_CREATE_CTV_SELF_SERVICE]}>
        <p className={cn(styles.emptyStateSubtitle, isFiltering && styles.isFilteringEmptyState)}>
          {t(isFiltering ? 'page.list.noFilteredCampaignsActionSubtitle' : 'page.list.noCampaignsActionSubtitle')}
        </p>
        {!isFiltering && (
          <Link
            className={cn('p-text', styles.newCampaignButton)}
            to={routePaths.campaignBuilderInitialize.url()}
            data-testid='empty-state-create-campaign-button'
          >
            <i className={cn('pi pi-plus', styles.newCampaignIcon)}></i>
            {t('page.newCampaign')}
          </Link>
        )}
      </AllowAccess>
    </div>
  );
}
