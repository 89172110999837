import {
  Actions,
  ExternalUsersListReducerAction,
  ExternalUsersListReducerState,
} from '@features/users/list/types/externalUsersListReducer';
import { DEFAULT_PER_PAGE_LIMIT } from '@shared/components/Table/consts/table';

export const initialState: ExternalUsersListReducerState = {
  first: 0,
  limit: DEFAULT_PER_PAGE_LIMIT,
  page: 0,
  sortField: undefined,
  sortOrder: undefined,
  search: undefined,
  usersCount: 0,
};

export const reducer = (
  state: ExternalUsersListReducerState,
  { payload, type }: ExternalUsersListReducerAction,
): ExternalUsersListReducerState => {
  switch (type) {
    case Actions.CHANGE_BASIC_TABLE_DATA:
      return {
        ...state,
        ...payload,
      };

    case Actions.CHANGE_SEARCH_QUERY:
      return {
        ...state,
        search: payload?.search,
      };

    case Actions.CHANGE_USERS_COUNT:
      return {
        ...state,
        usersCount: payload?.usersCount,
      };

    default:
      return state;
  }
};
