import { PointStyle, ScriptableContext, TooltipItem, TooltipModel } from 'chart.js';
import { TFunction } from 'i18next';
import { SelectItem } from 'primereact/selectitem';

import {
  BeforeDrawChartParam,
  chartColors,
  DigitalMetrics,
  DisplayMetrics,
  ImpressionsDistributionData,
  ImpressionsDistributionType,
  ImpressionsDistributionTypes,
  Metric,
  FormatMetricsData,
  VideoMetrics,
  ScreenMetricsData,
  AdTypeMetricsData,
  MetricsData,
} from '@features/campaigns/performance/components/ImpressionsDistribution/types.ts';
import { commonChartTooltipStyles } from '@features/campaigns/performance/shared/styles/commonChartTooltipStyles';
import { transformValueToFullPercentage } from '@features/campaigns/performance/shared/utils/transformValueToFullPercentage.ts';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Nullable } from '@shared/types/common';
import { formatSeconds } from '@shared/utils/formatSeconds.ts';
import { formatNumberWithCommas } from '@shared/utils/numberFormat';

export const setTitleInsideDoughnutChart = ({ ctx, width, height, config }: BeforeDrawChartParam) => {
  const fontSize = '16px Inter-Variable';
  ctx.font = fontSize;
  ctx.fillStyle = '#818A92';
  ctx.textBaseline = 'middle';

  const title =
    config?.data?.datasets?.[0] &&
    'title' in config.data.datasets[0] &&
    typeof config.data.datasets[0].title === 'string'
      ? config.data.datasets[0].title
      : '';

  // title positioning in the chart
  const titleX = Math.round((width - ctx.measureText(title).width) / 2);
  const titleY = height / 2;

  ctx.fillText(title, titleX, titleY);
};

export const getChartOptions = (dateRange: string) => ({
  plugins: {
    datalabels: { display: false },
    legend: {
      display: false,
    },
    tooltip: {
      xAlign: 'center' as const,
      yAlign: 'bottom' as const,
      callbacks: {
        title: () => {
          return dateRange;
        },
        label: function (this: TooltipModel<'doughnut'>, tooltipItem: TooltipItem<'doughnut'>) {
          const label = tooltipItem.label;
          const value = Number.isNaN(tooltipItem.raw) ? 0 : transformValueToFullPercentage(tooltipItem.raw as number);
          const isValueZeroOrInteger = value === 0 || Number.isInteger(value);
          const formattedValue = isValueZeroOrInteger ? value : value.toFixed(2);

          return `${label}: ${formattedValue}%`;
        },
        labelTextColor: () => '#fff',
        labelPointStyle: () => ({
          pointStyle: 'circle' as PointStyle,
          rotation: 0,
        }),
      },
      ...commonChartTooltipStyles,
    },
  },
  layout: {
    padding: 10,
  },
});

const hoverHandler = (context: ScriptableContext<'doughnut'>) => {
  return chartColors[context.dataIndex];
};

export const getChartDataByImpressionsDistributionType = (
  data: Nullable<ImpressionsDistributionData>,
  impressionsDistributionType: keyof ImpressionsDistributionData,
  t: TFunction<typeof i18nNameSpace.CAMPAIGNS>,
) => {
  if (!data) {
    return null;
  }

  const labels = data[impressionsDistributionType].map(
    (impressionsDistributionData) => impressionsDistributionData.label,
  );
  const values = data[impressionsDistributionType].map(
    (impressionsDistributionData) => impressionsDistributionData.valuePercentage,
  );

  return {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: chartColors,
        hoverOffset: 20,
        hoverBackgroundColor: hoverHandler,
        hoverBorderColor: hoverHandler,
        borderColor: hoverHandler,
        title: t('page.campaignDetails.performanceTab.impressionsDistributionChartTitle'),
      },
    ],
  };
};

export const getImpressionsDistributionTypes = (t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  const options: SelectItem[] = [
    {
      value: ImpressionsDistributionTypes.SCREEN,
      label: t('page.campaignDetails.performanceTab.impressionsDistributionTypeButton.screen'),
    },
    {
      value: ImpressionsDistributionTypes.FORMAT,
      label: t('page.campaignDetails.performanceTab.impressionsDistributionTypeButton.format'),
    },
    {
      value: ImpressionsDistributionTypes.AD_TYPE,
      label: t('page.campaignDetails.performanceTab.impressionsDistributionTypeButton.adType'),
    },
  ];

  return options;
};

const getDigitalMetricsData = (digitalMetrics: DigitalMetrics | undefined): Metric[] => {
  return [
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.clicks',
      value: digitalMetrics?.clicks,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.ctr',
      isPercentageValue: true,
      value: digitalMetrics?.ctr,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
      value: digitalMetrics?.impressions,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.vtr',
      isPercentageValue: true,
      value: digitalMetrics?.vtr,
    },
  ];
};

const getDisplayMetricsData = (displayMetrics: DisplayMetrics | undefined): Metric[] => {
  if (!displayMetrics) {
    return [];
  }

  return Object.keys(displayMetrics).map((metricName): Metric => {
    const metricValue = displayMetrics[metricName as keyof DisplayMetrics];

    const metricBasicData: Metric = {
      label: `page.campaignDetails.performanceTab.keyMetrics.${metricName}`,
      value: metricValue,
    };

    if (metricName === 'timeSpentSeconds') {
      return { ...metricBasicData, isTimeSpent: true };
    }

    if (metricName === 'engagement') {
      return { ...metricBasicData, isEngagement: true };
    }

    if (metricName === 'ctr') {
      return { ...metricBasicData, isPercentageValue: true };
    }

    return metricBasicData;
  });
};

const getVideoMetricsData = (videoMetrics: VideoMetrics | undefined): Metric[] => {
  return [
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
      value: videoMetrics?.impressions,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.vtr',
      isPercentageValue: true,
      value: videoMetrics?.vtr,
    },
  ];
};

const extractMetrics = <
  T extends
    | ImpressionsDistributionData['screen']
    | ImpressionsDistributionData['format']
    | ImpressionsDistributionData['adType'],
>(
  metrics: T,
  expectedLabel: string,
): DigitalMetrics | VideoMetrics | DisplayMetrics | undefined => {
  return metrics?.find(({ label }) => label === expectedLabel)?.metrics;
};

const getScreenMetricsData = (
  screenImpressionsDistributionData: ImpressionsDistributionData['screen'] | undefined,
): ScreenMetricsData | null => {
  if (!screenImpressionsDistributionData) {
    return null;
  }

  const digitalMetrics = extractMetrics(screenImpressionsDistributionData, 'Digital') as DigitalMetrics | undefined;
  const ctvMetrics = extractMetrics(screenImpressionsDistributionData, 'CTV') as VideoMetrics | undefined;

  return digitalMetrics || ctvMetrics
    ? {
        digital: getDigitalMetricsData(digitalMetrics),
        ctv: getVideoMetricsData(ctvMetrics),
      }
    : null;
};

const getAdTypeMetricsData = (
  adTypeImpressionsDistributionData: ImpressionsDistributionData['adType'] | undefined,
): AdTypeMetricsData | null => {
  if (!adTypeImpressionsDistributionData) {
    return null;
  }

  const digitalMetrics = extractMetrics(adTypeImpressionsDistributionData, 'Digital VOD') as DigitalMetrics | undefined;
  const displayMetrics = extractMetrics(adTypeImpressionsDistributionData, 'CTV Display') as DisplayMetrics | undefined;
  const vodMetrics = extractMetrics(adTypeImpressionsDistributionData, 'CTV VOD') as VideoMetrics | undefined;
  const linearMetrics = extractMetrics(adTypeImpressionsDistributionData, 'CTV Linear addressable') as
    | VideoMetrics
    | undefined;

  return {
    /* cSpell:disable */
    digitalvod: getDigitalMetricsData(digitalMetrics),
    ctvdisplay: getDisplayMetricsData(displayMetrics),
    ctvvod: getVideoMetricsData(vodMetrics),
    ctvlinearaddressable: getVideoMetricsData(linearMetrics),
    /* cSpell:enable */
  };
};

const getFormatMetricsData = (
  formatImpressionsDistributionData: ImpressionsDistributionData['format'] | undefined,
): FormatMetricsData | null => {
  if (!formatImpressionsDistributionData) {
    return null;
  }

  const displayMetrics = extractMetrics(formatImpressionsDistributionData, 'Display') as DisplayMetrics | undefined;
  const videoMetrics = extractMetrics(formatImpressionsDistributionData, 'Video') as VideoMetrics | undefined;

  return {
    display: getDisplayMetricsData(displayMetrics),
    video: getVideoMetricsData(videoMetrics),
  };
};

export const getMetricsData = (
  impressionsDistributionType: ImpressionsDistributionType,
  impressionsDistributionData: ImpressionsDistributionData | undefined | null,
): MetricsData | null => {
  switch (impressionsDistributionType) {
    case ImpressionsDistributionTypes.SCREEN:
      return getScreenMetricsData(impressionsDistributionData?.screen);
    case ImpressionsDistributionTypes.FORMAT:
      return getFormatMetricsData(impressionsDistributionData?.format);
    case ImpressionsDistributionTypes.AD_TYPE:
      return getAdTypeMetricsData(impressionsDistributionData?.adType);
  }
};

export const getFormattedValue = ({ isTimeSpent, isPercentageValue, isEngagement, value }: Metric): string => {
  if (typeof value === 'undefined') {
    return '-';
  }

  if (isTimeSpent) {
    return formatSeconds(value);
  }

  if (isEngagement) {
    return value.toFixed(1);
  }

  if (isPercentageValue) {
    return `${transformValueToFullPercentage(value)}%`;
  }

  return formatNumberWithCommas(value);
};
