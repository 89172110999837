import { CampaignPerformanceGeneralType } from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { ICON_VARIANTS, IconVariants } from '@shared/components/Icon/IconMapper.ts';

export interface HighlightDataPerformance {
  label: string;
  value?: number;
  isMoneyValue: boolean;
  iconVariant: IconVariants;
  isFrequencyValue?: boolean;
  isEngagementValue?: boolean;
  isActivationValue?: boolean;
  isTimeSpent?: boolean;
  subtitleLabel?: string;
  subtitleValue?: number;
}

type ReportingDataType = CampaignPerformanceGeneralType | null | undefined;

const generateKeyMetricsForAllFormat = (reportingData: ReportingDataType) => {
  return [
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
      value: reportingData?.summary.impressions,
      isMoneyValue: false,
      iconVariant: ICON_VARIANTS.eye,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.uniqueDevices',
      value: reportingData?.summary.uniqueDevices,
      isMoneyValue: false,
      iconVariant: ICON_VARIANTS.phone,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.frequency',
      value: reportingData?.summary.frequency,
      isMoneyValue: false,
      isFrequencyValue: true,
      iconVariant: ICON_VARIANTS.clock,
    },
  ];
};

export const getKeyMetrics = (reportingData?: ReportingDataType): HighlightDataPerformance[] => {
  return generateKeyMetricsForAllFormat(reportingData);
};
