import { useTranslation } from 'react-i18next';

import { FieldBudget } from '@features/campaigns/builder/components/FormFields/common/FieldBudget/FieldBudget';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { formatNumberAsMoney } from '@shared/utils/moneyFormat.ts';

import styles from './Budget.module.scss';

interface BudgetProps {
  onChangeCallback?: () => void;
  canRefetch?: boolean;
  readOnlyValue?: number;
  canEditBudget?: boolean;
}
export function Budget({ onChangeCallback, canRefetch, readOnlyValue, canEditBudget }: BudgetProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className={styles.wrapper}>
      <p className={styles.labelWrapper}>{t('availabilityAndCosts.budget')}</p>
      {readOnlyValue ? (
        <div className={styles.valueWrapper}>{formatNumberAsMoney(readOnlyValue)}</div>
      ) : (
        <FieldBudget
          labelVisible={false}
          onChangeCallback={onChangeCallback}
          disabled={canRefetch || !canEditBudget}
          debounced
        />
      )}
    </div>
  );
}
