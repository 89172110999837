import { CampaignListReducerState } from '@features/campaigns/list/types/campaignListReducer';
import { DataTableStateWithFilters } from '@shared/types/table';

export const removeCampaignsCountFromState = (state: CampaignListReducerState): DataTableStateWithFilters => {
  const stateCopy = { ...state };

  delete stateCopy.campaignsCount;

  return stateCopy;
};
