import { CampaignId } from '@shared/types/campaign.ts';

export const apiRoutes = {
  getAdvertisers: () => `/advertisers`,
  getBrands: (advertiserId: string) => `/advertisers/${advertiserId}/brands`,
  getContentPackages: () => `/content-packages`,
  getAgeAndGenderGroups: () => `/age-gender`,
  getInterestSegments: () => `/interest-segments`,
  getProvinces: () => `/provinces`,
  createCampaign: () => `/campaigns`,
  readCampaign: (campaignId: CampaignId) => `/campaigns/${campaignId}`,
  updateCampaignStep01: (campaignId: CampaignId) => `/campaigns/${campaignId}/details`,
  updateCampaignCTVAdProducts: (campaignId: CampaignId) => `/campaigns/ctv/${campaignId}/ad-products`,
  updateCampaignCrossAdProducts: (campaignId: CampaignId) => `/campaigns/cross/${campaignId}/ad-products`,
  updateCampaignStep04: (campaignId: CampaignId) => `/campaigns/${campaignId}/additional-note`,
  updateCampaignCTVTargeting: (campaignId: CampaignId) => `/campaigns/ctv/${campaignId}/targeting`,
  updateCampaignCrossTargeting: (campaignId: CampaignId) => `/campaigns/cross/${campaignId}/targeting`,
  creativeLShapeImage: (campaignId: CampaignId) => `/campaigns/${campaignId}/l-shape-image`,
  creativeLandingImage: (campaignId: CampaignId) => `/campaigns/${campaignId}/landing-image`,
  submitCampaign: (campaignId: CampaignId) => `/campaigns/${campaignId}/submit`,
  readPricing: (campaignId: CampaignId) => `/campaigns/${campaignId}/pricing`,
  updatePricing: (campaignId: CampaignId) => `/campaigns/${campaignId}/pricing`,
};
