import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { ApiError } from '@shared/utils/interfaces/error.ts';

import styles from './ErrorMessage.module.scss';

interface ErrorToastMessageProps {
  error: ApiError | string;
}

function ErrorMessageText({ error }: ErrorToastMessageProps) {
  const { t } = useTranslation(i18nNameSpace.API);

  if (typeof error === 'string') {
    return <span className={styles.toastMessageText}>{t(error)}</span>;
  }

  const { key, props } = error.i18n;

  return <span className={styles.toastMessageText}>{t(key, { ...props })}</span>;
}

export function ErrorMessage({ error }: ErrorToastMessageProps) {
  const { t } = useTranslation(i18nNameSpace.API);

  return (
    <>
      <p className={styles.toastMessageTitle}>{t('api.error.common.toastHeaderText')}</p>
      <ErrorMessageText error={error} />
    </>
  );
}
