import { apiRoutes } from '@features/campaigns/performance/consts/apiRoutes.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { ConfigResponseTypes } from '@shared/types/apiClient.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export async function downloadPptxPerformancePresentation(campaignId: CampaignId): Promise<Blob | null> {
  return await apiClient.get<Blob>(
    apiRoutes.downloadPptxPerformancePresentation(campaignId),
    undefined,
    ConfigResponseTypes.BLOB,
  );
}

export async function downloadXlsxPerformancePresentation(campaignId: CampaignId): Promise<Blob | null> {
  return await apiClient.get<Blob>(
    apiRoutes.downloadXlsxPerformancePresentation(campaignId),
    undefined,
    ConfigResponseTypes.BLOB,
  );
}
