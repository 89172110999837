import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { VirtualScroller } from 'primereact/virtualscroller';
import { Dispatch, SetStateAction } from 'react';

import { LoadingItemTemplate } from '@features/campaigns/shared/components/FormFields/Dropdown/components/LoadingItemTemplate/LoadingItemTemplate';
import { Advertiser } from '@features/campaigns/shared/types/campaign.ts';
import { ItemTemplate } from '@features/users/details/components/TreeWithSearch/ItemTemplate/ItemTemplate.tsx';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';

import styles from './TreePanel.module.scss';

interface TreePanelProps {
  data: Advertiser[] | [];
  onCheckboxChange: (event: CheckboxChangeEvent) => void;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>> | ((value: string[]) => void);
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<AdManagerApiResponse<Advertiser[]> | null, unknown>, Error>>;
  currentItemsCount: number;
  totalItems: number;
  isFetching?: boolean;
}

export function TreePanel({
  data,
  onCheckboxChange,
  selectedItems,
  fetchNextPage,
  currentItemsCount,
  totalItems,
  isFetching,
}: TreePanelProps) {
  return (
    <div className={styles.wrapper}>
      <VirtualScroller
        className={styles.scrollerContainer}
        items={data}
        itemTemplate={(item, options) =>
          ItemTemplate(item, options, {
            selectedItems,
            onCheckboxChange,
          })
        }
        lazy
        showLoader
        loading={isFetching}
        loadingTemplate={<LoadingItemTemplate />}
        itemSize={32}
        step={20}
        onLazyLoad={async (event) => {
          const canFetchNextPage = event.last === currentItemsCount && currentItemsCount < totalItems;
          if (canFetchNextPage) {
            await fetchNextPage();
          }
        }}
      />
    </div>
  );
}
