import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import StatusIcon from '@features/campaigns/shared/components/StatusIcon/StatusIcon';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Campaign } from '@shared/utils/interfaces/campaign';

function StatusBodyTemplate(campaign: Campaign) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <span className={cn(styles.columnValues, styles.statusValue)}>
      <StatusIcon variant={campaign.status} />
      {t(`page.list.campaignStatus.${campaign.status.toLowerCase()}`)}
    </span>
  );
}

export default StatusBodyTemplate;
