import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes';
import {
  mockedBillingContactsData,
  mockedBillingContactUpdateData,
} from '@shared/mocks/requests/billingContacts/billingContacts.fixtures';

export const billingContactsRequests = [
  http.get(`*${apiRoutes.getBillingContacts(':advertiserId')}`, () => {
    return HttpResponse.json(mockedBillingContactsData);
  }),
  http.put(`${apiRoutes.updateBillingDetails(':campaignId')}`, () => {
    return HttpResponse.json(mockedBillingContactUpdateData);
  }),
];
