import { Item } from '@features/campaigns/builder/services/formFields';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { createMockedCampaignData } from '@shared/mocks/requests/campaignDetails/campaignDetails.fixtures';
import { STATUS } from '@shared/types/common';

export const mockedBillingContactsData: AdManagerApiResponse<Item[]> = {
  items: [
    {
      id: 'xcff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Billing contact #1',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: false,
      code: '1112',
    },
    {
      id: '776013ce-56e7-49f6-b22b-1433638b33fb',
      name: 'Billing contact #2',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: false,
      code: '2212',
    },
    {
      id: 'b663f46b-02ea-4531-9b51-01578c51b7b7',
      name: 'Billing contact #3',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: true,
      code: '3312',
    },
  ],
  meta: {
    currentPage: 1,
    itemCount: 3,
    itemsPerPage: 3,
    totalItems: 3,
    totalPages: 1,
  },
};

export const mockedBillingContactUpdateData = createMockedCampaignData({ authorizedBudget: null });
