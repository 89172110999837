import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import API_EN from './src/locale/en/api.json';
import CAMPAIGNS_EN from './src/locale/en/campaigns.json';
import COMMON_EN from './src/locale/en/common.json';
import USERS_EN from './src/locale/en/users.json';
import API_IT from './src/locale/it/api.json';
import CAMPAIGNS_IT from './src/locale/it/campaigns.json';
import COMMON_IT from './src/locale/it/common.json';
import USERS_IT from './src/locale/it/users.json';
import { LocalStorageKeys } from './src/shared/consts/localStorageKeys';

const resources = {
  en: {
    common: COMMON_EN,
    campaigns: CAMPAIGNS_EN,
    users: USERS_EN,
    api: API_EN,
  },
  it: {
    common: COMMON_IT,
    campaigns: CAMPAIGNS_IT,
    users: USERS_IT,
    api: API_IT,
  },
};

await i18n.use(initReactI18next).init({
  debug: false,
  resources,
  // TODO: create global controller for handling storage
  lng: localStorage.getItem(LocalStorageKeys.LANGUAGE) || 'it',
  fallbackLng: 'it',
  interpolation: { escapeValue: false },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'span', 'strong', 'i'],
    useSuspense: true,
  },
});
