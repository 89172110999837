import { AccessRight, AccessRights } from '@shared/api/commonApiInterfaces';

export const billingDetailsAccessRights: AccessRight[] = [
  AccessRights.EDIT_AGENCY,
  AccessRights.READ_AGENCY,
  AccessRights.EDIT_AUTHORIZED_BUDGET,
  AccessRights.READ_AUTHORIZED_BUDGET,
  AccessRights.EDIT_BILLING_CONTACT,
  AccessRights.READ_BILLING_CONTACT,
  AccessRights.EDIT_PURCHASE_ORDER,
  AccessRights.READ_PURCHASE_ORDER,
];
