import { ValueOf } from '@shared/types/common.ts';

export interface MetricChartProps {
  data: CampaignPerformanceGeneralType;
  selectedChartType: MetricChartValueType;
  dateRange: string;
}

export interface MetricsGraphData {
  labels: string[];
  values: number[];
}

export const MetricChartValue = {
  IMPRESSIONS: 'Impressions',
  DEVICES: 'Devices',
  FREQUENCY: 'Frequency',
} as const;

export type MetricChartValueType = ValueOf<typeof MetricChartValue>;

export interface CampaignPerformanceGeneralType {
  summary: {
    impressions: number;
    uniqueDevices: number;
    frequency: number;
  };
  impressionsByDayGraph: MetricsGraphData;
  impressionsByHourGraph: MetricsGraphData;
  uniqueDevicesGraph: MetricsGraphData;
  frequencyGraph: MetricsGraphData;
}
