export function formatNameWithCode(name?: string, code?: string, fallbackValue?: string) {
  if (!name && !code) {
    return fallbackValue;
  }

  if (!code) {
    return name;
  }

  return `${name} (${code})`;
}
