import { format } from 'date-fns';

import { ExportReport } from '@features/campaigns/performance/components/ExportReport/ExportReport.tsx';
import ImpressionsDistribution from '@features/campaigns/performance/components/ImpressionsDistribution/ImpressionsDistribution.tsx';
import { TopCharts } from '@features/campaigns/performance/components/TopCharts/TopCharts.tsx';
import { GeneralMetrics } from '@features/campaigns/performance/containers/GeneralMetrics/GeneralMetrics.tsx';
import { CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';

import styles from './Performance.module.scss';

interface PerformanceProps {
  campaignFormat?: CampaignFormat[];
  campaignStartDate?: string;
  campaignEndDate?: string;
}

const getDateRange = (campaignStartDate?: string, campaignEndDate?: string) => {
  const startDate = new Date(campaignStartDate as string);
  const formattedStartDate = format(startDate, 'dd/MM/yyyy');
  const endDate = new Date(campaignEndDate as string);
  const formattedEndDate = format(endDate, 'dd/MM/yyyy');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

function Performance({ campaignStartDate, campaignEndDate }: PerformanceProps) {
  const dateRange = getDateRange(campaignStartDate, campaignEndDate);

  return (
    <>
      <section className={styles.spaceBetween}>
        <div className={styles.dateRange} data-testid='campaign-date-range'>
          {dateRange}
        </div>
        <ExportReport />
      </section>
      <GeneralMetrics dateRange={dateRange} />
      <ImpressionsDistribution dateRange={dateRange} />
      <TopCharts />
    </>
  );
}

export default Performance;
