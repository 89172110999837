import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';

import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './DeleteCampaignModal.module.scss';

interface DeleteCampaignModalProps {
  isVisible: boolean;
  toggleModalVisibility: () => void;
  deleteCallback: () => void;
  campaignName: string | undefined;
  deleteCallbackActionIsLoading: boolean;
}

export function DeleteCampaignModal({
  isVisible,
  toggleModalVisibility,
  deleteCallback,
  campaignName,
  deleteCallbackActionIsLoading,
}: DeleteCampaignModalProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <Dialog
      className={styles.deleteModalContainer}
      visible={isVisible}
      onHide={toggleModalVisibility}
      draggable={false}
      resizable={false}
      header={t('deleteModal.title')}
      footer={
        <DefaultActionsFooter
          submitLabel={t('deleteModal.deleteButtonText')}
          cancelLabel={t('deleteModal.cancelButtonText')}
          cancelCallback={toggleModalVisibility}
          submitCallback={deleteCallback}
          submitCallbackActionIsLoading={deleteCallbackActionIsLoading}
          severity={'danger'}
        />
      }
    >
      <Trans t={t} i18nKey={'deleteModal.content.deleteConfirmationQuestion'} values={{ campaignName }}>
        Are you sure you want to delete <strong>{campaignName}</strong>? This process cannot be undone.
      </Trans>
    </Dialog>
  );
}
