import { apiRoutes } from '@features/users/list/consts/apiRoutes';
import { ExternalUser } from '@features/users/list/types/externalUser.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';

interface FetchUserDetailsParams {
  userId: string;
}

export async function fetchExternalUserDetails({ userId }: FetchUserDetailsParams): Promise<ExternalUser | null> {
  return await apiClient
    .get<AdManagerApiResponse<ExternalUser[]>>(apiRoutes.getExternalUsers())
    .then((data) => {
      const userDetailsData = data?.items?.find((userData) => userData.id === userId);
      return userDetailsData || null;
    })
    .catch((error) => {
      throw error;
    });
}
