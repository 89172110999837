import cn from 'classnames';
import { format } from 'date-fns';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { Campaign } from '@shared/utils/interfaces/campaign';

function EndDateColumnBodyTemplate(campaign: Campaign) {
  const endDate = new Date(campaign.endDate);
  const formattedDate = format(endDate, 'dd/MM/yyyy');

  return <span className={cn(styles.columnValues, styles.endDateValue)}>{formattedDate}</span>;
}

export default EndDateColumnBodyTemplate;
