import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import { navigationItems } from '@shared/components/Navigation/utils/navigationItems';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './Navigation.module.scss';

function Navigation() {
  const { t } = useTranslation(i18nNameSpace.COMMON);
  const currentLocation = useLocation().pathname;

  return (
    <nav className={styles.navigationWrapper}>
      {navigationItems.map(({ route, requiredPermissions, displayName, icon }) => (
        <AllowAccess requiredPermissions={requiredPermissions} key={displayName}>
          <Link
            data-testid={`${displayName}-navigation-link`}
            className={cn('p-button', currentLocation === route ? 'active' : '')}
            to={route}
            key={displayName}
          >
            <i className={cn('pi', icon)}></i>
            <span>{t(displayName)}</span>
          </Link>
        </AllowAccess>
      ))}
    </nav>
  );
}

export default Navigation;
