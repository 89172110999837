import { endOfDay, formatISO9075, startOfDay } from 'date-fns';

import {
  CreateCampaignFormStep01Values,
  RestoredStep01Data,
} from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import { CampaignDetails, CampaignMediaType, CampaignMediaTypes } from '@features/campaigns/shared/types/campaign.ts';

export interface CreateCampaignStep01Payload
  extends Omit<CreateCampaignFormStep01Values, 'dateRange' | 'budget' | 'isCustomCampaignName'> {
  startDate: string;
  endDate: string;
  budget: string;
  isNameAutoGenerated: boolean;
  mediaType?: CampaignMediaType;
}

export interface CreateCampaignStep01PayloadWithUrlToCampaignDetails extends CreateCampaignStep01Payload {
  campaignDetailsUiUrlTemplate: string;
}

export function createCampaignStep01DTO(
  formValues: CreateCampaignFormStep01Values,
  mediaType: CampaignMediaType = CampaignMediaTypes.CTV,
): CreateCampaignStep01Payload {
  const { advertiserId, brandId, name, isAgencyCommissionGross, isNameAutoGenerated } = formValues;
  const dateRange = formValues.dateRange || [];

  return {
    advertiserId,
    brandId,
    name,
    isNameAutoGenerated,
    isAgencyCommissionGross,
    budget: formValues.budget.toString(),
    startDate: formatISO9075(dateRange[0], { representation: 'date' }),
    endDate: formatISO9075(dateRange[1], { representation: 'date' }),
    mediaType,
  };
}

export function restoreStep01DataDTO(data: CampaignDetails): RestoredStep01Data {
  const startDate = startOfDay(new Date(data.startDate));
  const endDate = endOfDay(new Date(data.endDate));

  return {
    id: data.id,
    advertiserId: data.advertiser.id,
    advertiserName: data.advertiser.name,
    advertiserCode: data.advertiser.code,
    brandId: data.brand.id,
    brandName: data.brand.name,
    brandCode: data.brand.code,
    name: data.name,
    isAgencyCommissionGross: data.isAgencyCommissionGross,
    dateRange: [startDate, endDate],
    budget: data.budget,
    isNameAutoGenerated: data.isNameAutoGenerated,
    campaignType: data.type,
    status: data.status,
    createdBy: data.createdBy,
  };
}

export function restoreStep01FormValues(data: RestoredStep01Data): CreateCampaignFormStep01Values {
  return {
    advertiserId: data.advertiserId,
    brandId: data.brandId,
    name: data.name,
    isAgencyCommissionGross: data.isAgencyCommissionGross,
    dateRange: data.dateRange,
    budget: data.budget,
    isNameAutoGenerated: data.isNameAutoGenerated,
  };
}
