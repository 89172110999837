import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './ResultsCounter.module.scss';

interface ResultsCounterProps {
  currentItemsCount: number;
  totalItemsCount: number;
}
export function ResultsCounter({ currentItemsCount, totalItemsCount }: ResultsCounterProps) {
  const { t } = useTranslation([i18nNameSpace.USERS]);

  return (
    <p className={styles.wrapper}>
      {t('page.details.advertisersAssign.resultsCount', { currentItemsCount, totalItemsCount })}
    </p>
  );
}
