import { ReactNode } from 'react';

import { ControlAccess } from '@features/auth/ControlAccess/ControlAccess';
import { Permission } from '@shared/consts/permissions';

interface AllowAccessProps {
  requiredPermissions?: Permission[];
  accessDeniedRedirectTo?: string;
  children: ReactNode;
  fallbackView?: ReactNode;
  additionalCondition?: boolean;
}

export function AllowAccess({
  requiredPermissions,
  children,
  accessDeniedRedirectTo,
  fallbackView,
  additionalCondition,
}: AllowAccessProps) {
  return (
    <ControlAccess
      requiredPermissions={requiredPermissions}
      accessDeniedRedirectTo={accessDeniedRedirectTo}
      fallbackView={fallbackView}
      additionalCondition={additionalCondition}
    >
      {children}
    </ControlAccess>
  );
}
