import { BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import config from '../../../config.ts';

const msalConfig: Configuration = {
  auth: {
    clientId: config.AD_SPA_CLIENT_ID,
    authority: config.AD_AUTHORITY,
    redirectUri: config.AD_REDIRECT_URI,
    knownAuthorities: [new URL(config.AD_AUTHORITY).hostname],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const authRequest = {
  scopes: ['openid', config.AD_API_SCOPE],
};

export const authRequestForSilentAndPopupFlows = {
  ...authRequest,
  redirectUri: `${config.REDIRECT_URI}/blank.html`,
};

export const msalInstance = new PublicClientApplication(msalConfig);
