import cn from 'classnames';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import newLogoText from '@shared/assets/icons/admanager-logo-text@2x.png';
import errorImg from '@shared/assets/icons/error.png';
import { i18nNameSpace } from '@shared/consts/i18n';
import { routePaths } from '@shared/router/routePaths';

import styles from './ErrorPage.module.scss';

interface Props {
  statusCode?: 404 | 500;
}

function ErrorPage({ statusCode }: Props) {
  const isNotFoundPage = statusCode === 404;
  const determinedTranslationKey = statusCode || 500;
  const navigate = useNavigate();
  const { t } = useTranslation(i18nNameSpace.COMMON);

  const goBackToCampaignsList = () => {
    navigate(routePaths.campaigns);
  };

  return (
    <section className={styles.container}>
      <div className={styles.gradientLine} />
      <header className={styles.headerContainer}>
        <button className={styles.logoRedirection} onClick={goBackToCampaignsList}>
          <img src={newLogoText} className={styles.newLogoText} alt='Mediaset AdManager' />
        </button>
      </header>
      <main className={styles.mainContainer}>
        <img src={errorImg} className={styles.errorImg} alt='Error' />

        {statusCode && <Tag className={styles.errorTag} value={`${t('errorPage.error')} ${statusCode}`} />}

        <h2 className={styles.title}>{t(`errorPage.${determinedTranslationKey}.title`)}</h2>

        <p className={styles.secondaryText}>
          {t(`errorPage.${determinedTranslationKey}.description`)}{' '}
          {!isNotFoundPage && <span className={styles.block}>{t('errorPage.tryAgain')}</span>}
        </p>

        {isNotFoundPage && (
          <Button onClick={goBackToCampaignsList} className={styles.goBackButton}>
            {t('errorPage.goBack')}
          </Button>
        )}

        <p className={cn(styles.secondaryText, styles.support)}>
          {t('errorPage.support')}{' '}
          <a href='mailto:admanagerfailure@mediaset.it' className={styles.contactEmail}>
            admanagerfailure@mediaset.it
          </a>
        </p>
        <p className={styles.secondaryText}>
          {t('errorPage.thanks')} <span className={styles.block}>{t('errorPage.teamName')}</span>
        </p>
      </main>
    </section>
  );
}

export default ErrorPage;
