import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './PlaceholderCard.module.scss';

interface PlaceholderCardProps {
  textKey: string;
}
export function PlaceholderCard({ textKey }: PlaceholderCardProps) {
  const { t } = useTranslation(i18nNameSpace.COMMON);

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{t(textKey)}</p>
    </div>
  );
}
