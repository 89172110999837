import cn from 'classnames';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './SearchInput.module.scss';

interface SearchInputProps {
  setSearchInputValueCallback: Dispatch<SetStateAction<string | undefined>> | ((value: string | undefined) => void);
}

export function SearchInput({ setSearchInputValueCallback }: SearchInputProps) {
  const { t } = useTranslation([i18nNameSpace.USERS]);
  const [inputValue, setInputValue] = useState('');

  const handleClearSearch = () => {
    setSearchInputValueCallback(undefined);
    setInputValue('');
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValueCallback(event.target.value);
    setInputValue(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <span className={cn('p-input-icon-left', styles.inputWrapper, styles.searchIcon)}>
        <i className={cn('pi pi-search')} />
        <InputText
          className={styles.input}
          placeholder={t('page.details.advertisersAssign.searchPlaceholder')}
          data-testid={'field-search-advertiser'}
          value={inputValue}
          onChange={(event) => onChangeHandler(event)}
        />
        <button type='button' className={styles.clear} onClick={handleClearSearch} data-testid={'field-search-clear'}>
          <i className='pi pi-times-circle' />
        </button>
      </span>
    </div>
  );
}
