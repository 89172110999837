import { Item } from '@features/campaigns/builder/services/formFields';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { STATUS } from '@shared/types/common';

export const mockedAgenciesData: AdManagerApiResponse<Item[]> = {
  items: [
    {
      id: '1cff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Agency #1',
      status: STATUS.ACTIVE,
      code: '1312444',
    },
    {
      id: '2cff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Agency #2',
      status: STATUS.ACTIVE,
      code: '1312444',
    },
    {
      id: '3cff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Agency #3',
      status: STATUS.ACTIVE,
      code: '13532234',
    },
  ],
  meta: {
    currentPage: 1,
    itemCount: 3,
    itemsPerPage: 3,
    totalItems: 3,
    totalPages: 1,
  },
};
