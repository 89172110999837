import cn from 'classnames';
import { Sidebar as PrimeReactSidebar } from 'primereact/sidebar';
import { useState } from 'react';

import admanagerLogoSignet from '@shared/assets/images/admanager-logo-signet.svg';
import admanagerLogo from '@shared/assets/images/admanager-logo.svg';
import Navigation from '@shared/components/Navigation/Navigation.tsx';
import UserProfile from '@shared/components/UserProfile/UserProfile.tsx';

import styles from './Sidebar.module.scss';

function Sidebar() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <PrimeReactSidebar
      data-testid={'navigation-sidebar'}
      showCloseIcon={false}
      appendTo='self'
      visible
      onMouseOver={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onHide={() => setIsVisible(false)}
      maskClassName={cn(styles.sidebarMaskWrapper, isVisible ? styles.sidebarUncollapse : styles.sidebarCollapse)}
      className={cn(styles.sidebarWrapper)}
    >
      <div className={styles.sidebarHeader}>
        <img
          src={admanagerLogoSignet}
          className={cn(styles.logoImg, { [styles.zeroOpacity]: isVisible })}
          alt={'AdManager'}
        />
        <img
          src={admanagerLogo}
          className={cn(styles.newLogoText, { [styles.fullOpacity]: isVisible })}
          alt='Mediaset AdManager'
        />
      </div>
      <Navigation />
      <div className={styles.sidebarFooter}>
        <UserProfile isSidebarCollapsed={!isVisible} />
      </div>
    </PrimeReactSidebar>
  );
}

export default Sidebar;
