import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';

import commonStyles from '@features/campaigns/builder/containers/ImpressionsCalculator/ImpressionsCalculator.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { formatNumbersAsCompactNumbers, formatNumberWithCommas } from '@shared/utils/numberFormat.ts';

import styles from './AvailableImpressions.module.scss';

const IMPRESSIONS_VALUE_EXCEEDED_COLOR = '#E24C4C';
const IMPRESSIONS_VALUE_DEFAULT_COLOR = '#A855F7';
const COMPACT_NUMBERS_FORMAT_THRESHOLD = 1000000;

function expectedProgressBarColor(percentageValue: number): string {
  if (percentageValue >= 100) {
    return IMPRESSIONS_VALUE_EXCEEDED_COLOR;
  }
  return IMPRESSIONS_VALUE_DEFAULT_COLOR;
}

function calculatePercentageValue(value: number, maxValue: number): number {
  return (value / maxValue) * 100;
}

interface AvailableImpressionsProps {
  value: number;
  maxValue: number;
}

function formatNumberByMagnitude(value: number): string {
  if (value >= COMPACT_NUMBERS_FORMAT_THRESHOLD) {
    return formatNumbersAsCompactNumbers(value);
  } else {
    return formatNumberWithCommas(value);
  }
}

export function AvailableImpressions({ value, maxValue }: AvailableImpressionsProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const impressionsPercentageValue = calculatePercentageValue(value, maxValue);
  const expectedProgressBarColorValue = expectedProgressBarColor(impressionsPercentageValue);
  const formattedRangeValue = formatNumberByMagnitude(value);
  const formattedRangeMaxValue = formatNumberByMagnitude(maxValue);

  return (
    <div className={styles.wrapper}>
      <p className={commonStyles.fieldLabel}>{t('availabilityAndCosts.impressionsAvailable')}</p>
      <div className={styles.progressBarWrapper}>
        <ProgressBar
          value={impressionsPercentageValue}
          showValue={false}
          color={expectedProgressBarColorValue}
          className={styles.background}
        />
      </div>
      {formattedRangeValue !== '0' || formattedRangeMaxValue !== '0' ? (
        <p className={styles.progressBarValues}>
          {formattedRangeValue} / {formattedRangeMaxValue}
        </p>
      ) : null}
    </div>
  );
}
