import { Outlet, useLocation, useParams } from 'react-router-dom';

import PageHeader from '@features/campaigns/builder/components/PageHeader/PageHeader.tsx';
import Stepper from '@features/campaigns/builder/components/Stepper/Stepper';
import { getStepperActiveIndex } from '@features/campaigns/builder/utils/getStepperActiveIndex.ts';
import { LeaveGuardModalProvider } from '@shared/providers/LeaveGuardModalProvider/LeaveGuardModalProvider';

import styles from './PageLayout.module.scss';

function BuilderPageLayout() {
  const { pathname } = useLocation();
  const { campaignId } = useParams();
  const stepperActiveIndex = getStepperActiveIndex(pathname, campaignId);

  return (
    <LeaveGuardModalProvider>
      <section>
        <PageHeader />
        <div className='content-wrapper'>
          <div className={styles.stepperContainer}>
            <Stepper activeIndex={stepperActiveIndex} />
          </div>
          <div className={styles.formStepContainer}>
            <Outlet />
          </div>
        </div>
      </section>
    </LeaveGuardModalProvider>
  );
}

export default BuilderPageLayout;
