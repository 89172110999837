import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DetailsAndStatus from '@features/campaigns/details/components/CampaignDetails/DetailsAndStatus/DetailsAndStatus';
import AvailabilityAndCost from '@features/campaigns/details/components/CampaignDetails/ImpressionsCalculator/AvailabilityAndCost';
import { CampaignDetailsViewData } from '@features/campaigns/details/containers/CampaignDetailsPage/CampaignDetailsPage.dto.ts';
import { AdProducts } from '@features/campaigns/shared/containers/Review/AdProducts/AdProducts';
import { CampaignDetails as CampaignDetailsSection } from '@features/campaigns/shared/containers/Review/CampaignDetails/CampaignDetails';
import { MediaType } from '@features/campaigns/shared/containers/Review/MediaType/MediaType';
import { Note } from '@features/campaigns/shared/containers/Review/Note/Note';
import { Target } from '@features/campaigns/shared/containers/Review/Target/Target';
import { InvalidSectionsItem } from '@features/campaigns/shared/types/campaign.ts';
import { WarningMessage } from '@shared/components/Toast/WarningMessage/WarningMessage.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface CampaignDetailsProps {
  data: CampaignDetailsViewData;
  invalidSections: InvalidSectionsItem[];
}

function CampaignDetails({ data, invalidSections }: CampaignDetailsProps) {
  const { t } = useTranslation(i18nNameSpace.API);

  useEffect(() => {
    if (invalidSections.length > 0) {
      invalidSections.forEach((section) => {
        toast.warning(<WarningMessage message={t(section.details)} />);
      });
    }
  }, [invalidSections]);

  return (
    <section className='grid'>
      <div className='col-4'>
        <DetailsAndStatus
          data={data.detailsAndStatus}
          formData={data.billingDetails}
          accessRights={data.accessRights}
        />
      </div>
      <div className='col-8'>
        <AvailabilityAndCost data={data.availabilityAndCost} />
        <MediaType headerText={'review.sectionTitle.mediaType'} data={data.mediaType} />
        <CampaignDetailsSection headerText={'review.sectionTitle.campaignDetails'} data={data.campaignDetails} />
        <Target
          headerText={'review.sectionTitle.target'}
          data={data.target}
          campaignType={data.type}
          mediaType={data.mediaType}
        />
        <AdProducts
          headerText={'review.sectionTitle.adProducts'}
          data={data.adProducts}
          campaignType={data.type}
          mediaType={data.mediaType}
        />
        <Note headerText={'review.sectionTitle.additionalNote'} data={data.additionalNote} />
      </div>
    </section>
  );
}

export default CampaignDetails;
