import cn from 'classnames';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';

import NameWithCode from '@features/campaigns/shared/components/NameWithCode/NameWithCode';
import { Advertiser } from '@features/campaigns/shared/types/campaign.ts';
import styles from '@features/users/details/components/TreeWithSearch/TreePanel/TreePanel.module.scss';

interface ItemTemplateProps {
  selectedItems: string[];
  onCheckboxChange: (event: CheckboxChangeEvent) => void;
}

export function ItemTemplate(
  item: Advertiser,
  options: VirtualScrollerTemplateOptions,
  { selectedItems, onCheckboxChange }: ItemTemplateProps,
) {
  const handleChange = (event: CheckboxChangeEvent) => {
    onCheckboxChange(event);
  };

  const isSelected = selectedItems.includes(item.id);

  return (
    <div className={styles.itemWrapper} style={{ height: options.props.itemSize + 'px' }}>
      <Checkbox
        className={styles.checkbox}
        inputId={item.id}
        onChange={handleChange}
        checked={isSelected}
        value={item.id}
        name='advertiser'
        disabled={item.isPreliminaryBlocked}
        data-testid={`${item.name}-checkbox-item`}
      />
      <label htmlFor={item.id} className={cn(item.isPreliminaryBlocked && 'p-disabled')}>
        <NameWithCode name={item.name} code={item.code} disabled={item.isPreliminaryBlocked} />
      </label>
    </div>
  );
}
