import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { MutableRefObject } from 'react';

import styles from './ExportMenu.module.scss';

interface ExportMenuProps {
  menuItems: MenuItem[];
  menuRef: MutableRefObject<null | Menu>;
  dataTestId: 'export-details-menu' | 'export-report-menu';
}

export function ExportMenu({ menuItems, menuRef, dataTestId }: ExportMenuProps) {
  return <Menu className={styles.menuWrapper} model={menuItems} popup ref={menuRef} data-testid={dataTestId} />;
}
