import { TFunction } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import commonDropdownStyles from '@features/campaigns/performance/components/shared/styles/GroupByDropdown.module.scss';
import { TopChartsData } from '@features/campaigns/performance/components/TopCharts/TopCharts.types.ts';
import { ChartNoData } from '@features/campaigns/performance/shared/components/ChartNoData/ChartNoData.tsx';
import { BarColors, ChartKeys } from '@features/campaigns/performance/shared/components/HorizontalBarChart/consts';
import HorizontalBarChart from '@features/campaigns/performance/shared/components/HorizontalBarChart/HorizontalBarChart';
import commonStyles from '@features/campaigns/performance/shared/styles/TopSectionsCommonStyles.module.scss';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

const getGroupByOptions = (t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  return [
    {
      label: t('page.campaignDetails.performanceTab.topGeoSelectOptions.groupByRegion'),
      value: 'region',
    },
    {
      label: t('page.campaignDetails.performanceTab.topGeoSelectOptions.groupByProvince'),
      value: 'province',
    },
  ];
};

interface TopGeoData {
  provinces: TopChartsData['provinces'];
  regions: TopChartsData['regions'];
}

interface TopGeoProps {
  isFetching: boolean;
  data: TopGeoData | null;
}

function TopGeo({ isFetching, data }: TopGeoProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const groupByOptions: SelectItemOptionsType = getGroupByOptions(t);
  const [groupBy, setGroupBy] = useState(groupByOptions[0].value);
  const properData = groupBy === groupByOptions[0].value ? data?.regions : data?.provinces;
  const isNoData = properData === undefined || properData.labels.length === 0;

  return (
    <section data-testid='top-geo-section'>
      <h3 className={commonStyles.title} data-testid='top-geo-section-title'>
        {t('page.campaignDetails.performanceTab.subtitles.topGeo')}
      </h3>
      <div className={commonStyles.container}>
        {isFetching && <Spinner variant='elementOverlay' />}
        <Dropdown
          aria-label='group-by-geo'
          value={groupBy}
          onChange={(event) => setGroupBy(event.value)}
          options={groupByOptions}
          className={commonDropdownStyles.groupByDropdown}
          data-testid='select-group-by-geo'
        />

        {isNoData ? (
          <ChartNoData />
        ) : (
          <HorizontalBarChart
            data={properData}
            barColor={BarColors.primary}
            dataTestId='top-geo'
            chartKey={groupBy === groupByOptions[0].value ? ChartKeys.REGION : ChartKeys.PROVINCE}
          />
        )}
      </div>
    </section>
  );
}

export default TopGeo;
