import { formatNumberAsMoney } from '@shared/utils/moneyFormat.ts';
import { formatNumberWithCommas } from '@shared/utils/numberFormat.ts';

import styles from './ItemValue.module.scss';

interface ItemValueProps {
  value?: number | '';
  moneyFormat?: boolean;
}

function formatValue(value: ItemValueProps['value'], moneyFormat: ItemValueProps['moneyFormat']) {
  if (typeof value === 'undefined') {
    return '-';
  }

  if (value === '') {
    return value;
  }

  return moneyFormat ? formatNumberAsMoney(value) : formatNumberWithCommas(value);
}

export function ItemValue({ value, moneyFormat = false }: ItemValueProps) {
  const formattedValue = formatValue(value, moneyFormat);

  return (
    <p className={styles.wrapper} data-testid='item-value'>
      {formattedValue}
    </p>
  );
}
