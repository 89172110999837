import { apiRoutes } from '@features/users/details/consts/apiRoutes';
import {
  AdvertisersAssignPayload,
  AdvertisersAssignResponse,
} from '@features/users/details/containers/AdvertisersAssign/AdvertisersAssign.dto';
import { apiClient } from '@shared/api/ApiClient';
import { UserId } from '@shared/types/users';

export async function assignAdvertisersForExternalUser(
  userId: UserId,
  payload: AdvertisersAssignPayload,
): Promise<AdvertisersAssignResponse | null> {
  const isPayloadEmpty = payload.advertiserIds && payload.advertiserIds.length === 0;
  const preparedPayload = isPayloadEmpty ? { advertiserIds: null } : payload;

  return await apiClient.post<AdvertisersAssignPayload, AdvertisersAssignResponse>(
    apiRoutes.assignAdvertisersToExternalUser(userId),
    preparedPayload,
  );
}
