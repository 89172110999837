import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/users/list/consts/apiRoutes';

import { mockedExternalUsersList } from './externalUsersList.fixtures.ts';

export const externalUsersListRequests = [
  http.get(`*${apiRoutes.getExternalUsers()}`, ({ request }) => {
    const search = new URL(request.url).searchParams.get('search');

    if (search === 'hellothere') {
      return HttpResponse.json({ items: [] });
    }

    return HttpResponse.json(mockedExternalUsersList);
  }),
];
