import { RestoredStep01Data } from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import { restoreStep01DataDTO } from '@features/campaigns/builder/containers/FormPages/Step01/Step01.dto.ts';
import {
  RestoredStep03Data,
  restoreStep03DataDTO,
} from '@features/campaigns/builder/containers/FormPages/Step03/Step03.dto.ts';
import {
  RestoredStep04Data,
  restoreStep04DataDTO,
} from '@features/campaigns/builder/containers/FormPages/Step04/Step04.dto.ts';
import { ReviewData, reviewDataDTO } from '@features/campaigns/shared/containers/Review/Review.dto.ts';
import { CAMPAIGN_BUILDER_STEPS, CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';

import { RestoredStep02Data, restoreStep02DataDTO } from '../containers/FormPages/Step02/Step02.dto';

export type PossibleStepNumber = keyof typeof CAMPAIGN_BUILDER_STEPS;

type StepDataDTO = RestoredStep01Data | RestoredStep02Data | RestoredStep03Data | RestoredStep04Data | ReviewData;

export function transformCampaignDetailsToStepData(campaignDetails: CampaignDetails, stepNumber: PossibleStepNumber) {
  const stepTransformers: Record<PossibleStepNumber, () => StepDataDTO | undefined> = {
    [CAMPAIGN_BUILDER_STEPS.STEP_01]: () => restoreStep01DataDTO(campaignDetails),
    [CAMPAIGN_BUILDER_STEPS.STEP_02]: () => restoreStep02DataDTO(campaignDetails),
    [CAMPAIGN_BUILDER_STEPS.STEP_03]: () => restoreStep03DataDTO(campaignDetails),
    [CAMPAIGN_BUILDER_STEPS.STEP_04]: () => restoreStep04DataDTO(campaignDetails),
    [CAMPAIGN_BUILDER_STEPS.STEP_05]: () => reviewDataDTO(campaignDetails),
  };

  return stepTransformers[stepNumber]();
}
