import { useQuery } from '@tanstack/react-query';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { SelectButton } from 'primereact/selectbutton';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ChartLegend from '@features/campaigns/performance/components/ImpressionsDistribution/components/ChartLegend';
import {
  BeforeDrawChartParam,
  ImpressionsDistributionType,
  ImpressionsDistributionTypes,
} from '@features/campaigns/performance/components/ImpressionsDistribution/types';
import {
  getChartDataByImpressionsDistributionType,
  getChartOptions,
  getImpressionsDistributionTypes,
  getMetricsData,
  setTitleInsideDoughnutChart,
} from '@features/campaigns/performance/components/ImpressionsDistribution/utils';
import SelectButtonItemTemplate from '@features/campaigns/performance/components/shared/components/SelectButtonItemTemplate';
import selectButtonStyles from '@features/campaigns/performance/components/shared/styles/SelectButton.module.scss';
import { QUERY_KEYS } from '@features/campaigns/performance/consts/queryKeys';
import { getCampaignImpressionsDistribution } from '@features/campaigns/performance/services/getCampaignImpressionsDistribution';
import { ChartNoData } from '@features/campaigns/performance/shared/components/ChartNoData/ChartNoData';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './ImpressionsDistribution.module.scss';

ChartJS.register(ArcElement, Tooltip, {
  id: 'customPluginName',
  beforeDraw: (chart: BeforeDrawChartParam) => {
    // !IMPORTANT plugin registration works globally, to skip adding additional title to all charts in the application we need to check if it's a chart with the proper id
    if (chart.canvas.id === 'impressions-distribution-chart') {
      setTitleInsideDoughnutChart(chart);
    }
  },
});

interface ImpressionsDistributionProps {
  dateRange: string;
}

function ImpressionsDistribution({ dateRange }: ImpressionsDistributionProps) {
  const { campaignId } = useParams();

  const [impressionsDistributionType, setImpressionsDistributionType] = useState<ImpressionsDistributionType>(
    ImpressionsDistributionTypes.SCREEN,
  );
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.IMPRESSIONS_DISTRIBUTION, campaignId],
    queryFn: () => {
      if (campaignId) {
        return getCampaignImpressionsDistribution(campaignId);
      }
    },
  });

  const impressionsDistributionTypes = getImpressionsDistributionTypes(t);
  const chartData = getChartDataByImpressionsDistributionType(data, impressionsDistributionType, t);
  const metricsData = getMetricsData(impressionsDistributionType, data);
  const isNoData = chartData === null || chartData === undefined || chartData?.labels.length === 0;

  return (
    <section data-testid='impressions-distribution' className={styles.container}>
      <SelectButton
        options={impressionsDistributionTypes}
        value={impressionsDistributionType}
        onChange={(event) => {
          if (event.value !== null) {
            setImpressionsDistributionType(event.value);
          }
        }}
        optionLabel='label'
        itemTemplate={SelectButtonItemTemplate}
        className={selectButtonStyles.selectButtonWrapper}
        data-testid={'impressions-distribution-type-select-button'}
      />
      <div className={styles.card}>
        {isFetching && <Spinner variant='elementOverlay' />}
        {isNoData ? (
          <ChartNoData />
        ) : (
          <>
            <div className={styles.chartWrapper}>
              <Doughnut data={chartData} options={getChartOptions(dateRange)} id='impressions-distribution-chart' />
            </div>
            <ChartLegend chartData={chartData} metricsData={metricsData} />
          </>
        )}
      </div>
    </section>
  );
}

export default ImpressionsDistribution;
