import axios, { AxiosError, HttpStatusCode } from 'axios';

export const MAX_RETRY_AMOUNT = 1;

const isGetMethodRequest = (error: AxiosError) => {
  return error.response?.config.method === 'get';
};

const isNotUnauthorizedAccess = (error: AxiosError) => {
  return error.response?.status !== HttpStatusCode.Forbidden;
};

export const handleRetries = (failureCount: number, error: unknown) => {
  return (
    axios.isAxiosError(error) &&
    isNotUnauthorizedAccess(error) &&
    isGetMethodRequest(error) &&
    failureCount < MAX_RETRY_AMOUNT
  );
};
