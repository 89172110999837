import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes';
import { apiClient } from '@shared/api/ApiClient.ts';
import { ConfigResponseTypes } from '@shared/types/apiClient';
import { CampaignId } from '@shared/types/campaign.ts';

export async function downloadPptxDetailsPresentation(campaignId: CampaignId): Promise<Blob | null> {
  return await apiClient.get<Blob>(
    apiRoutes.downloadPptxDetailsPresentation(campaignId),
    undefined,
    ConfigResponseTypes.BLOB,
  );
}
