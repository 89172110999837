import { Item } from '@features/campaigns/builder/services/formFields';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { STATUS } from '@shared/types/common';

export const mockedBrandsData: AdManagerApiResponse<Item[]> = {
  items: [
    {
      id: '0224cb28-95ca-463e-adc0-0108b3772913',
      name: 'Brand #1',
      status: STATUS.ACTIVE,
      code: '9331',
    },
    {
      id: '1224cb28-95ca-463e-adc0-0108b3772913',
      name: 'Brand #2',
      status: STATUS.ACTIVE,
      code: '9332',
    },
    {
      id: '2224cb28-95ca-463e-adc0-0108b3772913',
      name: 'Brand #3',
      status: STATUS.INACTIVE,
      code: '9333',
    },
  ],
  meta: {
    itemCount: 3,
    itemsPerPage: 3,
    currentPage: 1,
    totalItems: 3,
    totalPages: 1,
  },
};
