import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ExternalUser } from '@features/users/list/types/externalUser.ts';
import { Item } from '@shared/components/Review/Item/Item.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './UserDetails.module.scss';

interface UserDetailsProps {
  data: ExternalUser;
}
export function UserDetails({ data }: UserDetailsProps) {
  const { t } = useTranslation([i18nNameSpace.USERS]);

  return (
    <section className={styles.wrapper}>
      <header className={styles.header}>
        <h2 className={styles.text}>{t('page.details.headers.userDetails')}</h2>
      </header>
      <div className={styles.cardWrapper}>
        <div className='col-12'>
          <Item label={t('page.details.userDetails.name')} value={data.givenName} />
        </div>
        <div className='col-12'>
          <Item label={t('page.details.userDetails.surname')} value={data.surname} />
        </div>
        <div className='col-12'>
          <Item label={t('page.details.userDetails.email')} value={data.mail} />
        </div>
        <div className='col-12 no-v-padding'>
          <Item label={t('page.details.userDetails.role')}>
            <span className={cn(styles.badge, styles.external)}>{t('page.details.roles.externalUser')}</span>
          </Item>
        </div>
      </div>
    </section>
  );
}
