import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { PrimeReactProvider } from 'primereact/api';
import { RouterProvider } from 'react-router-dom';

import AuthenticatingScreen from '@features/auth/AuthenticatingScreen/AuthenticatingScreen';
import { authRequest } from '@shared/providers/AzureAuthProvider/authConfig.ts';
import { AzureAuthProvider } from '@shared/providers/AzureAuthProvider/AzureAuthProvider';
import { FeatureFlagsProvider } from '@shared/providers/FeatureFlagsProvider/FeatureFlagsProvider.tsx';
import { primeReactConfiguration } from '@shared/providers/PrimeReactConfiguration/primeReactConfiguration.ts';
import { TanstackQueryProvider } from '@shared/providers/TanstackQueryProvider/TanstackQueryProvider.tsx';
import { router } from '@shared/router/router.tsx';

import { ToastProvider } from './ToastProvider/ToastProvider.tsx';

function AppProviders() {
  return (
    <AzureAuthProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={AuthenticatingScreen}
        authenticationRequest={authRequest}
      >
        <TanstackQueryProvider>
          <ToastProvider />
          <PrimeReactProvider value={primeReactConfiguration}>
            <FeatureFlagsProvider>
              <RouterProvider router={router} />
            </FeatureFlagsProvider>
          </PrimeReactProvider>
        </TanstackQueryProvider>
      </MsalAuthenticationTemplate>
    </AzureAuthProvider>
  );
}

export default AppProviders;
