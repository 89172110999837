import { ValueOf } from '@shared/types/common.ts';

export const ChartKeys = {
  PROVINCE: 'PROVINCE',
  REGION: 'REGION',
  CONTENT: 'CONTENT',
} as const;

export type ChartKey = ValueOf<typeof ChartKeys>;

export const BarColors = {
  primary: '#a855f7',
  blue500: '#3B82F6',
} as const;

export type BarColor = ValueOf<typeof BarColors>;
