import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '@features/campaigns/details/containers/BillingDetails/BillingDetails.module.scss';
import { Item } from '@shared/components/Review/Item/Item';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { Nullable } from '@shared/types/common';
import { formatNumberAsMoney } from '@shared/utils/moneyFormat';

interface AuthorizedBudgetReadOnlyProps {
  authorizedBudget: Nullable<number>;
}

export default function AuthorizedBudgetReadOnly({ authorizedBudget }: AuthorizedBudgetReadOnlyProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const authorizedBudgetValue = authorizedBudget ? formatNumberAsMoney(authorizedBudget) : '-';

  return (
    <>
      <div className='col-12'>
        <hr className={cn(styles.divider)} />
      </div>
      <div className={'col-12'}>
        <Item label={t('page.billingForm.authorizedBudgetField.label')} value={authorizedBudgetValue} />
      </div>
    </>
  );
}
