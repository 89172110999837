import cs from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import NameWithCode from '@features/campaigns/shared/components/NameWithCode/NameWithCode';
import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { CampaignDetailsData } from '@features/campaigns/shared/containers/Review/Review.dto';
import { ReviewSectionProps } from '@features/campaigns/shared/containers/Review/types';
import { useIsBuilderView } from '@features/campaigns/shared/hooks/useIsBuilderView';
import { Item } from '@shared/components/Review/Item/Item';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { formatNumberAsMoney } from '@shared/utils/moneyFormat.ts';

export function CampaignDetails({ headerText, ctaButtonCallback, data }: ReviewSectionProps<CampaignDetailsData>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const formattedBudget = formatNumberAsMoney(data.budget);
  const formattedStartDate = format(new Date(data.startDate), 'dd/MM/yyyy');
  const formattedEndDate = format(new Date(data.endDate), 'dd/MM/yyyy');
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  const formattedAgencyCommission = data.isAgencyCommissionGross
    ? t('builder.formFields.agencyCommission.gross')
    : t('builder.formFields.agencyCommission.net');
  const isBuilderView = useIsBuilderView();

  return (
    <SectionWrapper headerText={headerText} ctaButtonCallback={ctaButtonCallback}>
      <div className={'grid'} data-testid='campaign-details-section'>
        <div className={'col-6'}>
          <Item
            label={t('builder.formFields.advertiser.label')}
            value={<NameWithCode name={data.advertiserName} code={data.advertiserCode} />}
          />
        </div>
        <div className={'col-6'}>
          <Item
            label={t('builder.formFields.brand.label')}
            value={<NameWithCode name={data.brandName} code={data.brandCode} />}
          />
        </div>
        <div className={'col-12'}>
          <Item label={t('builder.formFields.daterange.submitSectionLabel')} value={formattedDateRange} />
        </div>
        <div className={cs('col-6', { 'no-v-padding': !isBuilderView })}>
          <Item label={t('builder.formFields.budget.label')} value={formattedBudget} />
        </div>
        <div className={cs('col-6', { 'no-v-padding': !isBuilderView })}>
          <Item label={t('builder.formFields.agencyCommission.label')} value={formattedAgencyCommission} />
        </div>
        {isBuilderView && (
          <div className={'col-12 no-v-padding'}>
            <Item label={t('builder.formFields.campaignName.label')} value={data.campaignName} />
          </div>
        )}
      </div>
    </SectionWrapper>
  );
}
