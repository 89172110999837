import { useTranslation } from 'react-i18next';

import { TopChartsData } from '@features/campaigns/performance/components/TopCharts/TopCharts.types.ts';
import { ChartNoData } from '@features/campaigns/performance/shared/components/ChartNoData/ChartNoData.tsx';
import { BarColors, ChartKeys } from '@features/campaigns/performance/shared/components/HorizontalBarChart/consts';
import HorizontalBarChart from '@features/campaigns/performance/shared/components/HorizontalBarChart/HorizontalBarChart';
import styles from '@features/campaigns/performance/shared/styles/TopSectionsCommonStyles.module.scss';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface TopContentProps {
  isFetching: boolean;
  data: TopChartsData['genres'] | undefined;
}

function TopContent({ isFetching, data }: TopContentProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const isNoData = data === undefined || data.labels.length === 0;

  return (
    <section data-testid='top-content-section'>
      <h3 className={styles.title} data-testid='top-content-section-title'>
        {t('page.campaignDetails.performanceTab.subtitles.topContent')}
      </h3>
      <div className={styles.container}>
        {isFetching && <Spinner variant='elementOverlay' />}
        {isNoData ? (
          <ChartNoData />
        ) : (
          <HorizontalBarChart
            data={data}
            barColor={BarColors.blue500}
            dataTestId='top-content'
            chartKey={ChartKeys.CONTENT}
          />
        )}
      </div>
    </section>
  );
}

export default TopContent;
