import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';
import { mockedCampaignDetails } from '@shared/mocks/requests/campaignDetails/campaignDetails.fixtures';
import { mockedPricing } from '@shared/mocks/requests/pricing/pricing.fixtures';

export const pricingRequests = [
  http.get(`*${apiRoutes.readPricing(':id')}`, async ({ request }) => {
    const url = new URL(request.url);
    const cpm = url.searchParams.get('cpm');
    const budget = url.searchParams.get('budget');
    const impressions = url.searchParams.get('impressions');

    if (cpm) {
      mockedPricing.cpm = Number(cpm);
    }

    if (budget) {
      mockedPricing.budget = Number(budget);
    }

    if (impressions) {
      mockedPricing.impressions = Number(impressions);
    }

    return HttpResponse.json(mockedPricing);
  }),
  http.put(`*${apiRoutes.updatePricing(':id')}`, async () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
];
