import { ExternalUser } from '@features/users/list/types/externalUser.ts';
import { MsGraphApiResponse } from '@shared/api/commonApiInterfaces.ts';

export const mockedExternalUsersList: MsGraphApiResponse<ExternalUser[]> = {
  items: [
    {
      givenName: 'Ann',
      id: 'b25c5113-f236-4b32-b719-7a25685b84b0',
      mail: 'ann.yellow@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'Yellow',
    },
    {
      givenName: 'Giovanni',
      id: '0c82ed85-b358-4602-92dc-c9b3b32f150f',
      mail: 'giovanni.paolo@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'Paolo',
    },
    {
      givenName: 'Jerry',
      id: 'b06d8abc-5f99-4314-b298-cda9f4d58c37',
      mail: 'jerry.springer@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'Springer',
    },
    {
      givenName: 'John',
      id: 'd06d8abc-5f99-4314-c598-cda9f4d58c11',
      mail: 'john.matrix@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'Matrix',
    },
  ],
};
