import { CampaignId } from '@shared/types/campaign.ts';

export const apiRoutes = {
  downloadPptxDetailsPresentation: (campaignId: CampaignId) => `/campaigns/${campaignId}/details-presentation`,
  approveCampaign: (campaignId: CampaignId) => `/campaigns/${campaignId}/approve`,
  approveCampaignBudget: (campaignId: CampaignId) => `/campaigns/${campaignId}/approve-budget`,
  getAgencies: () => `/advertisers/agencies`,
  getBillingContacts: (advertiserId: string) => `/advertisers/${advertiserId}/billing-contacts`,
  updateBillingDetails: (campaignId: CampaignId) => `/campaigns/${campaignId}/billing-details`,
  updateAuthorizedBudget: (campaignId: CampaignId) => `/campaigns/${campaignId}/authorized-budget`,
};
