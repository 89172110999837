import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './AuthenticatingScreen.module.scss';

function AuthenticatingScreen() {
  const { t } = useTranslation(i18nNameSpace.COMMON);

  return (
    <div className={styles.wrapper}>
      <ProgressSpinner />
      <p className={styles.paragraph}>{t('authenticationInProgress')}</p>
    </div>
  );
}

export default AuthenticatingScreen;
