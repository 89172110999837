import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ActionsPanel from '@features/campaigns/list/components/Table/components/ActionsPanel/ActionsPanel';
import ActionsColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/ActionsColumnBodyTemplate/ActionsColumnBodyTemplate';
import BudgetColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/BudgetColumnBodyTemplate/BudgetColumnBodyTemplate';
import EndDateColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/EndDateColumnBodyTemplate/EndDateColumnBodyTemplate';
import FormatBodyTemplate from '@features/campaigns/list/components/Table/components/columns/FormatColumnBodyTemplate/FormatBodyTemplate';
import MediaTypeColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/MediaTypeColumnBodyTemplate/MediaTypeColumnBodyTemplate';
import NameColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/NameColumnBodyTemplate/NameColumnBodyTemplate';
import StartDateColumnBodyTemplate from '@features/campaigns/list/components/Table/components/columns/StartDateColumnBodyTemplate/StartDateColumnBodyTemplate';
import StatusBodyTemplate from '@features/campaigns/list/components/Table/components/columns/StatusColumnBodyTemplate/StatusBodyTemplate';
import { fetchCampaigns } from '@features/campaigns/list/services/listCampaigns';
import { Actions, ReducerProps } from '@features/campaigns/list/types/campaignListReducer';
import { removeCampaignsCountFromState } from '@features/campaigns/list/utils/removeCampaignsCountFromState';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '@shared/components/Table/consts/table';
import commonStyles from '@shared/components/Table/Table.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n';
import { DataTableState } from '@shared/types/table';
import { onPage, onSort } from '@shared/utils/eventHandlers';
import { Campaign } from '@shared/utils/interfaces/campaign';

import styles from './CampaignsListTable.module.scss';
import { EmptyState } from './components/EmptyState/EmptyState';

function CampaignsListTable({ state, dispatch }: ReducerProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const actionsPanel = useRef(null);
  const [clickedCampaign, setClickedCampaign] = useState<Campaign>();
  const [searchParams] = useSearchParams();

  const requestState = removeCampaignsCountFromState(state);

  const { isLoading, data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGNS_LIST, requestState, dispatch],
    queryFn: () => fetchCampaigns({ dataTableState: requestState, dispatch }),
    placeholderData: keepPreviousData,
  });

  const handleSetBasicDataTableState = (value: DataTableState) => {
    dispatch({ type: Actions.CHANGE_BASIC_TABLE_DATA, payload: value });
  };

  const dataIsLoading = isLoading || isFetching;

  if (dataIsLoading) {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  }

  if (!data || data?.items?.length === 0) {
    const isFiltering = searchParams.size > 0;
    return <EmptyState isFiltering={isFiltering} />;
  }

  return (
    <>
      <DataTable
        data-testid='campaign-list-table'
        className={commonStyles.wrapper}
        value={data?.items || []}
        first={state.first}
        rows={state.limit}
        totalRecords={state.campaignsCount}
        lazy
        dataKey='id'
        paginator
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown items per page'
        currentPageReportTemplate={t('page.list.paginator.summary')}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        onPage={onPage(state, handleSetBasicDataTableState)}
        onSort={onSort(state, handleSetBasicDataTableState)}
        sortField={state.sortField}
        sortOrder={state.sortOrder}
        removableSort
        loading={dataIsLoading}
      >
        <Column
          className={styles.nameColumn}
          field='name'
          header={t('page.list.columns.name')}
          sortable
          body={(campaign: Campaign) => <NameColumnBodyTemplate campaign={campaign} />}
        />
        <Column
          className={styles.mediaTypeColumn}
          field='mediaType'
          header={t('page.list.columns.mediaType')}
          sortable
          body={MediaTypeColumnBodyTemplate}
        />
        <Column
          className={styles.formatColumn}
          field='format'
          header={t('page.list.columns.format')}
          body={FormatBodyTemplate}
        />
        <Column
          className={styles.startDateColumn}
          field='startDate'
          header={t('page.list.columns.startDate')}
          sortable
          body={StartDateColumnBodyTemplate}
        />
        <Column
          className={styles.endDateColumn}
          field='endDate'
          header={t('page.list.columns.endDate')}
          sortable
          body={EndDateColumnBodyTemplate}
        />
        <Column
          className={styles.budgetColumn}
          field='budget'
          header={t('page.list.columns.budget')}
          sortable
          body={BudgetColumnBodyTemplate}
        />
        <Column
          className={styles.statusColumn}
          field='status'
          header={t('page.list.columns.status')}
          body={StatusBodyTemplate}
        />
        <Column
          className={styles.actionsColumn}
          field='actions'
          body={(campaign: Campaign) => {
            if (campaign.accessRights.length === 0) {
              return null;
            }

            return (
              <ActionsColumnBodyTemplate
                campaign={campaign}
                referenceToActionsPanel={actionsPanel}
                setClickedCampaign={setClickedCampaign}
              />
            );
          }}
        />
      </DataTable>
      <ActionsPanel campaign={clickedCampaign} referenceToActionsPanel={actionsPanel} />
    </>
  );
}

export default CampaignsListTable;
