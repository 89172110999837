import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ApproveCampaignBudgetModal } from '@features/campaigns/details/components/Modals/ApproveCampaignBudgetModal/ApproveCampaignBudgetModal';
import { ApproveCampaignModal } from '@features/campaigns/details/components/Modals/ApproveCampaignModal/ApproveCampaignModal';
import { approveCampaign } from '@features/campaigns/details/services/approveCampaign';
import { approveCampaignBudget } from '@features/campaigns/details/services/approveCampaignBudget';
import { DeleteCampaignModal } from '@features/campaigns/shared/components/Modals/DeleteCampaignModal/DeleteCampaignModal';
import { SubmitCampaignModal } from '@features/campaigns/shared/components/Modals/SubmitCampaignModal/SubmitCampaignModal';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import useDeleteCampaign from '@features/campaigns/shared/hooks/useDeleteCampaign.tsx';
import useSubmitCampaign from '@features/campaigns/shared/hooks/useSubmitCampaign';
import { AccessRight, AccessRights } from '@shared/api/commonApiInterfaces';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage';
import { i18nNameSpace } from '@shared/consts/i18n';
import { routePaths } from '@shared/router/routePaths';

import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
  shouldSubmitButtonBeDisabled: boolean;
  campaignName: string;
  campaignAccessRights: AccessRight[];
}

function ActionButtons({ shouldSubmitButtonBeDisabled, campaignName, campaignAccessRights }: ActionButtonsProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { campaignId } = useParams();
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isApproveBudgetModalVisible, setIsApproveBudgetModalVisible] = useState(false);
  const showEditButton =
    campaignAccessRights.includes(AccessRights.EDIT_ALL) ||
    campaignAccessRights.includes(AccessRights.EDIT_BUDGET) ||
    campaignAccessRights.includes(AccessRights.EDIT_END_DATE) ||
    campaignAccessRights.includes(AccessRights.EDIT_TARGETING) ||
    campaignAccessRights.includes(AccessRights.EDIT_BRAND);

  const handleToggleSubmitModalVisibility = () => setSubmitModalVisible(!isSubmitModalVisible);
  const handleToggleDeleteModalVisibility = () => setDeleteModalVisible(!isDeleteModalVisible);
  const handleToggleApproveModalVisibility = () => setIsApproveModalVisible(!isApproveModalVisible);
  const handleToggleApproveBudgetModalVisibility = () => setIsApproveBudgetModalVisible(!isApproveBudgetModalVisible);

  const { deleteCampaignMutation, deleteCampaignIsPending } = useDeleteCampaign({
    handleToggleDeleteModalVisibility,
    redirectToUrl: routePaths.campaigns,
  });

  const { mutate: submitCampaignMutation, isPending: submitCampaignIsPending } = useSubmitCampaign({
    campaignId: campaignId!,
    campaignName,
    handleToggleModalVisibility: handleToggleSubmitModalVisibility,
  });

  const { mutate: approveCampaignMutation, isPending: approveCampaignIsPending } = useMutation({
    mutationFn: () => approveCampaign(campaignId!),
    onSuccess: async () => {
      toast.success(
        <SuccessMessage message={t('api.success.campaign.approved', { ns: i18nNameSpace.API, campaignName })} />,
      );
      handleToggleApproveModalVisibility();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGN_DETAILS] });
    },
    onError: () => {
      handleToggleApproveModalVisibility();
    },
  });

  const { mutate: approveCampaignBudgetMutation, isPending: approveCampaignBudgetIsPending } = useMutation({
    mutationFn: () => approveCampaignBudget(campaignId!),
    onSuccess: async () => {
      toast.success(
        <SuccessMessage message={t('api.success.campaign.budgetApproved', { ns: i18nNameSpace.API, campaignName })} />,
      );
      handleToggleApproveBudgetModalVisibility();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGN_DETAILS] });
    },
    onError: () => {
      handleToggleApproveBudgetModalVisibility();
    },
  });

  const redirectToEditForm = () => {
    navigate(routePaths.campaignBuilderStep01.url(campaignId), { state: routePaths.campaignDetails.url(campaignId!) });
  };

  const continueSubmittingCampaignHandler = () => {
    submitCampaignMutation();
  };

  const handleApproveConfirmation = () => {
    approveCampaignMutation();
  };

  const handleApproveBudgetConfirmation = () => {
    approveCampaignBudgetMutation();
  };

  const handleDeleteConfirmation = () => {
    if (campaignId) {
      deleteCampaignMutation(campaignId);
    }
  };

  return (
    <>
      <div className={styles.actionButtonsWrapper}>
        {campaignAccessRights.includes(AccessRights.DELETE) && (
          <Button
            className={'p-button p-button-outlined'}
            data-testid='delete-campaign-button'
            onClick={handleToggleDeleteModalVisibility}
          >
            <i className={cn('pi pi-trash', styles.buttonIcon)}></i>
            <span>{t('page.campaignDetails.actions.delete')}</span>
          </Button>
        )}
        {showEditButton && (
          <Button
            className={'p-button p-button-outlined'}
            data-testid='edit-campaign-button'
            onClick={redirectToEditForm}
          >
            <i className={cn('pi pi-pencil', styles.buttonIcon)}></i>
            <span>{t('page.campaignDetails.actions.edit')}</span>
          </Button>
        )}
        {campaignAccessRights.includes(AccessRights.SUBMIT) && (
          <Button
            className={'p-button'}
            data-testid='submit-campaign-button'
            disabled={shouldSubmitButtonBeDisabled}
            loading={submitCampaignIsPending}
            onClick={handleToggleSubmitModalVisibility}
          >
            {t('page.campaignDetails.actions.submit')}
          </Button>
        )}
        {campaignAccessRights.includes(AccessRights.APPROVE) && (
          <Button
            onClick={handleToggleApproveModalVisibility}
            className={'p-button'}
            data-testid='approve-campaign-button'
          >
            {t('page.campaignDetails.actions.approve')}
          </Button>
        )}
        {campaignAccessRights.includes(AccessRights.APPROVE_BUDGET) && (
          <Button
            onClick={handleToggleApproveBudgetModalVisibility}
            className={'p-button'}
            data-testid='approve-budget-button'
          >
            {t('page.campaignDetails.actions.approveBudget')}
          </Button>
        )}
      </div>
      <SubmitCampaignModal
        isVisible={isSubmitModalVisible}
        toggleModalVisibility={handleToggleSubmitModalVisibility}
        submitCallback={continueSubmittingCampaignHandler}
        campaignName={campaignName}
        submitCallbackActionIsLoading={submitCampaignIsPending}
      />
      <ApproveCampaignModal
        isVisible={isApproveModalVisible}
        toggleModalVisibility={handleToggleApproveModalVisibility}
        approveCallback={handleApproveConfirmation}
        campaignName={campaignName}
        submitCallbackActionIsLoading={approveCampaignIsPending}
      />
      <ApproveCampaignBudgetModal
        isVisible={isApproveBudgetModalVisible}
        toggleModalVisibility={handleToggleApproveBudgetModalVisibility}
        approveCallback={handleApproveBudgetConfirmation}
        campaignName={campaignName}
        submitCallbackActionIsLoading={approveCampaignBudgetIsPending}
      />
      <DeleteCampaignModal
        isVisible={isDeleteModalVisible}
        toggleModalVisibility={handleToggleDeleteModalVisibility}
        deleteCallback={handleDeleteConfirmation}
        campaignName={campaignName}
        deleteCallbackActionIsLoading={deleteCampaignIsPending}
      />
    </>
  );
}

export default ActionButtons;
