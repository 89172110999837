import flagItaly from '@shared/assets/icons/flag-italy.svg';
import flagUnitedKingdom from '@shared/assets/icons/flag-unitedKingdom.svg';

export interface Language {
  name: string;
  code: string;
  locale: string;
  flagIcon: string;
}

const languages = [
  { name: `Italian`, code: 'IT', locale: 'it', flagIcon: flagItaly },
  { name: `English`, code: 'EN', locale: 'en', flagIcon: flagUnitedKingdom },
];

export { languages };
