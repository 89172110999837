import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';

export const creativesRequests = [
  http.delete(`*${apiRoutes.creativeLShapeImage(':id')}`, () => {
    return HttpResponse.json();
  }),
  http.delete(`*${apiRoutes.creativeLandingImage(':id')}`, () => {
    return HttpResponse.json();
  }),
];
