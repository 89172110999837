import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';
import {
  mockedAgeAndGenderData,
  mockedContentPackagesData,
  mockedInterestSegmentsData,
  mockedProvincesData,
} from '@shared/mocks/requests/targeting/targeting.fixtures';

export const targetingRequests = [
  http.get(`*${apiRoutes.getInterestSegments()}`, () => {
    return HttpResponse.json(mockedInterestSegmentsData);
  }),
  http.get(`*${apiRoutes.getContentPackages()}`, () => {
    return HttpResponse.json(mockedContentPackagesData);
  }),
  http.get(`*${apiRoutes.getProvinces()}`, () => {
    return HttpResponse.json(mockedProvincesData);
  }),
  http.get(`*${apiRoutes.getAgeAndGenderGroups()}`, () => {
    return HttpResponse.json(mockedAgeAndGenderData);
  }),
];
