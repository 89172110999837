import { PropsWithChildren } from 'react';

import styles from './PageHeader.module.scss';

type PageHeaderProps = PropsWithChildren;

function PageHeader({ children }: PageHeaderProps) {
  return (
    <section className={styles.wrapper}>
      <header className={styles.headerContentWrapper}>{children}</header>
    </section>
  );
}

export default PageHeader;
