import { PropsWithChildren, useState } from 'react';

import {
  ContextValue,
  LeaveGuardModalContext,
  ModalState,
} from '@shared/providers/LeaveGuardModalProvider/providerSetup.ts';

export function LeaveGuardModalProvider({ children }: PropsWithChildren) {
  const [modalState, setModalState] = useState<ModalState>({ isOpen: false, isLoading: false, isFormDirty: false });

  const toggleModal = () => setModalState((state) => ({ ...state, isOpen: !state.isOpen }));
  const setIsFormDirty = (isDirty: boolean) => setModalState((state) => ({ ...state, isFormDirty: isDirty }));
  const setIsLoading = (isLoading: boolean) => setModalState((state) => ({ ...state, isLoading }));

  const value: ContextValue = {
    isOpen: modalState.isOpen,
    isLoading: modalState.isLoading,
    isFormDirty: modalState.isFormDirty,
    toggleModal,
    setIsFormDirty,
    setIsLoading,
  };

  return <LeaveGuardModalContext.Provider value={value}>{children}</LeaveGuardModalContext.Provider>;
}
