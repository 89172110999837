import PageHeader from '@features/campaigns/details/components/PageHeader/PageHeader';
import CampaignDetailsPage from '@features/campaigns/details/containers/CampaignDetailsPage/CampaignDetailsPage';
import { LeaveGuardModalProvider } from '@shared/providers/LeaveGuardModalProvider/LeaveGuardModalProvider.tsx';

function CampaignDetailsPageLayout() {
  return (
    <LeaveGuardModalProvider>
      <PageHeader />
      <section className='content-wrapper'>
        <CampaignDetailsPage />
      </section>
    </LeaveGuardModalProvider>
  );
}

export default CampaignDetailsPageLayout;
