import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { ReviewSectionProps } from '@features/campaigns/shared/containers/Review/types';

import styles from './Note.module.scss';

export function Note({ headerText, ctaButtonCallback, data }: ReviewSectionProps<string>) {
  return (
    <SectionWrapper headerText={headerText} ctaButtonCallback={ctaButtonCallback}>
      <p className={styles.text} data-testid='additional-note-section'>
        {data || '-'}
      </p>
    </SectionWrapper>
  );
}
