import { InputText } from 'primereact/inputtext';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldErrorMessage } from '@features/campaigns/builder/components/FormFields/common/FieldErrorMessage/FieldErrorMessage';
import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes.ts';
import styles from '@features/campaigns/shared/components/FormFields/FormFields.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

export function FieldPurchaseOrder() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { control } = useFormContext();

  return (
    <div className={styles.fieldWrapper}>
      <label htmlFor={BillingFormFieldTypes.PURCHASE_ORDER} className={styles.fieldLabel}>
        {t('page.billingForm.orderField.label')}
      </label>
      <Controller
        control={control}
        name={BillingFormFieldTypes.PURCHASE_ORDER}
        render={({ field, formState: { errors } }) => {
          return (
            <>
              <InputText
                {...field}
                id={BillingFormFieldTypes.PURCHASE_ORDER}
                data-testid={'field-purchase-order-input'}
              />
              {errors?.[BillingFormFieldTypes.PURCHASE_ORDER] && (
                <FieldErrorMessage fieldErrorData={errors} fieldName={BillingFormFieldTypes.PURCHASE_ORDER} />
              )}
            </>
          );
        }}
      />
    </div>
  );
}
