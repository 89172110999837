export const mockedInterestSegmentsData = {
  items: [
    {
      id: 'b73a3d24-1747-4cee-a84e-5dc84b93214b',
      name: 'Arredamento e Design',
      status: 'ACTIVE',
    },
    {
      id: 'ea388a96-c2c1-4ee0-8b66-395da17a1fa9',
      name: 'Arte e cultura',
      status: 'ACTIVE',
    },
    {
      id: 'f9376faa-fe91-46fe-b495-7878f74ea89e',
      name: 'Automotive',
      status: 'ACTIVE',
    },
    {
      id: '15d734c6-efa0-4ecc-96d6-bd836e317c7d',
      name: 'Beauty e Cosmesi',
      status: 'ACTIVE',
    },
    {
      id: 'f93e6ab2-0e80-4d4d-8280-9691a62e63ac',
      name: 'Calcio e Sport',
      status: 'ACTIVE',
    },
    {
      id: '7adb33f1-55b2-4d96-9b9d-8f7f4aec0d81',
      name: 'Cinema',
      status: 'ACTIVE',
    },
    {
      id: '146d1a09-a238-4250-a9d5-0038e6e250c6',
      name: 'Cucina',
      status: 'ACTIVE',
    },
    {
      id: 'c3b95247-6d0d-4a46-b741-d1375c0bdf08',
      name: 'Ecommerce',
      status: 'ACTIVE',
    },
    {
      id: 'abcd06da-f669-48af-a0dd-d090d9a02596',
      name: 'Entertainment',
      status: 'ACTIVE',
    },
    {
      id: '148afb13-4d2a-4fcb-ad31-4516a82508d9',
      name: 'Fashion e Luxury',
      status: 'ACTIVE',
    },
    {
      id: '8b38b9fe-64cc-45f6-ba0b-ea0ef64452d6',
      name: 'Finance e Insurance',
      status: 'ACTIVE',
    },
    {
      id: '37c644cf-a7cf-45e4-9883-c77a75d7fbc1',
      name: 'Gamers',
      status: 'ACTIVE',
    },
    {
      id: 'cc9f4470-2be7-47f7-8502-14f6fba353f4',
      name: 'GDO Shoppers',
      status: 'ACTIVE',
    },
    {
      id: '24afa1b2-54ec-414a-aaa2-4aea4bd66227',
      name: 'Green',
      status: 'ACTIVE',
    },
    {
      id: 'b70b71c2-2d32-4cf3-ba34-f00cab3f7b13',
      name: 'Infosum',
      status: 'ACTIVE',
    },
    {
      id: 'e840c60e-5465-4d8a-a435-fe1664c9e23c',
      name: 'Infotainment',
      status: 'ACTIVE',
    },
    {
      id: '9f09beb4-547e-4862-9e1b-6040d9241472',
      name: 'Light Tv viewer',
      status: 'ACTIVE',
    },
    {
      id: '5ca2ce6b-2579-4a38-9dd0-62eb386de548',
      name: 'Luxury',
      status: 'ACTIVE',
    },
    {
      id: 'a410bb4a-5a87-4e14-ac16-97f57835443e',
      name: 'Meteo',
      status: 'ACTIVE',
    },
    {
      id: '6be9995d-79a1-4c2d-bd59-a4de8f3bf171',
      name: 'Moda e Accessori',
      status: 'ACTIVE',
    },
    {
      id: '5fe6462b-8abd-4ef0-b7a1-cc408f9afb46',
      name: 'OTT Streamers',
      status: 'ACTIVE',
    },
    {
      id: 'cce31949-0635-427b-9800-2df772a8c7ea',
      name: 'Parenting e Kids',
      status: 'ACTIVE',
    },
    {
      id: '08fb0a84-e5ad-4f04-9cc0-89628ec46060',
      name: 'Pet Lovers',
      status: 'ACTIVE',
    },
    {
      id: 'be444049-9d56-4bc4-bb81-7a54a562f0d0',
      name: 'Prodotti Bio',
      status: 'ACTIVE',
    },
    {
      id: '34e7be6e-e0d8-4a39-b6cf-6cc9762b8447',
      name: 'Retargeting',
      status: 'ACTIVE',
    },
    {
      id: '3179956a-0e14-4761-a640-dc01b0dde12e',
      name: 'Ristorazione',
      status: 'ACTIVE',
    },
    {
      id: '7361ec36-ffb9-413e-b220-31b1264dcf62',
      name: 'Salute e Cura Persona',
      status: 'ACTIVE',
    },
    {
      id: '31d00a24-0e86-438c-81c6-bcb0b9b76429',
      name: 'Talent e Reality',
      status: 'ACTIVE',
    },
    {
      id: '6defed07-cb55-405f-875a-985d2d204462',
      name: 'Tech',
      status: 'ACTIVE',
    },
    {
      id: 'c7cc7324-c42e-4424-a054-b7ce75081968',
      name: 'Tempo Libero',
      status: 'ACTIVE',
    },
    {
      id: '065bd9ef-1589-4507-88e2-85fa5201504a',
      name: 'Turismo',
      status: 'ACTIVE',
    },
    {
      id: 'b56709bc-27c3-4982-aa36-bf13656571f7',
      name: 'Tv Series e Fiction',
      status: 'ACTIVE',
    },
  ],
  meta: {
    totalItems: 32,
    itemCount: 32,
    itemsPerPage: 10000,
    totalPages: 1,
    currentPage: 1,
  },
};

export const mockedContentPackagesData = {
  items: [
    {
      id: '028fafe6-c469-4077-9c49-8f474337d506',
      name: 'CROSS - 60 SECONDI',
      status: 'ACTIVE',
    },
    {
      id: '516d3584-5a9e-4a0d-83d9-f6c6a9aaecba',
      name: 'CROSS - INFINITY',
      status: 'ACTIVE',
    },
    {
      id: 'fa2a2a4c-6497-4e77-be52-3af463e4ed67',
      name: 'CROSS - INFINITY INFOSUM',
      status: 'ACTIVE',
    },
    {
      id: 'ba299bed-7a40-4ac9-a10d-a2c0a0cf348d',
      name: 'CROSS - KIDS',
      status: 'ACTIVE',
    },
    {
      id: 'a103abcd-81bc-4a66-b402-b8481e3d8a62',
      name: 'CROSS - NEWS',
      status: 'ACTIVE',
    },
    {
      id: 'e3bf8218-0ca2-4273-8b1a-91c96aca92a8',
      name: 'CROSS - SPORT',
      status: 'ACTIVE',
    },
    {
      id: '8dc8ec97-29b4-49bf-b509-b0fe699b8743',
      name: 'CROSS - TV SERIES',
      status: 'ACTIVE',
    },
    {
      id: 'c11f65d1-b092-4193-96b4-4f71af7bd75b',
      name: 'CROSS - WOMEN',
      status: 'ACTIVE',
    },
    {
      id: '4d5abb57-11b4-4244-8f38-a0f4b153ab47',
      name: 'CTV - DISPLAY TV SERIES',
      status: 'ACTIVE',
    },
    {
      id: '8ada5767-357f-44e6-bbe1-1c9d7eb3ff18',
      name: 'CTV - TV SERIES',
      status: 'ACTIVE',
    },
  ],
  meta: {
    totalItems: 10,
    itemCount: 10,
    itemsPerPage: 10000,
    totalPages: 1,
    currentPage: 1,
  },
};

export const mockedProvincesData = {
  items: [
    {
      id: 'c1a7479d-6d9c-4bcf-ba7b-10c0ec70c70d',
      name: 'Abruzzo',
      provinces: [
        {
          id: '2343b9d6-17d4-4486-82ff-8cb2ffa7e7e5',
          name: 'Chieti',
        },
        {
          id: '6790caf8-6770-4838-9d07-070d34b9c1ef',
          name: "L'Aquila",
        },
        {
          id: '5daa09ff-6403-43ae-9a83-b9ce2d87d9c6',
          name: 'Pescara',
        },
        {
          id: 'b5282516-b3dc-4d4a-ae8d-5635c2f33765',
          name: 'Teramo',
        },
      ],
    },
    {
      id: 'd5148000-3bd3-4976-b3b2-98f07684dab2',
      name: 'Basilicata',
      provinces: [
        {
          id: 'c8a2e9ae-5f10-48a2-b1bb-458ec6537f67',
          name: 'Matera',
        },
        {
          id: 'a6dabe7e-6560-4446-9676-ee90a06fa64b',
          name: 'Potenza',
        },
      ],
    },
    {
      id: '938fc90b-75b9-41de-ae4f-523174e2929e',
      name: 'Calabria',
      provinces: [
        {
          id: '6d478db5-ccb6-48c9-a023-8651173bf0b4',
          name: 'Catanzaro',
        },
        {
          id: 'ff043aa9-b618-4b93-ac70-46643f093b6f',
          name: 'Cosenza',
        },
        {
          id: '767f1d4a-8a21-4006-8cb1-133a90162fd3',
          name: 'Crotone',
        },
        {
          id: 'e3e1717e-393e-47ef-9364-e0410ba1cb47',
          name: 'Reggio Calabria',
        },
        {
          id: 'bfd24830-44f2-48c9-8cbb-1e4ef0b2861f',
          name: 'Vibo Valentia',
        },
      ],
    },
    {
      id: '290488c0-be3b-44aa-99c8-dcb4a5ccc1f1',
      name: 'Campania',
      provinces: [
        {
          id: '91bbca38-78c2-4ecd-b9de-3379e9466a92',
          name: 'Avellino',
        },
        {
          id: 'f2037670-4fac-4ff6-8c68-3125543e50bb',
          name: 'Benevento',
        },
        {
          id: 'e9c10b41-3dfe-41a7-9d27-ebcc8d6ce26f',
          name: 'Caserta',
        },
        {
          id: '47a9ed3a-c8d3-430f-8bd3-a802923151db',
          name: 'Napoli',
        },
        {
          id: '97aae96b-6920-4aec-97d4-df639f241013',
          name: 'Salerno',
        },
      ],
    },
    {
      id: '2ff4deb3-e77c-47b2-ac97-e19e817b990e',
      name: 'Emilia-Romagna',
      provinces: [
        {
          id: 'be73e25c-7f52-47e3-a4b5-6f96f26a3ffd',
          name: 'Bologna',
        },
        {
          id: '0c2e976a-d488-4584-b41d-800dc71df04c',
          name: 'Ferrara',
        },
        {
          id: 'a1f415c0-0701-4749-a475-8b4e2f478c4c',
          name: 'Forlì-Cesena',
        },
        {
          id: '53026ca3-81c0-45fa-a207-d1635d31b11c',
          name: 'Modena',
        },
        {
          id: '0d541026-0eff-4e59-ba1d-40800f4ec020',
          name: 'Parma',
        },
        {
          id: 'd506a4d3-d2dc-4db0-9a86-bbb01b998081',
          name: 'Piacenza',
        },
        {
          id: '95a5a0ba-f35e-466b-8c45-e5d223dde962',
          name: 'Ravenna',
        },
        {
          id: '9d56d6d3-b581-49d6-95ba-cd15bb2f0a46',
          name: 'Reggio Emilia',
        },
        {
          id: '4bf1cea1-97c7-4646-a718-f3be85688498',
          name: 'Rimini',
        },
      ],
    },
    {
      id: '37c3e70a-b21c-40ba-849e-223244a52fec',
      name: 'Friuli-Venezia Giulia',
      provinces: [
        {
          id: '7fe7f1d0-4e26-4ba5-8d26-3896f88be9b2',
          name: 'Gorizia',
        },
        {
          id: 'c5b7417c-267a-4653-888c-952b4cd228ef',
          name: 'Pordenone',
        },
        {
          id: 'cea83320-dc61-4fac-9e4f-24c8f8c07e1e',
          name: 'Trieste',
        },
        {
          id: '1749b7eb-7a16-4dbe-a7c4-3e84d43a002d',
          name: 'Udine',
        },
      ],
    },
    {
      id: '8e6f6e5b-fbff-4479-82be-bdd48804729f',
      name: 'Lazio',
      provinces: [
        {
          id: '9e530b67-3681-4a02-8b5f-80e08203b14f',
          name: 'Frosinone',
        },
        {
          id: '6136e53a-d9e9-4324-ae69-93157400ce7c',
          name: 'Latina',
        },
        {
          id: '9655e46e-dc04-446e-9f77-3f46e2b1eedf',
          name: 'Rieti',
        },
        {
          id: '56f46311-331a-4d14-8e2e-fca86d561864',
          name: 'Roma',
        },
        {
          id: '03810148-edc9-4aec-8a10-3a2660736b96',
          name: 'Viterbo',
        },
      ],
    },
    {
      id: '776f9587-5a8a-4827-a312-ef58d6ef40c3',
      name: 'Liguria',
      provinces: [
        {
          id: '61bbfe07-72c3-4e25-8784-c35b00968fda',
          name: 'Genova',
        },
        {
          id: '3484f781-effe-4571-ba1f-032b69ce0540',
          name: 'Imperia',
        },
        {
          id: '9ca929bc-6742-4d92-b4b7-72a9330972d7',
          name: 'La Spezia',
        },
        {
          id: '15bfc969-2ce9-4f01-ac2a-cc4148f2f22b',
          name: 'Savona',
        },
      ],
    },
    {
      id: 'afc04529-f162-4ef5-86e2-d90151942102',
      name: 'Lombardia',
      provinces: [
        {
          id: '110cd404-ccf4-4a40-bb08-5beb43725a3d',
          name: 'Bergamo',
        },
        {
          id: '064a5013-37b2-46aa-859e-cbd0cf34b22d',
          name: 'Brescia',
        },
        {
          id: 'bf0eee1d-6d1e-4472-9726-69be211c9c56',
          name: 'Como',
        },
        {
          id: 'b2583843-1c3d-4de0-87c2-06dbbd5b69a7',
          name: 'Cremona',
        },
        {
          id: '891acac8-3521-4fc9-99fa-59bc96fa22b1',
          name: 'Lecco',
        },
        {
          id: '9c8f7021-aaa9-4de1-96fb-a023595be70a',
          name: 'Lodi',
        },
        {
          id: 'baa4ac7d-9d2c-4868-ae4a-4f4afb636892',
          name: 'Mantova',
        },
        {
          id: 'e168e4df-7061-4a6d-abc0-9ac2c63ffb6f',
          name: 'Milano',
        },
        {
          id: 'c5f7402c-4271-48f2-84ba-c084c4420841',
          name: 'Monza-Brianza',
        },
        {
          id: 'ca86ffa2-1724-463f-a4d0-5fc3076e292e',
          name: 'Pavia',
        },
        {
          id: 'd31b0969-c2e0-4ba3-b8c8-a02a9a4a1307',
          name: 'Sondrio',
        },
        {
          id: '020bf8a0-7cc0-4059-bea0-f71a20931940',
          name: 'Varese',
        },
      ],
    },
    {
      id: 'd290d34a-936b-4bcd-899d-c81cdd9cc90d',
      name: 'Marche',
      provinces: [
        {
          id: 'f0e0bc02-3736-4199-bd5f-bc394867edc6',
          name: 'Ancona',
        },
        {
          id: 'ff98fb9c-1c5a-42b7-80ae-019673c16f3f',
          name: 'Ascoli Piceno',
        },
        {
          id: 'a8ad2614-44f5-422c-be23-c0f79d29c7fc',
          name: 'Fermo',
        },
        {
          id: 'b155eb29-8cd1-4f2a-b922-b4a8e2495d09',
          name: 'Macerata',
        },
        {
          id: '728720b8-a2fd-4155-a3b4-fb2341eb28fc',
          name: 'Pesaro-Urbino',
        },
      ],
    },
    {
      id: 'ea944beb-4522-4454-b901-e37aa1386d03',
      name: 'Molise',
      provinces: [
        {
          id: '41bbbd8f-c07f-4db3-8666-08f0b0f0fc2b',
          name: 'Campobasso',
        },
        {
          id: '4370695a-04ab-4d83-ab6a-8032ebd5fbbe',
          name: 'Isernia',
        },
      ],
    },
    {
      id: '3595db68-8405-46fc-a666-8c4b172d7878',
      name: 'Piemonte',
      provinces: [
        {
          id: '01574f74-9e0b-4832-b64d-12df5fa645eb',
          name: 'Alessandria',
        },
        {
          id: 'b3734431-71d1-4ee4-a9f3-4e256fdd444d',
          name: 'Asti',
        },
        {
          id: '4dcf1ca0-5089-4780-bf40-9494c780cb54',
          name: 'Biella',
        },
        {
          id: 'f63e7cb3-8830-48a2-a900-924b44e8abbc',
          name: 'Cuneo',
        },
        {
          id: 'ee22e9a8-2354-40f2-9619-dc5321419a1e',
          name: 'Novara',
        },
        {
          id: '571670db-2e03-46ca-a3be-38d4eda7223f',
          name: 'Torino',
        },
        {
          id: '62cb5891-455c-4588-876e-00876beac262',
          name: 'Verbania-Cusio-Ossola',
        },
        {
          id: '74820b27-2153-42fa-a372-15dd51f41d96',
          name: 'Vercelli',
        },
      ],
    },
    {
      id: '96c7ab65-3669-4a38-b17f-eb42042d1aab',
      name: 'Puglia',
      provinces: [
        {
          id: '92cecc8f-9d6d-4985-bf5a-4dd5e7a64aa5',
          name: 'Bari',
        },
        {
          id: 'e9ae403a-1672-47d2-8192-7d3ab8744728',
          name: 'Barletta-Andria-Trani',
        },
        {
          id: '60bd7bf1-8807-4657-abb1-64e815deec0f',
          name: 'Brindisi',
        },
        {
          id: '28c9aee7-105c-4970-995d-b5a32fc58bbf',
          name: 'Foggia',
        },
        {
          id: '24f3a1e9-b097-4ac7-8bec-f04dac27c672',
          name: 'Lecce',
        },
        {
          id: '2bd3a304-225f-4aa6-84fd-80ccc7b03fe4',
          name: 'Taranto',
        },
      ],
    },
    {
      id: '5e82a004-7d77-4f90-a4d8-d1ec085356d7',
      name: 'Sardegna',
      provinces: [
        {
          id: '1a79e7d1-29aa-40dd-bce1-c86a072e0fd7',
          name: 'Cagliari',
        },
        {
          id: 'c3ac9fe4-6652-4c14-828f-d621583ece76',
          name: 'Nuoro',
        },
        {
          id: '806338c7-d51b-4e51-b499-dbd8ddada00e',
          name: 'Oristano',
        },
        {
          id: 'dfc2078e-a9cb-4a31-8258-94b90773e921',
          name: 'Sassari',
        },
        {
          id: '6c8975f5-3832-4eaa-8560-fde7e03d57d0',
          name: 'Sud Sardegna',
        },
      ],
    },
    {
      id: '7d78337c-ce8e-460d-951f-78c3aaae026f',
      name: 'Sicilia',
      provinces: [
        {
          id: '7b2c95aa-e839-411e-83e9-8d8425a1b882',
          name: 'Agrigento',
        },
        {
          id: '6bc00628-f59e-4fae-86ee-c312b6bc2b39',
          name: 'Caltanissetta',
        },
        {
          id: 'c0c07eaa-f9fb-47d3-9514-bd9253adedb4',
          name: 'Catania',
        },
        {
          id: 'd7851fc4-b99f-4d49-971f-907adcb2eef2',
          name: 'Enna',
        },
        {
          id: 'c7df2fd8-afdf-4474-9ca2-6c622a0deb6a',
          name: 'Messina',
        },
        {
          id: '087f26a5-6f81-42b1-985e-8a35e5753ba5',
          name: 'Palermo',
        },
        {
          id: '5e74e1ec-1418-4f9f-a863-d796b4e7e13b',
          name: 'Ragusa',
        },
        {
          id: '336cc85b-812d-4d43-aa3a-1359774a87b4',
          name: 'Siracusa',
        },
        {
          id: '80580356-a198-451d-8998-0fee0075c4ef',
          name: 'Trapani',
        },
      ],
    },
    {
      id: '82a0f671-c16c-4992-ae5b-01a2a7045213',
      name: 'Toscana',
      provinces: [
        {
          id: 'd5bb710f-9a49-49e0-94b3-f7726e4f29ca',
          name: 'Arezzo',
        },
        {
          id: '7c118b9e-9a37-48d8-acc3-e599a81f3b2e',
          name: 'Firenze',
        },
        {
          id: '4afa148c-b19c-4ab7-9953-053a78bdcccc',
          name: 'Grosseto',
        },
        {
          id: '54ab6fa5-9b1a-42af-a1f1-34c1f26df725',
          name: 'Livorno',
        },
        {
          id: '68163e7e-0145-44be-a03c-57aa4f1b024e',
          name: 'Lucca',
        },
        {
          id: '0c3b12bb-2b59-42f3-9130-61f91c2d2c74',
          name: 'Massa-Carrara',
        },
        {
          id: 'ce9e95de-8f4b-4857-9e30-9c7d4aec034d',
          name: 'Pisa',
        },
        {
          id: '0aa55a35-509d-483f-8275-32c0445a79f1',
          name: 'Pistoia',
        },
        {
          id: '4814fd6f-01a8-44ff-b4c2-9281610bcd55',
          name: 'Prato',
        },
        {
          id: '51f3352d-ff58-41be-9cde-f9dd3793d2ff',
          name: 'Siena',
        },
      ],
    },
    {
      id: 'ddd3bb83-8b7f-433e-b691-279450ee926c',
      name: 'Trentino-Alto Adige',
      provinces: [
        {
          id: 'baefb5cc-a575-4fb9-a885-ad3b5ee38c25',
          name: 'Bolzano',
        },
        {
          id: '55dee90a-2d56-4f63-9c6d-d92469bb1114',
          name: 'Trento',
        },
      ],
    },
    {
      id: 'cac4a6d4-ab59-4224-87ff-8abe8501debc',
      name: 'Umbria',
      provinces: [
        {
          id: '45045060-4459-40be-b990-424e3463933a',
          name: 'Perugia',
        },
        {
          id: '09da6601-2780-4397-80dd-b77f5c22c428',
          name: 'Terni',
        },
      ],
    },
    {
      id: '70133a37-bcd2-472f-9b72-8f0206a4cde0',
      name: "Valle d'Aosta",
      provinces: [
        {
          id: '95b61a32-7ead-451d-827b-816a555eb9f7',
          name: 'Aosta',
        },
      ],
    },
    {
      id: '7f6e5ef6-53f2-4fd0-b110-9aa58319d717',
      name: 'Veneto',
      provinces: [
        {
          id: '0bd1d81a-d90a-4dc7-8a79-4a9dad6c4fac',
          name: 'Belluno',
        },
        {
          id: '36072c7d-3dc4-4c6c-a122-c22912193fae',
          name: 'Padova',
        },
        {
          id: '30ba6ab1-4b44-45e3-b850-ec5a324093ec',
          name: 'Rovigo',
        },
        {
          id: 'cd3cfd6c-8c0e-4daf-8c65-ac9caf04104f',
          name: 'Treviso',
        },
        {
          id: 'aee7f5d6-c556-4d7a-84a3-75c4d96f3a11',
          name: 'Venezia',
        },
        {
          id: '2f6b3b5c-d5a0-4956-8fb8-0f9b877b070b',
          name: 'Verona',
        },
        {
          id: '45baa5fc-61cf-4931-aaf1-fd7e2347183b',
          name: 'Vicenza',
        },
      ],
    },
  ],
};

export const mockedAgeAndGenderData = {
  items: [
    {
      id: 'bbaee67a-4cf1-486b-936e-db5c49bc5ede',
      name: 'Adulti 15-24',
      status: 'ACTIVE',
    },
    {
      id: 'f15ade5f-b51e-49a2-b6f3-459a7691e678',
      name: 'Adulti 25-34',
      status: 'ACTIVE',
    },
    {
      id: '36af3653-2241-4c02-aa26-ff4a032a0493',
      name: 'Adulti 35-44',
      status: 'ACTIVE',
    },
    {
      id: '9170740d-e100-4747-9cf3-fa0f40b561dc',
      name: 'Adulti 45-54',
      status: 'ACTIVE',
    },
    {
      id: '2bfe113d-ab6f-40f7-9f29-fdbfd565ba53',
      name: 'Adulti 55-64',
      status: 'ACTIVE',
    },
    {
      id: 'b6f04f92-6bd2-4679-aea3-5435e58ee049',
      name: 'Adulti 65+',
      status: 'ACTIVE',
    },
    {
      id: '5b93cd65-0318-40b8-8925-9435cc7b8e9b',
      name: 'Donne',
      status: 'ACTIVE',
    },
    {
      id: 'ccb01e31-a68d-4cbe-a076-ac353c9fdaf4',
      name: 'Donne 15-24',
      status: 'ACTIVE',
    },
    {
      id: 'f3d4f1db-0705-4b65-ab2a-bf04277297a5',
      name: 'Donne 25-34',
      status: 'ACTIVE',
    },
    {
      id: 'c9ec8b23-7055-47cb-8c98-4900574a8599',
      name: 'Donne 35-44',
      status: 'ACTIVE',
    },
    {
      id: 'b0eceb07-7fd1-441e-98b3-7949d96c16b0',
      name: 'Donne 45-54',
      status: 'ACTIVE',
    },
    {
      id: 'b1267b76-9dd9-4cdc-aa4d-6194844c2b63',
      name: 'Donne 55-64',
      status: 'ACTIVE',
    },
    {
      id: '86a7cf43-b5c1-4d9e-9d74-5f4db8b27030',
      name: 'Donne 65+',
      status: 'ACTIVE',
    },
    {
      id: 'a537bd78-a39b-4993-b8be-f35a980caddb',
      name: 'Fam con bambini 0-3',
      status: 'ACTIVE',
    },
    {
      id: '4965dd94-5fdb-4728-a3b0-e50fcafa0521',
      name: 'Fam con bambini 4-7',
      status: 'ACTIVE',
    },
    {
      id: '4e7c7599-a74b-415f-913d-1c6f29d80564',
      name: 'Fam con figli 0-14',
      status: 'ACTIVE',
    },
    {
      id: '8b239f54-c1bc-426a-9e4f-29c54ebcb2b9',
      name: 'Fam con ragazzi 8-14',
      status: 'ACTIVE',
    },
    {
      id: '7cba3692-dd21-442e-af3d-916337d4411a',
      name: 'Famiglie',
      status: 'ACTIVE',
    },
    {
      id: 'e62565b2-167a-41ca-b068-4dd26d75bf94',
      name: 'Single',
      status: 'ACTIVE',
    },
    {
      id: '11a24460-eb9f-4141-97e1-4024662acb03',
      name: 'Uomini',
      status: 'ACTIVE',
    },
    {
      id: '664fbb95-da24-49a8-a257-7e90a970b6e1',
      name: 'Uomini 15-24',
      status: 'ACTIVE',
    },
    {
      id: 'ba4fa4ef-4b4f-495a-a1b6-b6028d541f01',
      name: 'Uomini 25-34',
      status: 'ACTIVE',
    },
    {
      id: '29966b38-4a8d-4ad7-b6c4-155196c2a74a',
      name: 'Uomini 35-44',
      status: 'ACTIVE',
    },
    {
      id: '0bdb20bd-6af3-4dc8-a57d-9c36185b142f',
      name: 'Uomini 45-54',
      status: 'ACTIVE',
    },
    {
      id: 'f1840d12-12c2-4887-83f9-1d6cff8b9e67',
      name: 'Uomini 55-64',
      status: 'ACTIVE',
    },
    {
      id: 'b41e2e33-716d-47bf-b5bb-53db9b478c76',
      name: 'Uomini 65+',
      status: 'ACTIVE',
    },
  ],
  meta: {
    totalItems: 26,
    itemCount: 26,
    itemsPerPage: 10000,
    totalPages: 1,
    currentPage: 1,
  },
};
