import canale5LogoImage from '@features/campaigns/builder/assets/images/canale-5-logo.svg';
import tvFrameImage from '@features/campaigns/builder/assets/images/creative-preview-tv-frame-opt.png';
import tvProgramPlaceholderImage from '@features/campaigns/builder/assets/images/tv-program-placeholder.jpg';

import styles from './PreviewTVWrapper.module.scss';

interface PreviewTVWrapperProps {
  imageUrl: string;
}
export function PreviewTVWrapper({ imageUrl }: PreviewTVWrapperProps) {
  return (
    <div className={styles.container}>
      <img src={tvFrameImage} alt={'tv-frame'} className={styles.tvFrameBackground} />
      <img src={tvProgramPlaceholderImage} alt={'tv-program-placeholder'} className={styles.tvProgramPlaceholder} />
      <div className={styles.creativePreviewBox}>
        <img src={imageUrl} alt={'creative-preview'} className={styles.creativeImage} />
      </div>
      <img src={canale5LogoImage} alt={'canale-5-logo'} className={styles.canale5Logo} />
    </div>
  );
}
