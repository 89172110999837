import { createContext } from 'react';

export interface ModalState {
  isOpen: boolean;
  isLoading: boolean;
  isFormDirty: boolean;
}

interface ModalActions {
  toggleModal: () => void;
  setIsFormDirty: (isDirty: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export type ContextValue = ModalState & ModalActions;

export const LeaveGuardModalContext = createContext<ContextValue | undefined>(undefined);
