import { FieldTypes } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { UpdateCampaignFormStep04Values } from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import { UpdateCampaignPricingPayload } from '@features/campaigns/builder/services/campaign/pricing.ts';
import { CampaignDetails, InvalidSectionsItem } from '@features/campaigns/shared/types/campaign.ts';

export interface RestoredStep04Data {
  [FieldTypes.IMPRESSIONS]: number | undefined;
  [FieldTypes.BONUS_IMPRESSIONS_TOGGLE]: boolean | undefined;
  [FieldTypes.BONUS_IMPRESSIONS]: number | undefined | null;
  [FieldTypes.CPM]: number | undefined;
  [FieldTypes.ADDITIONAL_NOTE]: string;
  [FieldTypes.BUDGET]: number;
  status: CampaignDetails['status'];
  invalidSections?: InvalidSectionsItem[];
}

export interface UpdateCampaignStep04Payload {
  additionalNote: string;
}

export function restoreStep04DataDTO(data: CampaignDetails): RestoredStep04Data {
  return {
    [FieldTypes.IMPRESSIONS]: data.pricing?.impressions || undefined,
    [FieldTypes.BONUS_IMPRESSIONS_TOGGLE]: !!data.pricing?.bonusImpressions || undefined,
    [FieldTypes.BONUS_IMPRESSIONS]: data.pricing?.bonusImpressions || null,
    [FieldTypes.CPM]: data.pricing?.cpm || undefined,
    [FieldTypes.ADDITIONAL_NOTE]: data.additionalNote || '',
    [FieldTypes.BUDGET]: data.budget,
    status: data.status,
    invalidSections: data.invalidSections,
  };
}

export type LastFieldUsedUnion = typeof FieldTypes.IMPRESSIONS | typeof FieldTypes.CPM | undefined;

interface UpdatePricingDTOAdditionalConditions {
  canEditBonusImpressions: boolean;
}

export function updatePricingDTO(
  formValues: UpdateCampaignFormStep04Values,
  lastFieldUsed: LastFieldUsedUnion,
  { canEditBonusImpressions }: UpdatePricingDTOAdditionalConditions,
): UpdateCampaignPricingPayload {
  const { budget, cpm, impressions, bonusImpressions } = formValues;

  let payload: UpdateCampaignPricingPayload = {
    budget,
  };

  if (canEditBonusImpressions) {
    const bonusImpressionsValue = bonusImpressions === null ? 0 : bonusImpressions;

    payload = { ...payload, bonusImpressions: bonusImpressionsValue };
  }

  if (lastFieldUsed === FieldTypes.CPM) {
    payload = { ...payload, cpm };
  }

  if (lastFieldUsed === FieldTypes.IMPRESSIONS) {
    payload = { ...payload, impressions };
  }

  return payload;
}
export function updateCampaignStep04DTO(formValues: UpdateCampaignFormStep04Values): UpdateCampaignStep04Payload {
  return {
    additionalNote: formValues.additionalNote || '',
  };
}
