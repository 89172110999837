import { Dialog } from 'primereact/dialog';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaTypePanel } from '@features/campaigns/list/components/MediaType/Panel/MediaTypePanel.tsx';
import { CampaignMediaType, CampaignMediaTypes } from '@features/campaigns/shared/types/campaign.ts';
import { ICON_VARIANTS } from '@shared/components/Icon/IconMapper.ts';
import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './MediaTypeModal.module.scss';

interface MediaTypeModalProps {
  isVisible: boolean;
  toggleModalVisibility: () => void;
  selectedMediaType: CampaignMediaType;
  setSelectedMediaType: Dispatch<SetStateAction<CampaignMediaType>>;
  submitCallback: () => void;
}

export function MediaTypeModal({
  isVisible,
  toggleModalVisibility,
  setSelectedMediaType,
  selectedMediaType,
  submitCallback,
}: MediaTypeModalProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <Dialog
      visible={isVisible}
      onHide={toggleModalVisibility}
      draggable={false}
      resizable={false}
      header={t('builder.modals.headers.selectMediaType')}
      footer={
        <DefaultActionsFooter
          cancelLabel={t('builder.modals.cancel')}
          cancelCallback={toggleModalVisibility}
          submitLabel={t('builder.modals.next')}
          submitCallback={submitCallback}
        />
      }
    >
      <div className={styles.wrapper}>
        <MediaTypePanel
          mediaType={CampaignMediaTypes.CTV}
          iconVariant={ICON_VARIANTS.ctv}
          label={t('builder.mediaTypes.ctv.label')}
          iconLabel={t('builder.mediaTypes.ctv.iconLabel')}
          description={t('builder.mediaTypes.ctv.description')}
          changeHandler={setSelectedMediaType}
          isActive={selectedMediaType === CampaignMediaTypes.CTV}
        />
        <MediaTypePanel
          mediaType={CampaignMediaTypes.CROSS}
          iconVariant={ICON_VARIANTS.cross}
          label={t('builder.mediaTypes.cross.label')}
          iconLabel={t('builder.mediaTypes.cross.iconLabel')}
          description={t('builder.mediaTypes.cross.description')}
          changeHandler={setSelectedMediaType}
          isActive={selectedMediaType === CampaignMediaTypes.CROSS}
        />
      </div>
    </Dialog>
  );
}
