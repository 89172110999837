import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './NoImages.module.scss';

export function NoImages() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <div className={styles.noImages} data-testid='no-images'>
      {t('builder.formFields.common.noImages')}
    </div>
  );
}
