import { useQuery, useQueryClient } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { downloadPptxDetailsPresentation } from '@features/campaigns/details/services/downloadPptxDetailsPresentation.ts';
import {
  downloadPptxPerformancePresentation,
  downloadXlsxPerformancePresentation,
} from '@features/campaigns/performance/services/downloadPerformancePresentation.ts';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import { CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';
import {
  DownloadedFileExtension,
  DownloadedFileExtensions,
  DownloadedFileType,
  DownloadedFileTypes,
} from '@features/campaigns/shared/types/exportFileDownloadType.ts';
import { getDownloadedFileName } from '@shared/utils/getDownloadedFileName/getDownloadedFileName.ts';

interface ExportFileDownloadProps {
  fileType: DownloadedFileType;
}

export function useExportFileDownload({ fileType }: ExportFileDownloadProps) {
  const [downloadFileExtension, setDownloadFileExtension] = useState<DownloadedFileExtension | ''>('');
  const queryClient = useQueryClient();
  const { campaignId } = useParams();
  const cachedCampaignData = queryClient.getQueryData([
    QUERY_KEYS.CAMPAIGN_DETAILS,
    campaignId,
    null,
  ]) as CampaignDetails;

  const {
    data: fileData,
    isFetching: isLoading,
    isError,
  } = useQuery({
    queryKey: [fileType, downloadFileExtension, campaignId],
    queryFn: () => {
      if (!campaignId) {
        return null;
      }

      if (fileType === DownloadedFileTypes.DETAILS) {
        return downloadPptxDetailsPresentation(campaignId);
      }

      if (fileType === DownloadedFileTypes.REPORT && downloadFileExtension === DownloadedFileExtensions.PPTX) {
        return downloadPptxPerformancePresentation(campaignId);
      }

      if (fileType === DownloadedFileTypes.REPORT && downloadFileExtension === DownloadedFileExtensions.XLSX) {
        return downloadXlsxPerformancePresentation(campaignId);
      }
    },
    enabled: !!downloadFileExtension,
    gcTime: 0,
  });

  useEffect(() => {
    if (cachedCampaignData && fileData && !!downloadFileExtension) {
      const fileName = getDownloadedFileName({
        fileType: fileType,
        fileExtension: downloadFileExtension,
        campaignStartDate: cachedCampaignData.startDate,
        campaignSequentialId: cachedCampaignData.sequentialId,
        campaignName: cachedCampaignData.name,
      });

      saveAs(fileData, fileName);
      setDownloadFileExtension('');
    }

    if (isError) {
      setDownloadFileExtension('');
    }
  }, [fileData, downloadFileExtension, isError]);

  return {
    isLoading,
    setDownloadFileExtension,
  };
}
