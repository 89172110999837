import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  fetchAdvertisersAssignedToExternalUserWithAdvertiserResponseType,
  fetchAdvertisersForExternalUser,
} from '@features/campaigns/builder/services/formFields';
import { getNextParamFn } from '@features/campaigns/shared/components/FormFields/Dropdown/utils/getNextParamFn';
import { Advertiser } from '@features/campaigns/shared/types/campaign';
import { TreeWithSearch } from '@features/users/details/components/TreeWithSearch/TreeWithSearch.tsx';
import { QUERY_KEYS } from '@features/users/details/consts/queryKeys.ts';
import { assignAdvertisersForExternalUser } from '@features/users/details/services/formFields';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { LeaveGuardModal } from '@shared/components/Modal/LeaveGuardModal/LeaveGuardModal';
import { ErrorMessage } from '@shared/components/Toast/ErrorMessage/ErrorMessage';
import errorMessageStyles from '@shared/components/Toast/ErrorMessage/ErrorMessage.module.scss';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { useDebounce } from '@shared/hooks/useDebounce';
import { useLeaveGuardModalContext } from '@shared/providers/LeaveGuardModalProvider/useLeaveGuardModalContext';
import { routePaths } from '@shared/router/routePaths.ts';
import { STATUS } from '@shared/types/common';
import { formatNameWithCode } from '@shared/utils/formatNameWithCode';

import { AdvertisersAssignPayload } from './AdvertisersAssign.dto';
import styles from './AdvertisersAssign.module.scss';

const INACTIVE_ADVERTISERS_TOAST_ID = 'inactiveAdvertisers';

const handleDisplayOfInactiveAdvertisers = (data: AdManagerApiResponse<Advertiser[]> | null) => {
  const inactiveAdvertisers = data?.items?.filter((advertiser) => advertiser.status === STATUS.INACTIVE) || [];
  const shouldDisplayErrorToast = inactiveAdvertisers.length > 0;

  if (shouldDisplayErrorToast) {
    return toast.error(
      <>
        <p className={styles.toastText}>{t('api.error.externalUser.inactiveAdvertisers', { ns: i18nNameSpace.API })}</p>
        <ul>
          {inactiveAdvertisers.map(({ id, name, code }) => (
            <li key={id} className={errorMessageStyles.toastMessageListItem}>
              {formatNameWithCode(name, code)}
            </li>
          ))}
        </ul>
        <p className={styles.toastText}>
          {t('api.error.externalUser.inactiveAdvertisersFooter', { ns: i18nNameSpace.API })}
        </p>
      </>,
      {
        toastId: INACTIVE_ADVERTISERS_TOAST_ID,
        autoClose: false,
      },
    );
  }
};

const checkIsFormDirty = (newlySelectedIds: string[], previouslyAssignedAdvertisers: Advertiser[] | undefined) => {
  const previouslyAssignedAdvertisersIds = previouslyAssignedAdvertisers?.map(({ id }) => id) || [];

  if (previouslyAssignedAdvertisersIds.length !== newlySelectedIds.length) {
    return true;
  }

  return previouslyAssignedAdvertisersIds.every((id) => !newlySelectedIds.includes(id));
};

export function AdvertisersAssign() {
  const { t } = useTranslation([i18nNameSpace.USERS]);
  const { userId } = useParams();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchQueryValue, setSearchQueryValue] = useState<string | undefined>(undefined);
  const debouncedQueryValue = useDebounce(searchQueryValue);
  const form = useForm<AdvertisersAssignPayload>();
  const navigate = useNavigate();
  const { setIsFormDirty, toggleModal, isOpen: isLeaveUserDetailsModalOpen } = useLeaveGuardModalContext();

  const {
    data: previouslyAssignedAdvertisers,
    isFetching: isPreviouslyAssignedAdvertisersFetching,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_USER_ADVERTISERS, userId],
    queryFn: async () => {
      const response = await fetchAdvertisersAssignedToExternalUserWithAdvertiserResponseType(userId!);
      const activeAdvertisers = response?.items?.filter(({ status }) => status !== STATUS.INACTIVE) || [];
      const previouslyAssignedAdvertisersIds = activeAdvertisers.map(({ id }) => id);

      handleDisplayOfInactiveAdvertisers(response);

      setSelectedIds(previouslyAssignedAdvertisersIds);
      return activeAdvertisers;
    },
  });

  const {
    data: advertisersData,
    fetchNextPage,
    isFetching: isAdvertisersDataFetching,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_USER_ADVERTISERS, debouncedQueryValue],
    queryFn: (queryParams) => {
      const search = debouncedQueryValue;
      const page = queryParams?.pageParam?.page || 1;

      return fetchAdvertisersForExternalUser({ pageParam: { page, search } });
    },
    initialPageParam: { page: 1, search: debouncedQueryValue },
    getNextPageParam: getNextParamFn(debouncedQueryValue),
    gcTime: 0,
  });

  const advertisersFromList =
    advertisersData?.pages
      ?.map((page) => {
        if (page?.items) {
          return page.items;
        }
        return [];
      })
      .flat() || [];

  const currentItemsCount = advertisersFromList?.length;
  const totalItems = advertisersData?.pages?.at(-1)?.meta?.totalItems || 0;

  const { mutate: assignAdvertisersMutation, isPending: assignAdvertisersIsPending } = useMutation({
    mutationFn: (payload: AdvertisersAssignPayload) => assignAdvertisersForExternalUser(userId!, payload),
    onSuccess: async () => {
      toast.success(
        <SuccessMessage
          message={t('api.success.externalUser.assignedAdvertisersUpdated', { ns: i18nNameSpace.API })}
        />,
      );
      if (isLeaveUserDetailsModalOpen) {
        toggleModal();
        navigate(routePaths.users);
      }
      await refetch();
      setIsFormDirty(false);
      toast.dismiss(INACTIVE_ADVERTISERS_TOAST_ID);
    },
    onError: (error) => {
      const axiosError = axios.isAxiosError(error);

      if (axiosError) {
        const apiError = 'response' in error && error.response && 'data' in error.response && error.response.data;

        if (apiError) {
          return toast.error(<ErrorMessage error={apiError?.i18n?.key} />);
        }
      }
    },
  });

  const submitAdvertisersAssign: SubmitHandler<AdvertisersAssignPayload> = () => {
    assignAdvertisersMutation({ advertiserIds: selectedIds });
  };

  const handleOnSearchChange = (value: string | undefined) => {
    const canSetSearchQuery = value && value.length > 2;
    const shouldClearSearchQuery = typeof value === 'undefined';

    if (shouldClearSearchQuery || canSetSearchQuery) {
      setSearchQueryValue(value);
    }
  };

  const handleSelectedIdsChange = (newlySelectedIds: string[]) => {
    setSelectedIds(newlySelectedIds);

    const isFormDirty = checkIsFormDirty(newlySelectedIds, previouslyAssignedAdvertisers);
    setIsFormDirty(isFormDirty);
  };

  const getSelectedAdvertisers = () => {
    if (previouslyAssignedAdvertisers) {
      const combinedAdvertisersData = [...previouslyAssignedAdvertisers, ...advertisersFromList];

      const selectedAdvertisers = combinedAdvertisersData.filter((advertiser) => selectedIds.includes(advertiser.id));

      const uniqueSelectedAdvertisers = [
        ...new Map(selectedAdvertisers.map((advertiser) => [advertiser.id, advertiser])).values(),
      ];

      return uniqueSelectedAdvertisers;
    }
  };

  const selectedAdvertisers = getSelectedAdvertisers();

  useEffect(() => {
    return () => toast.dismiss(INACTIVE_ADVERTISERS_TOAST_ID);
  }, []);

  return (
    <section className={styles.wrapper}>
      <header className={styles.header}>
        <h2 className={styles.text}>{t('page.details.headers.advertisersAssign')}</h2>
      </header>
      <form onSubmit={form.handleSubmit(submitAdvertisersAssign)} autoComplete={'off'}>
        <div className={styles.cardWrapper}>
          <div className='col-12 no-v-padding'>
            <TreeWithSearch
              data={advertisersFromList}
              chipsData={selectedAdvertisers}
              selectedIds={selectedIds}
              setSelectedIds={handleSelectedIdsChange}
              fetchNextPage={fetchNextPage}
              currentItemsCount={currentItemsCount}
              totalItems={totalItems}
              isFetching={isAdvertisersDataFetching || isPreviouslyAssignedAdvertisersFetching}
              setSearchInputValueCallback={handleOnSearchChange}
            />
          </div>
        </div>
        <footer className={styles.footer}>
          <Button data-testid='update-agency-assignment-button' type='submit' loading={assignAdvertisersIsPending}>
            {t('page.details.common.saveAssignment')}
          </Button>
        </footer>
      </form>
      <LeaveGuardModal
        cancelCallback={() => navigate(routePaths.users)}
        submitCallback={form.handleSubmit(submitAdvertisersAssign)}
        headerText={t('page.details.modals.headers.leaveUserDetails')}
        cancelLabelText={t('page.details.modals.discardChanges')}
        submitLabelText={t('page.details.modals.yesSave')}
      >
        {t('page.details.modals.content.leaveUserDetails')}
      </LeaveGuardModal>
    </section>
  );
}
