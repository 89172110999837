import { Badge } from 'primereact/badge';

interface PlaceholderWithBadgeProps<T> {
  data?: T;
  placeholder: string;
  prefixId: string;
}

function PlaceholderWithBadge<T extends unknown[]>({ data, placeholder, prefixId }: PlaceholderWithBadgeProps<T>) {
  return (
    <span className='selected-label-wrapper'>
      <span data-testid={`${prefixId}-filter-placeholder`}>{placeholder}</span>
      <Badge value={data?.length} data-testid={`${prefixId}-filter-count-badge`} />
    </span>
  );
}

export default PlaceholderWithBadge;
