import cn from 'classnames';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

import { DropdownItem } from '@features/campaigns/builder/components/FormFields/FieldTypes';
import { Advertiser } from '@features/campaigns/shared/types/campaign.ts';
import { STATUS } from '@shared/types/common';
import { formatNameWithCode } from '@shared/utils/formatNameWithCode.ts';

import styles from './ChipsCounter.module.scss';

const MAX_VISIBLE_CHIPS = 2;
const MAX_REMAINING_CHIPS = 10 + MAX_VISIBLE_CHIPS;

interface ChipsCounterProps {
  data: Advertiser[];
  selectedItems: string[];
  removeAdvertiser: (id: string) => void;
}

function availableChips(data: Advertiser[], selectedItems: string[]) {
  const selectedItemsSet = new Set(selectedItems);
  return data.filter((advertiser) => selectedItemsSet.has(advertiser.id));
}

export function ChipsCounter({ data, selectedItems, removeAdvertiser }: ChipsCounterProps) {
  const chips = availableChips(data, selectedItems);

  const visibleChips = chips.slice(0, MAX_VISIBLE_CHIPS);
  const tooltipChips = chips.slice(MAX_VISIBLE_CHIPS, MAX_REMAINING_CHIPS);
  const remainingChips = chips.slice(MAX_REMAINING_CHIPS);
  const additionalChipsCount = tooltipChips.length + remainingChips.length;

  const removeHandler = (advertiser: Advertiser | DropdownItem) => {
    const isAdvertiserTypeData = 'id' in advertiser;
    removeAdvertiser(isAdvertiserTypeData ? advertiser.id : advertiser.value);
  };

  return (
    <>
      {visibleChips.map((advertiser) => {
        const nameWithCode = formatNameWithCode(advertiser.name, advertiser.code);
        const isChipRemovable = advertiser.status === STATUS.ACTIVE;

        return (
          <Chip
            key={advertiser.id}
            label={nameWithCode}
            removable={isChipRemovable}
            data-testid={`${nameWithCode}-chip`}
            removeIcon={
              <i
                key={advertiser.id}
                className={cn('pi pi-times', styles.removeIcon)}
                onClick={() => removeHandler(advertiser)}
                data-testid={`${nameWithCode}-chip-remove-icon`}
              ></i>
            }
          />
        );
      })}
      {tooltipChips.length > 0 && (
        <>
          <Tooltip target='.summary-chip' position='top' autoHide={false}>
            <ul className={styles.tooltipList}>
              {tooltipChips.map((advertiser) => {
                const nameWithCode = formatNameWithCode(advertiser.name, advertiser.code);

                return (
                  <li key={advertiser.id} className={styles.item}>
                    {nameWithCode}
                  </li>
                );
              })}
              {remainingChips.length > 0 && <li className={styles.item}>...</li>}
            </ul>
          </Tooltip>
          <Chip className='summary-chip chip-secondary' label={`+ ${additionalChipsCount}`} />
        </>
      )}
    </>
  );
}
