import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n';

interface ExportButtonProps {
  menuReference: MutableRefObject<null | Menu>;
  isLoading: boolean;
  label:
    | 'page.campaignDetails.campaignDetailsTab.labels.exportDetails'
    | 'page.campaignDetails.performanceTab.exportReportButton';
  dataTestId: 'campaign-details-export-button' | 'performance-export-report-button';
}

export default function ExportButton({ menuReference, isLoading, label, dataTestId }: ExportButtonProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <Button
      label={t(label)}
      icon='pi pi-chevron-down'
      outlined
      iconPos='right'
      onClick={(event) => {
        menuReference.current?.toggle(event);
      }}
      loading={isLoading}
      data-testid={dataTestId}
    />
  );
}
