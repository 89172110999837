export const commonChartTooltipStyles = {
  usePointStyle: true,
  padding: 10.5,
  titleMarginBottom: 7,
  backgroundColor: '#495057',
  boxPadding: 7,
  boxWidth: 7,
  boxHeight: 7,
  titleColor: '#fff',
  titleFont: {
    size: 14,
    weight: 500,
    family: 'Inter-Variable',
  },
  cornerRadius: 6,
  caretSize: 7,
};
