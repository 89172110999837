import { campaignBuilderRoutePathsMap } from '@shared/router/routePaths.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export const getStepperActiveIndex = (pathname: string, campaignId: CampaignId | undefined) => {
  const currentStep = Object.keys(campaignBuilderRoutePathsMap).reduce(
    (acc, curr) => {
      if (!campaignId && campaignBuilderRoutePathsMap[curr].url() === pathname) {
        return campaignBuilderRoutePathsMap[curr];
      }
      if (campaignBuilderRoutePathsMap[curr].url(campaignId) === pathname) {
        return campaignBuilderRoutePathsMap[curr];
      }
      return acc;
    },
    { url: () => '/campaigns/builder/', activeStep: 0, routerPath: '' },
  );

  return currentStep.activeStep;
};
