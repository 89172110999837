import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import TopContent from '@features/campaigns/performance/components/TopCharts/components/TopContent/TopContent.tsx';
import TopGeo from '@features/campaigns/performance/components/TopCharts/components/TopGeo/TopGeo.tsx';
import { QUERY_KEYS } from '@features/campaigns/performance/consts/queryKeys.ts';
import { getCampaignTopCharts } from '@features/campaigns/performance/services/getCampaignTopCharts.ts';

export function TopCharts() {
  const { campaignId } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.TOP_CHARTS, campaignId],
    queryFn: () => {
      if (campaignId) {
        return getCampaignTopCharts(campaignId);
      }
    },
  });

  const geoData = data
    ? {
        provinces: data.provinces,
        regions: data.regions,
      }
    : null;

  return (
    <section data-testid='top-charts'>
      <TopGeo isFetching={isFetching} data={geoData} />
      <TopContent isFetching={isFetching} data={data?.genres} />
    </section>
  );
}
