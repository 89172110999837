import { format } from 'date-fns';

import {
  DownloadedFileExtension,
  DownloadedFileType,
  DownloadedFileTypes,
} from '@features/campaigns/shared/types/exportFileDownloadType.ts';

interface GetDownloadedFileNameParams {
  fileType: DownloadedFileType;
  fileExtension: DownloadedFileExtension;
  campaignStartDate: string | undefined;
  campaignSequentialId: number | undefined;
  campaignName: string | undefined;
}

export function getDownloadedFileName({
  fileType,
  fileExtension,
  campaignStartDate = '',
  campaignSequentialId,
  campaignName = '',
}: GetDownloadedFileNameParams) {
  const type = fileType === DownloadedFileTypes.REPORT ? ' - report' : '';
  const formattedStartDate = format(new Date(campaignStartDate), 'dd-MM-yyyy');
  const fileName = `${campaignName} - ${campaignSequentialId || ''}${type} - ${formattedStartDate}.${fileExtension}`;

  return fileName;
}
