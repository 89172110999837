import { PropsWithChildren } from 'react';

import styles from './Item.module.scss';

interface ItemProps {
  label: string;
  value?: string | JSX.Element;
}

export function Item({ label, value, children }: PropsWithChildren<ItemProps>) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{label}</p>
      {value && <p className={styles.value}>{value}</p>}
      {children && children}
    </div>
  );
}
