import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';

import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './ApproveCampaignBudgetModal.module.scss';

interface ApproveCampaignBudgetModalProps {
  isVisible: boolean;
  toggleModalVisibility: () => void;
  approveCallback: () => void;
  campaignName: string;
  submitCallbackActionIsLoading: boolean;
}

export function ApproveCampaignBudgetModal({
  isVisible,
  toggleModalVisibility,
  approveCallback,
  campaignName,
  submitCallbackActionIsLoading,
}: ApproveCampaignBudgetModalProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <Dialog
      visible={isVisible}
      onHide={toggleModalVisibility}
      draggable={false}
      resizable={false}
      header={t('page.campaignDetails.campaignDetailsTab.modals.headers.approveCampaignBudget')}
      className={styles.maxWidth}
      ariaCloseIconLabel={t('dialog.closeButton', { ns: i18nNameSpace.COMMON })}
      footer={
        <DefaultActionsFooter
          submitLabel={t('page.campaignDetails.campaignDetailsTab.modals.yesApproveBudget')}
          cancelLabel={t('page.campaignDetails.campaignDetailsTab.modals.cancel')}
          cancelCallback={toggleModalVisibility}
          submitCallback={approveCallback}
          submitCallbackActionIsLoading={submitCallbackActionIsLoading}
        />
      }
    >
      <Trans
        t={t}
        i18nKey={'page.campaignDetails.campaignDetailsTab.modals.content.approveBudgetConfirmationQuestion'}
        values={{ campaignName }}
      >
        Are you sure you want to approve <strong className={styles.boldCampaignName}></strong> budget?
      </Trans>
    </Dialog>
  );
}
