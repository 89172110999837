import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import pageHeaderStyles from '@features/campaigns/shared/components/PageHeader/PageHeader.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { useLeaveGuardModalContext } from '@shared/providers/LeaveGuardModalProvider/useLeaveGuardModalContext.ts';
import { routePaths } from '@shared/router/routePaths.ts';

function PageHeader() {
  const { t } = useTranslation([i18nNameSpace.CAMPAIGNS]);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { toggleModal, isFormDirty } = useLeaveGuardModalContext();

  const handleCloseBuilderProcess = () => {
    if (isFormDirty) {
      return toggleModal();
    }

    navigate(locationState || routePaths.campaigns);
  };

  return (
    <div className={pageHeaderStyles.container}>
      <div className='content-wrapper'>
        <header className={pageHeaderStyles.headerWrapper}>
          <h3 className={pageHeaderStyles.header}>{t('page.campaignDetails.title')}</h3>
          <button
            className={pageHeaderStyles.close}
            data-testid='close-details-view-button'
            type='button'
            onClick={handleCloseBuilderProcess}
          >
            <i className='pi pi-times'></i>
          </button>
        </header>
      </div>
    </div>
  );
}

export default PageHeader;
