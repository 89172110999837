import { Permission, Permissions } from '@shared/consts/permissions';
import { routePaths } from '@shared/router/routePaths';

interface NavigationItem {
  route: typeof routePaths.campaigns | typeof routePaths.users;
  icon: string;
  displayName: string;
  requiredPermissions: Permission[];
}

export const navigationItems: NavigationItem[] = [
  {
    route: routePaths.campaigns,
    icon: 'pi-file',
    displayName: 'navigation.campaigns',
    requiredPermissions: [
      Permissions.CAMPAIGN_LIST_ALL,
      Permissions.CAMPAIGN_LIST_OWNED,
      Permissions.CAMPAIGN_LIST_ASSIGNED_ADVERTISERS,
    ],
  },
  {
    route: routePaths.users,
    icon: 'pi-users',
    displayName: 'navigation.users',
    requiredPermissions: [Permissions.USER_MODIFY],
  },
];
