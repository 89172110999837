import cn from 'classnames';

import { CampaignStatus, CampaignStatuses } from '@features/campaigns/shared/types/campaign';

import styles from './StatusIcon.module.scss';

const statusIconStyles: Record<CampaignStatus, string> = {
  [CampaignStatuses.DRAFT]: styles.draft,
  [CampaignStatuses.BUDGET_APPROVAL]: styles.budgetApproval,
  [CampaignStatuses.FAILED]: styles.failed,
  [CampaignStatuses.IN_APPROVAL]: styles.inApproval,
  [CampaignStatuses.APPROVED]: styles.approved,
  [CampaignStatuses.DELIVERING]: styles.delivering,
  [CampaignStatuses.COMPLETED]: styles.completed,
  [CampaignStatuses.PAUSED]: styles.paused,
};

interface StatusIconProps {
  variant: CampaignStatus;
}

export default function StatusIcon({ variant }: StatusIconProps) {
  return <span className={cn(styles.commonStatusIcon, statusIconStyles[variant])} />;
}
