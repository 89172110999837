import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth, EXTERNAL_USER_ROLE_ID } from '@features/auth/hooks/useAuth';
import { USER_ROLES } from '@features/auth/utils/constants';
import { Permission } from '@shared/consts/permissions';
import { useRolesDetailsContext } from '@shared/providers/RolesDetailsProvider/useRolesDetailsContext';

interface ControlAccessProps {
  requiredPermissions?: Permission[];
  accessDeniedRedirectTo?: string;
  children: ReactNode;
  fallbackView?: ReactNode;
  additionalCondition?: boolean;
}

const hasRequiredPermissions = (userPermissions: Permission[], requiredPermissions: Permission[]) => {
  return requiredPermissions.some((requiredPermission) => userPermissions.includes(requiredPermission));
};

export const ControlAccess: FC<ControlAccessProps> = ({
  requiredPermissions,
  accessDeniedRedirectTo,
  children,
  fallbackView = null,
  additionalCondition,
}) => {
  const [pass, setPass] = useState<boolean>(false);
  const { getRoleId } = useAuth();
  const navigate = useNavigate();
  const rolesDetailsContext = useRolesDetailsContext();

  useEffect(() => {
    (async () => {
      if (!requiredPermissions) {
        return setPass(true);
      }

      const userRoleId = await getRoleId();
      const isExternalUser = userRoleId === EXTERNAL_USER_ROLE_ID;

      if (!userRoleId) {
        return;
      }

      const userPermissions = rolesDetailsContext?.find(({ roleGroupId, name: roleName }) =>
        isExternalUser ? roleName === USER_ROLES.EXTERNAL_SALES_USER : roleGroupId === userRoleId,
      )?.permissions;

      if (!userPermissions || !userPermissions.length) {
        return;
      }

      const conditionPassed = hasRequiredPermissions(userPermissions, requiredPermissions);
      setPass(conditionPassed);

      if (!conditionPassed && accessDeniedRedirectTo) {
        navigate(accessDeniedRedirectTo);
      }
    })();
  }, [getRoleId, rolesDetailsContext]);

  const additionalConditionPassed = additionalCondition === undefined || additionalCondition;

  if (pass && additionalConditionPassed) {
    return children;
  }

  return fallbackView;
};
