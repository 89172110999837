export const USER_ROLES = {
  CREDIT_USER: 'CREDIT_USER',
  SALES_USER: 'SALES_USER',
  SALES_ADMIN: 'SALES_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  EXTERNAL_SALES_USER: 'EXTERNAL_SALES_USER',
  SALES_PORTFOLIO_USER: 'SALES_PORTFOLIO_USER',
} as const;

type ObjectValues<T> = T[keyof T];

export type UserRoles = ObjectValues<typeof USER_ROLES>;
