import { Skeleton } from 'primereact/skeleton';

import styles from './LoadingItemTemplate.module.scss';

export function LoadingItemTemplate() {
  return (
    <div className={styles.wrapper}>
      <Skeleton width={'100%'} height='1.5rem' />
    </div>
  );
}
