import { TFunction } from 'i18next';

import { CampaignFormats } from '@features/campaigns/shared/types/campaign.ts';
import { I18nNameSpaces } from '@shared/consts/i18n';
import { objectKeys } from '@shared/utils/objectKeys';

export const getFormatOptions = (t: TFunction<I18nNameSpaces['CAMPAIGNS']>) => {
  const campaignFormatKeys = objectKeys(CampaignFormats);

  const statusOptions = campaignFormatKeys.map((campaignFormatKey) => ({
    label: t(`page.list.campaignFormat.${campaignFormatKey.toLowerCase()}`),
    value: CampaignFormats[campaignFormatKey],
  }));

  return statusOptions;
};
