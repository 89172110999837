import { ImpressionsDistributionData } from '@features/campaigns/performance/components/ImpressionsDistribution/types.ts';
import { CampaignPerformanceGeneralType } from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { TopChartsData } from '@features/campaigns/performance/components/TopCharts/TopCharts.types.ts';
import {
  CampaignPerformanceAllType,
  CampaignPerformanceDisplayType,
  CampaignPerformanceVideoType,
} from '@features/campaigns/performance/types/performance.ts';

export const mockedAllFormatsReportingData: CampaignPerformanceAllType = {
  summary: {
    impressions: 1716096,
    uniqueDevices: 924965,
    frequency: 1.855309119804533,
  },
  impressionsGraph: {
    dates: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [214618, 170768, 170236, 130425, 191432, 84950, 163965, 239784, 38563, 46651, 236689, 28015],
  },
  uniqueDevicesGraph: {
    dates: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [190625, 305684, 418714, 483342, 585540, 625341, 713095, 821756, 830638, 843640, 921840, 924965],
  },
  frequencyGraph: {
    dates: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [
      1.1258649180327869, 1.2607333062901558, 1.326972587494089, 1.4193821352168858, 1.498580797212829,
      1.5390466961225955, 1.5795847678079358, 1.6625105262389321, 1.691159084944344, 1.7203925845147219,
      1.8312082357025081, 1.855309119804533,
    ],
  },
};

export const mockedGeneralReportingData: CampaignPerformanceGeneralType = {
  summary: {
    impressions: 1716096,
    uniqueDevices: 924965,
    frequency: 1.855309119804533,
  },
  impressionsByDayGraph: {
    labels: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [214618, 170768, 170236, 130425, 191432, 84950, 163965, 239784, 38563, 46651, 236689, 28015],
  },
  impressionsByHourGraph: {
    labels: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [214618, 170768, 170236, 130425, 191432, 84950, 163965, 239784, 38563, 46651, 236689, 28015],
  },
  uniqueDevicesGraph: {
    labels: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [190625, 305684, 418714, 483342, 585540, 625341, 713095, 821756, 830638, 843640, 921840, 924965],
  },
  frequencyGraph: {
    labels: [
      '2022-04-04',
      '2022-04-05',
      '2022-04-06',
      '2022-04-07',
      '2022-04-08',
      '2022-04-09',
      '2022-04-10',
      '2022-04-11',
      '2022-04-12',
      '2022-04-13',
      '2022-04-14',
      '2022-04-15',
    ],
    values: [
      1.1258649180327869, 1.2607333062901558, 1.326972587494089, 1.4193821352168858, 1.498580797212829,
      1.5390466961225955, 1.5795847678079358, 1.6625105262389321, 1.691159084944344, 1.7203925845147219,
      1.8312082357025081, 1.855309119804533,
    ],
  },
};

export const mockedVideoFormatReportingData: CampaignPerformanceVideoType = {
  summary: {
    impressions: 354680,
  },
  impressionsGraph: {
    dates: ['2022-06-19', '2022-06-20', '2022-06-21', '2022-06-22', '2022-06-23', '2022-06-24', '2022-06-25'],
    values: [75793, 56394, 55517, 51276, 28208, 50914, 36578],
  },
};

export const mockedDisplayFormatReportingData: CampaignPerformanceDisplayType = {
  summary: {
    actions: 1049,
    impressions: 728235,
    visits: 1050,
    timeSpentMinutes: 22.885019083969464,
    activationPercentage: 0.13045239517463456,
    engagement: 0.9988938053097345,
  },
  impressionsGraph: {
    dates: [
      '2022-05-30',
      '2022-05-31',
      '2022-06-01',
      '2022-06-02',
      '2022-06-03',
      '2022-06-04',
      '2022-06-05',
      '2022-06-06',
    ],
    values: [169274, 79654, 102031, 95671, 112235, 64211, 56506, 48653],
  },
  actionsGraph: {
    dates: [
      '2022-05-30',
      '2022-05-31',
      '2022-06-01',
      '2022-06-02',
      '2022-06-03',
      '2022-06-04',
      '2022-06-05',
      '2022-06-06',
    ],
    actions: [160, 107, 108, 171, 128, 107, 92, 76],
    engagement: [1, 1, 1, 1, 0.9915966386554622, 1, 1, 1],
  },
  visitsGraph: {
    dates: [
      '2022-05-30',
      '2022-05-31',
      '2022-06-01',
      '2022-06-02',
      '2022-06-03',
      '2022-06-04',
      '2022-06-05',
      '2022-06-06',
    ],
    visits: [160, 107, 108, 171, 129, 107, 92, 76],
    activation: [
      0.09452130864751823, 0.13433098149496572, 0.10585018278758417, 0.1787375484734141, 0.11493740811689758,
      0.16663811496472566, 0.16281456836442149, 0.1562082502620599,
    ],
  },
};

export const mockedImpressionsDistributionData: ImpressionsDistributionData = {
  screen: [
    {
      label: 'Digital',
      valuePercentage: 40,
      metrics: {
        impressions: 73637,
        clicks: 6471,
        ctr: 8.37,
        vtr: 0.08,
      },
    },
    {
      label: 'CTV',
      valuePercentage: 60,
      metrics: {
        impressions: 54365,
        vtr: 4.26,
      },
    },
  ],
  format: [
    {
      label: 'Display',
      valuePercentage: 35,
      metrics: {
        impressions: 947055,
        clicks: 6471,
        ctr: 0.07,
        actions: 6671,
        engagement: 1,
        timeSpentSeconds: 23000,
      },
    },
    {
      label: 'Video',
      valuePercentage: 65,
      metrics: {
        impressions: 353721,
        vtr: 0.0006,
      },
    },
  ],
  adType: [
    {
      label: 'Digital VOD',
      valuePercentage: 40,
      metrics: {
        impressions: 947055,
        clicks: 6471,
        ctr: 17.5,
        vtr: 77.3,
      },
    },
    {
      label: 'CTV VOD',
      valuePercentage: 25,
      metrics: {
        impressions: 353721,
        vtr: 66.3,
      },
    },
    {
      label: 'CTV Linear addressable',
      valuePercentage: 35,
      metrics: {
        impressions: 747753,
        vtr: 91.4,
      },
    },
  ],
};

export const mockedTopChartsData: TopChartsData = {
  provinces: {
    labels: [
      'Agrigento',
      'Alessandria',
      'Ancona',
      'Aosta',
      'Arezzo',
      'Ascoli Piceno',
      'Asti',
      'Avellino',
      'Bari',
      'Barletta-Andria-Trani',
      'Belluno',
      'Benevento',
      'Bergamo',
      'Biella',
      'Bologna',
      'Bolzano',
      'Brescia',
      'Brindisi',
      'Cagliari',
      'Caltanissetta',
      'Campobasso',
      'Caserta',
      'Catania',
      'Catanzaro',
      'Chieti',
      'Como',
      'Cosenza',
      'Cremona',
      'Crotone',
      'Cuneo',
      'Enna',
      'Fermo',
      'Ferrara',
      'Florence',
      'Foggia',
      'Forlì-Cesena',
      'Frosinone',
      'Genoa',
      'Gorizia',
      'Grosseto',
      'Imperia',
      'Isernia',
      'L’Aquila',
      'La Spezia',
      'Latina',
      'Lecce',
      'Lecco',
      'Livorno',
      'Lodi',
      'Lucca',
      'Macerata',
      'Mantua',
      'Massa and Carrara',
      'Matera',
      'Messina',
      'Milan',
      'Modena',
      'Monza and Brianza',
      'Naples',
      'Novara',
      'Nuoro',
      'Oristano',
      'Padua',
      'Palermo',
      'Parma',
      'Pavia',
      'Perugia',
      'Pesaro and Urbino',
      'Pescara',
      'Piacenza',
      'Pisa',
      'Pistoia',
      'Pordenone',
      'Potenza',
      'Prato',
      'Ragusa',
      'Ravenna',
      'Reggio Calabria',
      'Reggio Emilia',
      'Rieti',
      'Rimini',
      'Rome',
      'Rovigo',
      'Salerno',
      'Sassari',
      'Savona',
      'Siena',
      'Sondrio',
      'Syracuse',
      'Taranto',
      'Teramo',
      'Terni',
      'Trapani',
      'Trento',
      'Treviso',
      'Trieste',
      'Turin',
      'Udine',
      'Varese',
      'Venice',
      'Verbano-Cusio-Ossola',
      'Vercelli',
      'Verona',
      'Vibo Valentia',
      'Vicenza',
      'Viterbo',
    ],
    values: [
      1.84, 1.37, 1.37, 1.31, 1.3, 1.25, 1.25, 1.22, 1.2, 1.2, 1.15, 1.14, 1.14, 1.12, 1.11, 1.11, 1.1, 1.1, 1.1, 1.06,
      1.05, 1.05, 1.05, 1.04, 1.03, 1.02, 1.02, 1.01, 1.0, 1.0, 1.0, 0.99, 0.97, 0.97, 0.95, 0.95, 0.94, 0.94, 0.94,
      0.94, 0.93, 0.92, 0.91, 0.91, 0.9, 0.89, 0.88, 0.87, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.84, 0.84,
      0.83, 0.82, 0.82, 0.82, 0.81, 0.81, 0.81, 0.8, 0.79, 0.79, 0.79, 0.78, 0.78, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77,
      0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.76, 0.76, 0.76, 0.76, 0.75, 0.75, 0.69, 0.69, 0.69, 0.69,
      0.69, 0.69, 0.59, 0.59, 0.54, 0.4, 0.3, 0.25, 0.15, 0.1,
    ],
  },
  regions: {
    labels: [
      'Lombardia',
      'Lazio',
      'Campania',
      'Veneto',
      'Sicily',
      'Emilia-Romagna',
      'Piedmont',
      'Apulia',
      'Tuscany',
      'Calabria',
      'Sardinia',
      'Liguria',
      'Marche',
      'Abruzzo',
      'Friuli-Venezia G.',
      'Trentino-South T.',
      'Umbria',
      'Basilicata',
      'Molise',
      'Aosta Valley',
    ],
    values: [
      17.8, 16.1, 12.2, 11.2, 9.7, 9.5, 8.6, 8.2, 7.2, 6.8, 5.9, 5.2, 5.1, 4.8, 3.9, 3.67, 3.45, 2.23, 1.82, 1.32,
    ],
  },
  genres: {
    labels: [
      'Cartoons',
      'Documentary',
      'Science Fiction',
      'Drama',
      'News',
      'Series TV',
      'Sport',
      'Comedy',
      'Soap opera',
      'Crime movies',
    ],
    values: [15.8, 12.2, 9.7, 8.6, 7.2, 7.0, 7.0, 5.6, 4.2, 1.8],
  },
};
