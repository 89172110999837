import cn from 'classnames';
import { Avatar } from 'primereact/avatar';

import styles from './HeroSection.module.scss';

export function HeroSection() {
  return (
    <div className={styles.wrapper}>
      <div className={cn('content-wrapper', styles.innerWrapper)}>
        <Avatar className={styles.avatarWrapper} icon='pi pi-user' shape={'circle'} />
      </div>
    </div>
  );
}
