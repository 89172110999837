import styles from './PreviewDefaultWrapper.module.scss';

interface PreviewDefaultWrapperProps {
  imageUrl: string;
}
export function PreviewDefaultWrapper({ imageUrl }: PreviewDefaultWrapperProps) {
  return (
    <div className={styles.container}>
      <img src={imageUrl} alt='landing image preview' />
    </div>
  );
}
