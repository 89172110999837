import { Dispatch } from 'react';

import { apiRoutes } from '@features/users/list/consts/apiRoutes';
import { ExternalUser } from '@features/users/list/types/externalUser.ts';
import { Actions, ExternalUsersListReducerAction } from '@features/users/list/types/externalUsersListReducer.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';

interface FetchCampaignsParams {
  searchQuery?: string;
  dispatch: Dispatch<ExternalUsersListReducerAction>;
}

export async function fetchExternalUsers({
  searchQuery,
  dispatch,
}: FetchCampaignsParams): Promise<AdManagerApiResponse<ExternalUser[]> | null> {
  return await apiClient
    .get<AdManagerApiResponse<ExternalUser[]>>(apiRoutes.getExternalUsers(), {
      search: searchQuery || undefined,
    })
    .then((data) => {
      dispatch({ type: Actions.CHANGE_USERS_COUNT, payload: { usersCount: data?.items.length || 0 } });
      return data;
    })
    .catch((error) => {
      dispatch({ type: Actions.CHANGE_USERS_COUNT, payload: { usersCount: 0 } });
      throw error;
    });
}
