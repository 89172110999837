import { LocalStorageKeys } from '@shared/consts/localStorageKeys';

const useUserLanguage = (): string | null => {
  const userLanguage = localStorage.getItem(LocalStorageKeys.LANGUAGE);

  return userLanguage;
};

const setUserLanguage = (language: string) => {
  localStorage.setItem(LocalStorageKeys.LANGUAGE, language);
};

export { useUserLanguage, setUserLanguage };
