import cn from 'classnames';
import { locale } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import { PanelMenu } from 'primereact/panelmenu';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@features/auth/hooks/useAuth';
import { i18nNameSpace } from '@shared/consts/i18n';
import { setUserLanguage, useUserLanguage } from '@shared/hooks/useLanguage';
import { Language, languages } from '@shared/utils/languages';

import styles from './UserProfile.module.scss';

interface UserProfileProps {
  isSidebarCollapsed: boolean;
}

function UserProfile({ isSidebarCollapsed }: UserProfileProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [language, setLanguage] = useState<Language>();
  const [userName, setUserName] = useState<string>('');
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);

  const menu = useRef<PanelMenu>(null);
  const languageMenu = useRef<PanelMenu>(null);
  const toggleMenuButton = useRef<HTMLDivElement>(null);
  const userNameSpan = useRef<HTMLSpanElement>(null);

  const { t, i18n } = useTranslation(i18nNameSpace.COMMON);
  const userLanguage = useUserLanguage();
  const { logout, getPersonalInfo } = useAuth();

  const setAppLanguage = (language: Language) => {
    setLanguage(language);
    locale(language.locale);
    void i18n.changeLanguage(language.locale);
    setUserLanguage(language.locale);
  };

  useEffect(() => {
    (async () => {
      const name = await getPersonalInfo();
      setUserName(name || '');
    })();

    if (userLanguage) {
      const selectedUserLanguage = languages.find((language) => {
        return language.locale === userLanguage;
      });

      if (selectedUserLanguage) {
        setAppLanguage(selectedUserLanguage);
      }
    } else {
      const defaultLanguage = languages.find((language) => {
        return language.locale === i18n.language;
      });

      if (defaultLanguage) {
        setAppLanguage(defaultLanguage);
      }
    }
  }, []);

  useEffect(() => {
    if (isSidebarCollapsed && isMenuOpen) {
      toggleMenuButton.current?.click();
    }
  }, [isSidebarCollapsed, isMenuOpen]);

  useEffect(() => {
    if (!isMenuOpen) {
      setIsLanguageMenuVisible(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const currentUserNameSpan = userNameSpan.current;
    if (currentUserNameSpan) {
      setIsEllipsisActive(currentUserNameSpan.offsetWidth < currentUserNameSpan.scrollWidth);
    }
  }, [userName]);

  const changeLanguage = (e: ListBoxChangeEvent) => {
    if (e.value) {
      setAppLanguage(e.value);
    }
  };

  const languageTemplate = (option: Language) => {
    return (
      <div className={styles.languageItemWrapper}>
        <span className={styles.languageOptionName}>
          <img src={option.flagIcon} className={styles.languageFlagIcon} />
          {t(`userProfile.${option.name.toLowerCase()}Language`)}
        </span>
        {option === language && <i className={cn('pi pi-check', styles.selectedLanguageIcon)}></i>}
      </div>
    );
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuVisible(!isLanguageMenuVisible);
  };

  const languageList = [
    {
      template: () => {
        return (
          <ListBox
            data-testid='languageListBox'
            value={language}
            onChange={(e) => changeLanguage(e)}
            options={languages}
            optionLabel='name'
            itemTemplate={languageTemplate}
            className={styles.languageListWrapper}
          />
        );
      },
    },
  ];

  const menuList = [
    {
      template: () => {
        return (
          <>
            <Button
              data-testid={'toggle-language-menu-button'}
              text
              onClick={toggleLanguageMenu}
              className={styles.toggleLanguageMenuButton}
            >
              <i className={cn('pi pi-language', styles.languageButton)}></i>
              <span>{t('userProfile.languageButtonText')}</span>
              <i
                className={cn(
                  'pi',
                  isLanguageMenuVisible ? 'pi-chevron-up' : 'pi-chevron-down',
                  styles.languageChevronIcon,
                )}
              ></i>
            </Button>
            <PanelMenu
              data-testid={'language-list'}
              className={cn(
                styles.languageMenuWrapper,
                isLanguageMenuVisible ? styles.languageMenuWrapperVisible : styles.languageMenuWrapperHidden,
              )}
              model={languageList}
              ref={languageMenu}
            />
            <Button className={styles.logoutButton} onClick={logout} data-testid={'logout-button'}>
              <i className={cn('pi pi-sign-out', styles.languageButton)}></i>
              <span>{t('userProfile.logoutButtonText')}</span>
            </Button>
          </>
        );
      },
    },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.toggleMenuButton} onClick={toggleMenu} ref={toggleMenuButton}>
        <Avatar className={styles.avatar} icon='pi pi-user' shape='circle' />
        <Tooltip target='#userProfileName' className={styles.userProfileNameTooltip} showDelay={200} />
        <Button
          id='userProfileName'
          data-testid='user-profile-button'
          data-pr-tooltip={isEllipsisActive ? userName : ''}
          data-pr-position='top'
          data-pr-at='right-50 top-20'
          className={styles.userProfileInfoWrapper}
        >
          <span ref={userNameSpan} className={styles.userProfileName}>
            {userName}
          </span>
          <i className={cn('pi', isMenuOpen ? 'pi-chevron-up' : 'pi-chevron-down')}></i>
        </Button>
      </div>
      <PanelMenu
        data-testid={'language-menu'}
        className={cn(styles.menuWrapper, isMenuOpen ? styles.menuWrapperVisible : styles.menuWrapperHidden)}
        model={menuList}
        ref={menu}
      />
    </div>
  );
}

export default UserProfile;
