import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './SubmitCampaignModal.module.scss';

interface SubmitCampaignModalProps {
  isVisible: boolean;
  toggleModalVisibility: () => void;
  submitCallback: () => void;
  campaignName: string;
  submitCallbackActionIsLoading: boolean;
}

export function SubmitCampaignModal({
  isVisible,
  toggleModalVisibility,
  submitCallback,
  campaignName,
  submitCallbackActionIsLoading,
}: SubmitCampaignModalProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <Dialog
      visible={isVisible}
      onHide={toggleModalVisibility}
      draggable={false}
      resizable={false}
      header={t('builder.modals.headers.submitCampaign')}
      className={styles.maxWidth}
      footer={
        <DefaultActionsFooter
          submitLabel={t('builder.modals.yesContinue')}
          cancelLabel={t('builder.modals.cancel')}
          cancelCallback={toggleModalVisibility}
          submitCallback={submitCallback}
          submitCallbackActionIsLoading={submitCallbackActionIsLoading}
        />
      }
    >
      {t('builder.modals.content.campaignSubmissionQuestion')}: <strong className={styles.bold}>{campaignName}</strong>?
    </Dialog>
  );
}
