import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { PossibleStepNumber } from '@features/campaigns/builder/utils/transformCampaignDetailsToStepData';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import { readCampaignDetails } from '@features/campaigns/shared/services/campaign/read';
import { CAMPAIGN_BUILDER_STEPS } from '@features/campaigns/shared/types/campaign';
import { CampaignDetailsQuery } from '@features/campaigns/shared/types/campaignDetails';
import { CampaignId, IncludeParams } from '@shared/types/campaign';

interface CampaignDetailsRequestParams {
  campaignId: CampaignId;
  campaignStep?: PossibleStepNumber;
  enabled?: boolean;
}

export default function useCampaignDetails({ campaignId, campaignStep, enabled }: CampaignDetailsRequestParams) {
  const { pathname } = useLocation();
  const isCampaignDetailsView = pathname.includes('details') && !pathname.includes('builder');

  const composeQuery = (): CampaignDetailsQuery => {
    const campaignDetailsQuery: CampaignDetailsQuery = { include: undefined };

    if (
      isCampaignDetailsView ||
      campaignStep === CAMPAIGN_BUILDER_STEPS.STEP_04 ||
      campaignStep === CAMPAIGN_BUILDER_STEPS.STEP_05
    ) {
      campaignDetailsQuery.include = IncludeParams.PRICING;
    }

    return campaignDetailsQuery;
  };

  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_DETAILS, campaignId, campaignStep],
    queryFn: () => readCampaignDetails(campaignId, composeQuery()),
    gcTime: 0,
    enabled,
  });
}
