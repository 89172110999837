import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes';
import { mockedAgenciesData } from '@shared/mocks/requests/agencies/agencies.fixtures.ts';

export const agenciesRequests = [
  http.get(`*${apiRoutes.getAgencies()}`, () => {
    return HttpResponse.json(mockedAgenciesData);
  }),
];
