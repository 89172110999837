import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import config from './config.ts';

if (config.SENTRY_ENABLED === 'ACTIVE') {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: config.SENTRY_RELEASE,
    environment: config.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: parseFloat(config.SENTRY_ERROR_REPLAYS_SAMPLE_RATE || '0'),
    tracePropagationTargets: config.API_URL ? [config.API_URL] : [],
    tracesSampleRate: parseFloat(config.SENTRY_TRACES_SAMPLE_RATE || '0'),
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}
