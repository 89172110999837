import cn from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './Stepper.module.scss';

interface StepperProps {
  activeIndex: number;
}

function Stepper({ activeIndex }: StepperProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const buildItems = (labels: string[]): MenuItem[] => {
    return labels.map((label, index) => {
      return {
        template: (
          <a
            href='#'
            className={cn('p-menuitem-link', {
              'checked-step': activeIndex > index,
            })}
            role='presentation'
            aria-label={label}
            aria-current={activeIndex === index}
            tabIndex={-1}
            data-pc-section='action'
          >
            <span className='p-steps-number' data-pc-section='step'>
              {index < activeIndex ? <i className='pi pi-check'></i> : index + 1}
            </span>
            <span className='p-steps-title' data-pc-section='label'>
              {label}
            </span>
          </a>
        ),
      };
    });
  };

  const items = buildItems([
    t('builder.steps.campaignDetails'),
    t('builder.steps.target'),
    t('builder.steps.adProducts'),
    t('builder.steps.availabilityAndCosts'),
    t('builder.steps.reviewAndSubmit'),
  ]);

  return (
    <div className={styles.customStepper} data-testid='stepper'>
      <Steps model={items} activeIndex={activeIndex} readOnly={true} />
    </div>
  );
}

export default Stepper;
