import { LazyExoticComponent, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormPageStepProps } from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import {
  PossibleStepNumber,
  transformCampaignDetailsToStepData,
} from '@features/campaigns/builder/utils/transformCampaignDetailsToStepData.ts';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner';
import useCampaignDetails from '@features/campaigns/shared/hooks/useCampaignDetails';
import { AccessRights } from '@shared/api/commonApiInterfaces.ts';
import { PlaceholderCard } from '@shared/components/PlaceholderCard/PlaceholderCard.tsx';
import { WarningMessage } from '@shared/components/Toast/WarningMessage/WarningMessage';
import { i18nNameSpace } from '@shared/consts/i18n';
import { routePaths } from '@shared/router/routePaths';

export function withCampaignSavedData<T>(Component: LazyExoticComponent<(props: FormPageStepProps<T>) => JSX.Element>) {
  return function EnhancedComponent({ campaignStep }: { campaignStep: PossibleStepNumber }) {
    const { t } = useTranslation(i18nNameSpace.API);
    const { campaignId } = useParams();
    const { isFetching, data } = useCampaignDetails({
      campaignId: campaignId!,
      campaignStep,
    });
    const navigate = useNavigate();

    if (!data) {
      return <PlaceholderCard textKey='placeholderLoading' />;
    }

    const canEditCampaign =
      data.accessRights.includes(AccessRights.EDIT_ALL) ||
      data.accessRights.includes(AccessRights.EDIT_BUDGET) ||
      data.accessRights.includes(AccessRights.EDIT_END_DATE) ||
      data.accessRights.includes(AccessRights.EDIT_TARGETING) ||
      data.accessRights.includes(AccessRights.EDIT_BRAND);

    if (!canEditCampaign && data.accessRights.includes(AccessRights.READ)) {
      navigate(routePaths.campaignDetails.url(campaignId || ''));
      toast.warning(<WarningMessage message={t('api.warning.campaign.edit.not_allowed')} />);
      return;
    }

    if (!data.accessRights.includes(AccessRights.READ)) {
      navigate(routePaths.campaigns);
      toast.warning(<WarningMessage message={t('api.warning.campaign.read.not_allowed')} />);
      return;
    }

    const stepSavedData = transformCampaignDetailsToStepData(data, campaignStep);

    return (
      <Suspense fallback={<PlaceholderCard textKey={'placeholderLoading'} />}>
        {isFetching && <Spinner variant='fullScreenOverlay' />}
        {/* // TODO - remove assertion stepSavedData as T in https://clearcodehq.atlassian.net/browse/PBLTL-648 */}
        <Component
          campaignId={campaignId as string}
          campaignType={data.type}
          mediaType={data.mediaType}
          stepSavedData={stepSavedData as T}
          accessRights={data.accessRights}
        />
      </Suspense>
    );
  };
}
