import { DropdownItem } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import NameWithCode from '@features/campaigns/shared/components/NameWithCode/NameWithCode.tsx';

export function DropdownItemTemplate({ name, code, disabled = false }: DropdownItem) {
  return (
    <div className='dropdown-item-template-wrapper'>
      <span>
        <NameWithCode name={name} code={code} disabled={disabled} />
      </span>
      <span className='item-icon pi pi-check' />
    </div>
  );
}
