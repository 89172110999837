import { AxiosError } from 'axios';
import { t } from 'i18next';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';

import { i18nNameSpace } from '@shared/consts/i18n';
import { ErrorStatus } from '@shared/utils/errorStatus';
import { ApiError } from '@shared/utils/interfaces/error';

type ErrorFieldPath<T> = `root.${string}` | 'root' | Path<T>;

const displayApiErrorsOnFormFields = <T extends FieldValues>(setError: UseFormSetError<T>, error: unknown) => {
  const axiosError = error as AxiosError<ApiError>;

  if (axiosError) {
    const apiError = axiosError.response?.data;

    if (apiError?.status === ErrorStatus.VALIDATION_ERROR) {
      apiError.details?.forEach((errorDetail) => {
        setError(errorDetail.fieldPath as ErrorFieldPath<T>, {
          type: 'manual',
          message: t(errorDetail.i18n.key, {
            ...errorDetail.i18n.props,
            ns: i18nNameSpace.API,
          }),
        });
      });
    }
  }
};

export { displayApiErrorsOnFormFields };
