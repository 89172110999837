import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes.ts';
import { BillingDetailsValues } from '@features/campaigns/details/containers/BillingDetails/BillingDetails.types.ts';
import { Agency, BillingContact } from '@features/campaigns/shared/types/campaign.ts';

export interface BillingDetailsData {
  agency: Agency | null;
  advertiserId: string;
  validFromDate: string;
  validToDate: string;
  billingContact: BillingContact | null;
  purchaseOrder: string | null;
  authorizedBudget?: number | null;
  createdBy: string;
}

export interface BillingDetailsPayload {
  agencyId?: string;
  billingContactId?: string;
  purchaseOrder?: string;
}

export interface AuthorizedBudgetPayload {
  authorizedBudget: number;
}

export function restoreBillingDetailsFormValues({
  agency,
  billingContact,
  purchaseOrder,
  authorizedBudget,
}: BillingDetailsData): BillingDetailsValues {
  return {
    [BillingFormFieldTypes.AGENCY]: agency?.id,
    [BillingFormFieldTypes.BILLING_CONTACT]: billingContact ? billingContact.id : '',
    [BillingFormFieldTypes.PURCHASE_ORDER]: purchaseOrder || undefined,
    [BillingFormFieldTypes.AUTHORIZED_BUDGET]: authorizedBudget || undefined,
  };
}
