import { useQuery } from '@tanstack/react-query';
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import ActionsColumnBodyTemplate from '@features/users/list/components/Table/components/columns/ActionsColumnBodyTemplate/ActionsColumnBodyTemplate';
import EmailColumnBodyTemplate from '@features/users/list/components/Table/components/columns/EmailColumnBodyTemplate/EmailColumnBodyTemplate';
import NameColumnBodyTemplate from '@features/users/list/components/Table/components/columns/NameColumnBodyTemplate/NameColumnBodyTemplate';
import RoleColumnBodyTemplate from '@features/users/list/components/Table/components/columns/RoleColumnBodyTemplate/RoleColumnBodyTemplate';
import SurnameColumnBodyTemplate from '@features/users/list/components/Table/components/columns/SurnameColumnBodyTemplate/SurnameColumnBodyTemplate';
import { EmptyState } from '@features/users/list/components/Table/components/EmptyState/EmptyState.tsx';
import { QUERY_KEYS } from '@features/users/list/consts/queryKeys.ts';
import { fetchExternalUsers } from '@features/users/list/services/listExternalUsers.ts';
import { ExternalUser } from '@features/users/list/types/externalUser';
import { ReducerProps } from '@features/users/list/types/externalUsersListReducer';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '@shared/components/Table/consts/table';
import commonStyles from '@shared/components/Table/Table.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './ExternalUsersListTable.module.scss';

interface ExternalUsersListTableProps extends ReducerProps {
  filters?: DataTableFilterMeta;
}

function ExternalUsersListTable({ state, dispatch, filters }: ExternalUsersListTableProps) {
  const { t } = useTranslation(i18nNameSpace.USERS);
  const isSearchingByEmail = !!state.search;

  const { isLoading, data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.EXTERNAL_USERS_LIST, state.search, dispatch],
    queryFn: () => fetchExternalUsers({ searchQuery: state.search, dispatch }),
  });

  const dataIsLoading = isLoading || isFetching;

  if (dataIsLoading) {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  }

  if (!data || data?.items?.length === 0) {
    return <EmptyState isFiltering={isSearchingByEmail} />;
  }

  return (
    <DataTable
      alwaysShowPaginator={false}
      data-testid='external-users-list-table'
      className={commonStyles.wrapper}
      value={data?.items || []}
      first={state.first}
      rows={state.limit}
      totalRecords={state.usersCount}
      filters={filters}
      globalFilterFields={['givenName', 'surname', 'mail']}
      emptyMessage={<EmptyState isFiltering={isSearchingByEmail} />}
      dataKey='id'
      paginator
      paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown items per page'
      currentPageReportTemplate={t('page.list.paginator.summary')}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      removableSort
    >
      <Column
        className={styles.nameColumn}
        field='givenName'
        header={t('page.list.columns.name')}
        sortable
        body={NameColumnBodyTemplate}
      />
      <Column
        className={styles.surnameColumn}
        field='surname'
        header={t('page.list.columns.surname')}
        sortable
        body={SurnameColumnBodyTemplate}
      />
      <Column
        className={styles.emailColumn}
        field='mail'
        header={t('page.list.columns.email')}
        sortable
        body={EmailColumnBodyTemplate}
      />
      <Column
        className={styles.roleColumn}
        field='role'
        header={t('page.list.columns.role')}
        body={RoleColumnBodyTemplate}
      />
      <Column
        className={styles.actionsColumn}
        field='actions'
        body={(user: ExternalUser) => ActionsColumnBodyTemplate({ user })}
      />
    </DataTable>
  );
}

export default ExternalUsersListTable;
