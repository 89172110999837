import { useTranslation } from 'react-i18next';

import { Icon } from '@shared/components/Icon/Icon.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './ChartNoData.module.scss';

export function ChartNoData() {
  const { t } = useTranslation(i18nNameSpace.COMMON);

  const noDataText = t('noData');

  return (
    <div className={styles.wrapper}>
      <Icon variant='closeGray' iconAltText={noDataText} stylesClassName='emptyStateIcon' />
      <p className={styles.text} data-testid='chart-no-data'>
        {noDataText}
      </p>
      <span className={styles.description} data-testid='chart-no-data-description'>
        {t('noDataDescription')}
      </span>
    </div>
  );
}
