import { TFunction } from 'i18next';

import { CampaignStatuses } from '@features/campaigns/shared/types/campaign';
import { I18nNameSpaces } from '@shared/consts/i18n';
import { objectKeys } from '@shared/utils/objectKeys';

export const getStatusOptions = (t: TFunction<I18nNameSpaces['CAMPAIGNS']>) => {
  const campaignStatusKeysSortedAlphabetically = objectKeys(CampaignStatuses).sort();

  const statusOptions = campaignStatusKeysSortedAlphabetically.map((campaignStatusKey) => ({
    label: t(`page.list.campaignStatus.${campaignStatusKey.toLowerCase()}`),
    value: CampaignStatuses[campaignStatusKey],
  }));

  return statusOptions;
};
