import { CampaignId } from '@shared/types/campaign.ts';

export const apiRoutes = {
  downloadPptxPerformancePresentation: (campaignId: CampaignId) => `/campaigns/${campaignId}/performance-presentation`,
  downloadXlsxPerformancePresentation: (campaignId: CampaignId) => `/campaigns/${campaignId}/performance-xlsx`,
  getCampaignPerformanceDisplayType: (campaignId: CampaignId) => `/reporting/campaign/${campaignId}/display`,
  getCampaignPerformanceVideoType: (campaignId: CampaignId) => `/reporting/campaign/${campaignId}/video`,
  getCampaignPerformanceAllType: (campaignId: CampaignId) => `/reporting/campaign/${campaignId}/all`,
  getCampaignReportingGeneral: (campaignId: CampaignId) => `/reporting/campaign/${campaignId}/general`,
  getCampaignTopCharts: (campaignId: CampaignId) => `/reporting/campaign/${campaignId}/top`,
  getCampaignImpressionsDistribution: (campaignId: CampaignId) =>
    `/reporting/campaign/${campaignId}/impressions-distribution`,
};
