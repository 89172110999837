import cn from 'classnames';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricChartValueType } from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { getMetricTypeButtonsOptions } from '@features/campaigns/performance/components/MetricTypeButtons/utils/optionsToSelect.ts';
import SelectButtonItemTemplate from '@features/campaigns/performance/components/shared/components/SelectButtonItemTemplate.tsx';
import selectButtonStyles from '@features/campaigns/performance/components/shared/styles/SelectButton.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface MetricTypeButtonsProps {
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<MetricChartValueType>>;
}

function MetricTypeButtons({ selectedValue, setSelectedValue }: MetricTypeButtonsProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const onChangeHandler = (e: SelectButtonChangeEvent) => {
    setSelectedValue(e.value);
  };

  const options = getMetricTypeButtonsOptions(t);

  return (
    <SelectButton
      options={options}
      unselectable={false}
      value={selectedValue}
      onChange={(e) => onChangeHandler(e)}
      optionLabel='label'
      itemTemplate={(props) => <SelectButtonItemTemplate {...props} />}
      className={cn(
        selectButtonStyles.selectButtonWrapper,
        options.length === 1 && selectButtonStyles.singleSelectButtonWrapper,
      )}
      data-testid={'chart-type-select-buttons'}
    />
  );
}

export default MetricTypeButtons;
