const getMoneyNumberFormat = (minFractionDigits: number) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'EUR',
    style: 'currency',
    minimumFractionDigits: minFractionDigits,
  });
};

export function formatNumberAsMoney(n: number, minFractionDigits: number = 0) {
  return getMoneyNumberFormat(minFractionDigits)
    .format(n)
    .replace(/^(\D+)/, '$1 ');
}
