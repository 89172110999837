import type { PointStyle, TooltipItem, TooltipModel } from 'chart.js';
import { format } from 'date-fns';
import { TFunction } from 'i18next';

import {
  CampaignPerformanceGeneralType,
  MetricsGraphData,
  MetricChartValue,
  MetricChartValueType,
} from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { commonChartTooltipStyles } from '@features/campaigns/performance/shared/styles/commonChartTooltipStyles.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import {
  formatNumbersAsCompactNumbers,
  formatNumberWithCommas,
  formatNumberWithMinOneDigitAfterSeparator,
} from '@shared/utils/numberFormat.ts';
import { objectKeys } from '@shared/utils/objectKeys.ts';

interface DataWithDates {
  labels: string[];
}

export const formatDates = (data: CampaignPerformanceGeneralType) => {
  if (!data) {
    return null;
  }

  objectKeys(data).forEach((key) => {
    if ('labels' in data[key] && key !== 'impressionsByHourGraph') {
      const dataWithDates = data[key] as DataWithDates;

      dataWithDates.labels = dataWithDates.labels.map((date) => format(new Date(date), 'dd-MM-yyyy'));
    }

    if ('labels' in data[key] && key === 'impressionsByHourGraph') {
      const dataWithHours = data[key] as DataWithDates;

      dataWithHours.labels = dataWithHours.labels.map((date) => `${date}:00`);
    }
  });

  return data;
};

const commonGraphStyles = {
  pointRadius: 6,
  pointBorderWidth: 3,
  pointBorderColor: 'rgb(255, 255, 255)',
  borderWidth: 2,
  tension: 0.5,
};

export const getLineChartOptions = (selectedChartType: MetricChartValueType) => {
  return {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2.75 / 1,
    interaction: {
      mode: 'index' as const,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: { family: 'Inter-Variable', size: 14, weight: 500 },
          color: '#495057',
          padding: 30,
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
        },
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>) {
            const label = tooltipItem.dataset.label;
            const value =
              typeof tooltipItem.raw === 'number' ? formatNumberWithCommas(tooltipItem.raw) : tooltipItem.raw;

            return `${label}: ${value}`;
          },
          labelTextColor: () => '#fff',
          labelPointStyle: () => ({
            pointStyle: 'circle' as PointStyle,
            rotation: 0,
          }),
          labelColor: (tooltipItem: TooltipItem<'line'>) => {
            return {
              borderWidth: 0,
              backgroundColor: String(tooltipItem.dataset.borderColor),
              borderColor: String(tooltipItem.dataset.borderColor),
            };
          },
        },
        caretPadding: 11,
        ...commonChartTooltipStyles,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          crossAlign: 'far' as const,
          color: '#818A92',
          padding: 10,
          callback: (value: string | number) => {
            const properFormatNumberFunction =
              selectedChartType === MetricChartValue.IMPRESSIONS || selectedChartType === MetricChartValue.DEVICES
                ? formatNumbersAsCompactNumbers
                : formatNumberWithMinOneDigitAfterSeparator;
            const formattedValue = typeof value === 'number' ? properFormatNumberFunction(value) : value;
            const isZero = value == 0;

            return isZero ? 0 : formattedValue;
          },
        },
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
};

const impressionsGraphData = (data: CampaignPerformanceGeneralType, t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  return {
    labels: data?.impressionsByDayGraph.labels,
    datasets: [
      {
        label: t('page.campaignDetails.performanceTab.metricChartLabels.impressions'),
        data: data?.impressionsByDayGraph.values,
        borderColor: 'rgba(168, 85, 247, 1)',
        backgroundColor: 'rgba(168, 85, 247, 1)',
        ...commonGraphStyles,
        fill: {
          target: 'start',
          above: 'rgba(168, 85, 247, 0.1)',
        },
      },
    ],
  };
};

const devicesGraphData = (data: CampaignPerformanceGeneralType, t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  if (data && 'uniqueDevicesGraph' in data) {
    return {
      labels: data?.uniqueDevicesGraph.labels,
      datasets: [
        {
          label: t('page.campaignDetails.performanceTab.metricChartLabels.devices'),
          data: data?.uniqueDevicesGraph.values,
          borderColor: 'rgba(59, 130, 246, 1)',
          backgroundColor: 'rgba(59, 130, 246, 1)',
          ...commonGraphStyles,
          fill: {
            target: 'start',
            above: 'rgba(59, 130, 246, 0.1)',
          },
        },
      ],
    };
  }
};

const frequencyGraphData = (data: CampaignPerformanceGeneralType, t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  if (data && 'frequencyGraph' in data) {
    return {
      labels: data?.frequencyGraph.labels,
      datasets: [
        {
          label: t('page.campaignDetails.performanceTab.metricChartLabels.frequency'),
          data: data?.frequencyGraph.values,
          borderColor: 'rgba(34, 197, 94, 1)',
          backgroundColor: 'rgba(34, 197, 94, 1)',
          ...commonGraphStyles,
          fill: {
            target: 'start',
            above: 'rgba(34, 197, 94, 0.1)',
          },
        },
      ],
    };
  }
};

export const generateChartDataByDays = (
  data: CampaignPerformanceGeneralType,
  selectedChartType: MetricChartValueType,
  t: TFunction<typeof i18nNameSpace.CAMPAIGNS>,
) => {
  if (selectedChartType === MetricChartValue.IMPRESSIONS) {
    return impressionsGraphData(data, t);
  }

  if (selectedChartType === MetricChartValue.DEVICES) {
    return devicesGraphData(data, t);
  }

  if (selectedChartType === MetricChartValue.FREQUENCY) {
    return frequencyGraphData(data, t);
  }

  return undefined;
};

export const getBarChartOptions = (dateRange: string) => ({
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2.75 / 1,
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        font: { family: 'Inter-Variable', size: 14, weight: 500 },
        color: '#495057',
        padding: 30,
        boxWidth: 12,
        boxHeight: 12,
        useBorderRadius: true,
      },
      display: true,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems: TooltipItem<'bar'>[]) => {
          const timeLabel = tooltipItems[0].label;

          return `${dateRange} at ${timeLabel}`;
        },
        label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
          const label = tooltipItem.dataset.label;
          const value = tooltipItem.raw;

          return `${label}: ${value}%`;
        },
        labelTextColor: () => '#fff',
        labelPointStyle: () => ({
          pointStyle: 'circle' as PointStyle,
          rotation: 0,
        }),
        labelColor: () => {
          return {
            borderWidth: 0,
            backgroundColor: '#A855F7',
            borderColor: '#A855F7',
          };
        },
      },
      caretPadding: 11,
      ...commonChartTooltipStyles,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: '#818A92',
      },
    },
    y: {
      ticks: {
        crossAlign: 'far' as const,
        color: '#818A92',
        padding: 10,
        callback: (value: string | number) => {
          const isZero = value == 0;

          return isZero ? 0 : `${value}%`;
        },
      },
      grid: {
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
  },
});

function convertToPercentageValue(values: number[]) {
  return values.map((value) => value.toFixed(2));
}

export const generateChartDataByHours = (data: MetricsGraphData, t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => ({
  labels: data.labels,
  datasets: [
    {
      label: t('page.campaignDetails.performanceTab.metricChartLabels.impressions', { ns: i18nNameSpace.CAMPAIGNS }),
      data: convertToPercentageValue(data.values),
      backgroundColor: '#A855F7',
      borderRadius: 6,
      barThickness: 20,
    },
  ],
});
