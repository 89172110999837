import { Outlet } from 'react-router-dom';

import Sidebar from '@shared/components/Sidebar/Sidebar.tsx';

import styles from './AppLayout.module.scss';

function AppLayout() {
  return (
    <div className={styles.layoutWrapper}>
      <Sidebar />
      <div className={styles.contentWrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default AppLayout;
