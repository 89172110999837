const commaSeparatedNumberFormat = new Intl.NumberFormat('en-US');
const compactNumbersFormat = new Intl.NumberFormat('en-US', { notation: 'compact' });
const numberWithMaxTwoDigits = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
const numberWithMinOneDigit = new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 });

export function formatNumberWithCommas(n: number) {
  return commaSeparatedNumberFormat.format(n);
}

export function formatNumbersAsCompactNumbers(n: number) {
  return compactNumbersFormat.format(n);
}

export function formatNumberWithMaxTwoDigitsAfterSeparator(n: number) {
  return numberWithMaxTwoDigits.format(n);
}

export function formatNumberWithMinOneDigitAfterSeparator(n: number) {
  return numberWithMinOneDigit.format(n);
}
