import { Item } from '@features/campaigns/builder/services/formFields';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { STATUS } from '@shared/types/common';

export const mockedAdvertisersData: AdManagerApiResponse<Item[]> = {
  items: [
    {
      id: 'acff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Advertiser #1',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: false,
      code: '12112',
    },
    {
      id: 'bcff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Advertiser #2',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: false,
      code: '12212',
    },
    {
      id: 'ccff7517-f89e-48e8-aca2-d06d34df298a',
      name: 'Advertiser #3',
      status: STATUS.ACTIVE,
      isPreliminaryBlocked: true,
      code: '12312',
    },
  ],
  meta: {
    currentPage: 1,
    itemCount: 3,
    itemsPerPage: 3,
    totalItems: 3,
    totalPages: 1,
  },
};
