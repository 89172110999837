import { Nullable } from 'primereact/ts-helpers';

import { CampaignFormat, CampaignStatus } from '@features/campaigns/shared/types/campaign';
import { ValueOf } from '@shared/types/common';

export const SortFields = {
  NAME: 'name',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  BUDGET: 'budgets',
} as const;

export const SortOrders = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export const DataTableSortOrders = {
  '-1': -1,
  1: 1,
  0: 0,
} as const;

export type DataTableSortOrder = ValueOf<typeof DataTableSortOrders> | undefined | null;
export type SortField = ValueOf<typeof SortFields> | undefined;
export type SortOrder = ValueOf<typeof SortOrders> | undefined;

export interface DataTableState {
  first: number;
  limit: number;
  page: number;
  sortField?: SortField;
  sortOrder?: DataTableSortOrder;
  search?: string;
}

export interface DataTableStateWithFilters extends DataTableState {
  filters: {
    format?: CampaignFormat[];
    status?: CampaignStatus[];
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
  };
}
