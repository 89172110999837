import { BubbleDataPoint, Chart as ChartJS, ChartTypeRegistry, Point, ScriptableContext } from 'chart.js';

import { ValueOf } from '@shared/types/common';

export type BeforeDrawChartParam = ChartJS<
  keyof ChartTypeRegistry,
  (number | [number, number] | Point | BubbleDataPoint | null)[],
  unknown
>;

export const ImpressionsDistributionTypes = {
  SCREEN: 'screen',
  FORMAT: 'format',
  AD_TYPE: 'adType',
} as const;

export type ImpressionsDistributionType = ValueOf<typeof ImpressionsDistributionTypes>;

type AdTypeImpressionsDistribution =
  | {
      label: 'Digital VOD';
      valuePercentage: number;
      metrics: DigitalMetrics;
    }
  | {
      label: 'CTV Linear addressable';
      valuePercentage: number;
      metrics: VideoMetrics;
    }
  | {
      label: 'CTV VOD';
      valuePercentage: number;
      metrics: VideoMetrics;
    }
  | {
      label: 'CTV Display';
      valuePercentage: number;
      metrics: DisplayMetrics;
    };

type FormatImpressionsDistribution =
  | {
      label: 'Display';
      valuePercentage: number;
      metrics: DisplayMetrics;
    }
  | {
      label: 'Video';
      valuePercentage: number;
      metrics: VideoMetrics;
    };

type ScreenImpressionsDistribution =
  | {
      label: 'Digital';
      valuePercentage: number;
      metrics: DigitalMetrics;
    }
  | {
      label: 'CTV';
      valuePercentage: number;
      metrics: VideoMetrics | undefined;
    };

export interface ImpressionsDistributionData {
  screen: ScreenImpressionsDistribution[];
  format: FormatImpressionsDistribution[];
  adType: AdTypeImpressionsDistribution[];
}

export interface Metric {
  label: `page.campaignDetails.performanceTab.keyMetrics.${string}`;
  value?: number;
  isPercentageValue?: boolean;
  isTimeSpent?: boolean;
  isEngagement?: boolean;
}

export interface DisplayMetrics {
  impressions: number;
  clicks: number;
  ctr: number;
  actions: number;
  engagement?: number;
  timeSpentSeconds: number;
}

export interface VideoMetrics {
  impressions: number;
  vtr: number;
}

export interface DigitalMetrics {
  clicks: number;
  ctr: number;
  impressions: number;
  vtr: number;
}

export interface FormatMetricsData {
  display: Metric[];
  video: Metric[];
}

export interface ScreenMetricsData {
  digital: Metric[];
  ctv: Metric[];
}

export interface AdTypeMetricsData {
  /* cSpell:disable */
  digitalvod: Metric[];
  ctvdisplay: Metric[];
  ctvvod: Metric[];
  ctvlinearaddressable: Metric[];
  /* cSpell:enable */
}

export type MetricsData = FormatMetricsData | ScreenMetricsData | AdTypeMetricsData;

export const chartColors = ['#3B82F6', '#A855F7', '#22C55E'] as const;

type HoverHandler = (context: ScriptableContext<'doughnut'>) => ValueOf<typeof chartColors>;

interface Dataset {
  data: number[];
  backgroundColor: typeof chartColors;
  hoverOffset: number;
  hoverBackgroundColor: HoverHandler;
  hoverBorderColor: HoverHandler;
  borderColor: HoverHandler;
  title: string;
}

export interface ChartData {
  labels: string[];
  datasets: Dataset[];
}
