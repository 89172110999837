import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/shared/consts/apiRoutes';
import { mockedCampaignDetails } from '@shared/mocks/requests/campaignDetails/campaignDetails.fixtures';

export const campaignDeleteRequests = [
  http.delete(`*${apiRoutes.deleteCampaign(':campaignId')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
];
