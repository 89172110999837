import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import ActionButtons from '@features/campaigns/details/components/ActionButtons/ActionButtons';
import CampaignDetails from '@features/campaigns/details/components/CampaignDetails/CampaignDetails';
import Performance from '@features/campaigns/performance/containers/Performance/Performance';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner';
import useCampaignDetails from '@features/campaigns/shared/hooks/useCampaignDetails';
import { AccessRights } from '@shared/api/commonApiInterfaces.ts';
import placeholderCommonStyles from '@shared/components/PlaceholderCard/PlaceholderCard.module.scss';
import { PlaceholderCard } from '@shared/components/PlaceholderCard/PlaceholderCard.tsx';
import { i18nNameSpace } from '@shared/consts/i18n';
import { routePaths } from '@shared/router/routePaths';

import { campaignDetailsDTO, CampaignDetailsViewData } from './CampaignDetailsPage.dto';
import styles from './CampaignDetailsPage.module.scss';

function CampaignDetailsPage() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { pathname } = useLocation();
  const { campaignId } = useParams();
  const isPerformanceView = pathname.includes(routePaths.campaignPerformance.url(campaignId!));
  const activeTabIndex = isPerformanceView ? '1' : '0';
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);

  const { isFetching, data } = useCampaignDetails({ campaignId: campaignId! });
  const setActiveTabHandler = (tabIndex: number) => {
    setActiveIndex(`${tabIndex}`);
  };

  if (!data) {
    return (
      <div className={placeholderCommonStyles.placeholderCardWrapper}>
        <PlaceholderCard textKey='placeholderLoading' />
      </div>
    );
  }

  const campaignDetailsData: CampaignDetailsViewData = campaignDetailsDTO(data);
  const invalidSections = data.invalidSections;
  const areAllRequiredFieldFilledIn = invalidSections.length === 0;
  const shouldDisplayPerformanceTab = campaignDetailsData.accessRights.includes(AccessRights.EXPORT_REPORT);
  const id = campaignDetailsData.billingSequentialId
    ? `${campaignDetailsData.sequentialId} - ${campaignDetailsData.billingSequentialId}`
    : campaignDetailsData.sequentialId;

  return (
    <div className={styles.wrapper}>
      {!isPerformanceView && isFetching && <Spinner variant='fullScreenOverlay' />}
      <div className={styles.contentWrapper}>
        <ActionButtons
          shouldSubmitButtonBeDisabled={!areAllRequiredFieldFilledIn}
          campaignName={campaignDetailsData.campaignDetails.campaignName}
          campaignAccessRights={campaignDetailsData.accessRights}
        />
        <div className={styles.campaignDetailsWrapper}>
          <h2 className={styles.campaignName}>{campaignDetailsData.campaignDetails.campaignName}</h2>
          <p className={styles.campaignSubname}>{id}</p>
        </div>
        <TabView activeIndex={Number(activeIndex)} onTabChange={(e) => setActiveTabHandler(e.index)}>
          <TabPanel header={t('page.campaignDetails.viewTab.campaignDetails')}>
            {campaignId && <Navigate to={routePaths.campaignDetails.url(campaignId)} />}
            <CampaignDetails data={campaignDetailsData} invalidSections={invalidSections} />
          </TabPanel>
          {shouldDisplayPerformanceTab && (
            <TabPanel header={t('page.campaignDetails.viewTab.performance')}>
              {campaignId && <Navigate to={routePaths.campaignPerformance.url(campaignId)} />}
              <Performance
                campaignEndDate={campaignDetailsData.endDate}
                campaignStartDate={campaignDetailsData.startDate}
                campaignFormat={campaignDetailsData.formatType}
              />
            </TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}

export default CampaignDetailsPage;
