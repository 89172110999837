import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChartNoData } from '@features/campaigns/performance/components/ChartNoData/ChartNoData.tsx';
import MetricChart from '@features/campaigns/performance/components/MetricChart/MetricChart.tsx';
import {
  MetricChartValue,
  MetricChartValueType,
} from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import { formatDates } from '@features/campaigns/performance/components/MetricChart/utils/generateChartData.ts';
import MetricTypeButtons from '@features/campaigns/performance/components/MetricTypeButtons/MetricTypeButtons.tsx';
import { getKeyMetrics } from '@features/campaigns/performance/containers/Performance/utils/generateKeyMetricsData.ts';
import { fetchGeneralMetrics } from '@features/campaigns/performance/services/getGeneralMetrics.ts';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys.ts';
import { Highlights } from '@features/campaigns/shared/containers/Review/Highlights/Highlights.tsx';

import styles from './GeneralMetrics.module.scss';

interface GeneralMetricsProps {
  dateRange: string;
}

export function GeneralMetrics({ dateRange }: GeneralMetricsProps) {
  const { campaignId } = useParams();
  const [selectedMetricTypeButton, setSelectedMetricTypeButton] = useState<MetricChartValueType>(
    MetricChartValue.IMPRESSIONS,
  );

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_PERFORMANCE, campaignId],
    queryFn: async () => {
      if (!campaignId) {
        return null;
      }

      const data = await fetchGeneralMetrics(campaignId);

      if (data === null) {
        return null;
      }

      return formatDates(data);
    },
  });

  const keyMetricsData = getKeyMetrics(data);
  const isNoData = data === null || data === undefined;

  return (
    <div className={styles.wrapper}>
      {isFetching && <Spinner variant='elementOverlay' />}
      {isNoData ? (
        <ChartNoData />
      ) : (
        <>
          <section className={styles.keyMetricsSection} data-testid='key-metrics-section'>
            <Highlights data={keyMetricsData} className={styles.gridOverride3Columns} />
          </section>
          <section data-testid='chart-type-buttons-section'>
            <MetricTypeButtons
              selectedValue={selectedMetricTypeButton}
              setSelectedValue={setSelectedMetricTypeButton}
            />
            <MetricChart selectedChartType={selectedMetricTypeButton} data={data} dateRange={dateRange} />
          </section>
        </>
      )}
    </div>
  );
}
