import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { Campaign } from '@shared/utils/interfaces/campaign';

interface ActionsColumnBodyTemplate {
  campaign: Campaign;
  referenceToActionsPanel: MutableRefObject<null | OverlayPanel>;
  setClickedCampaign: Dispatch<SetStateAction<Campaign | undefined>>;
}

function ActionsColumnBodyTemplate({
  campaign,
  referenceToActionsPanel,
  setClickedCampaign,
}: ActionsColumnBodyTemplate) {
  return (
    <Button
      className={styles.actionButton}
      onClick={(e) => {
        setClickedCampaign(campaign);
        referenceToActionsPanel.current?.toggle(e);
      }}
      icon='pi pi-ellipsis-h'
      rounded
      text
      severity='help'
      data-testid='campaign-list-action-button'
    />
  );
}

export default ActionsColumnBodyTemplate;
