import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';
import { mockedBrandsData } from '@shared/mocks/requests/brands/brands.fixtures';

export const brandsRequests = [
  http.get(`*${apiRoutes.getBrands(':id')}`, () => {
    return HttpResponse.json(mockedBrandsData);
  }),
];
