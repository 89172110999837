export const formatSeconds = (seconds: number) => {
  const roundedSeconds = Math.round(seconds);

  if (roundedSeconds === 0) {
    return '0';
  }

  if (roundedSeconds < 60) {
    return `${roundedSeconds} sec`;
  }

  const minutes = Math.floor(roundedSeconds / 60);
  const remainingSeconds = roundedSeconds % 60;

  if (!remainingSeconds) {
    return `${minutes} min`;
  }

  return `${minutes} min ${remainingSeconds} sec`;
};
