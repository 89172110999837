import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

import styles from '@features/users/list/components/Table/ExternalUsersListTable.module.scss';
import { ExternalUser } from '@features/users/list/types/externalUser';
import { routePaths } from '@shared/router/routePaths';

interface ActionsColumnBodyTemplate {
  user: ExternalUser;
}

function ActionsColumnBodyTemplate({ user }: ActionsColumnBodyTemplate) {
  const navigate = useNavigate();

  return (
    <Button
      className={styles.actionButton}
      onClick={() => navigate(routePaths.userDetails.url(user.id))}
      icon='pi pi-pencil'
      rounded
      text
      severity='help'
      data-testid='redirect-to-edit-user-details'
    />
  );
}

export default ActionsColumnBodyTemplate;
