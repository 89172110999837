import { Button } from 'primereact/button';
import { SyntheticEvent } from 'react';

import styles from '@features/campaigns/builder/components/FormFields/common/FileUpload/components/FileItemTemplate/FileItemTemplate.module.scss';

interface FileItemTemplateProps {
  togglePreviewModal: () => void;
  fileName: string;
  fileURL: string;
  fileFormatSize: string;
  deleteItemCallback?: (event: SyntheticEvent) => void;
}

export function FileItemTemplate({
  togglePreviewModal,
  fileName,
  fileURL,
  fileFormatSize,
  deleteItemCallback,
}: FileItemTemplateProps) {
  return (
    <div className={styles.itemTemplateWrapper}>
      <div className={styles.fileInfo}>
        <div className={styles.thumbnailWrapper}>
          <button
            className={styles.thumbnailOverlay}
            type='button'
            onClick={togglePreviewModal}
            data-testid={'open-preview-modal-button'}
          >
            <i className={'pi pi-search-plus icon'} />
          </button>
          <img alt={fileName} role='presentation' src={fileURL} className={styles.thumbnail} />
        </div>
        <span className={styles.fileName}>
          {fileName}
          <small className={styles.fileSize}>{fileFormatSize}</small>
        </span>
      </div>
      {deleteItemCallback && (
        <Button
          type='button'
          icon='pi pi-trash'
          className={styles.deleteButton}
          onClick={deleteItemCallback}
          data-testid={'delete-file-button'}
          aria-label='Delete file'
        />
      )}
    </div>
  );
}
