import { z } from 'zod';

import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes.ts';

export const billingDetailsValidationSchema = z.object({
  [BillingFormFieldTypes.AGENCY]: z
    .string({
      required_error: 'builder.errorMessages.required',
    })
    .optional(),
  [BillingFormFieldTypes.BILLING_CONTACT]: z
    .string({
      required_error: 'builder.errorMessages.required',
    })
    .nonempty({
      message: 'builder.errorMessages.required',
    }),
  [BillingFormFieldTypes.PURCHASE_ORDER]: z
    .string({
      required_error: 'builder.errorMessages.required',
    })
    .optional(),
  [BillingFormFieldTypes.AUTHORIZED_BUDGET]: z
    .union([
      z
        .number({
          required_error: 'builder.errorMessages.required',
          invalid_type_error: 'builder.errorMessages.numericalValueRequired',
        })
        .min(1, 'builder.errorMessages.minAuthorizedBudget')
        .max(5000000, 'builder.errorMessages.maxAuthorizedBudget'),
      z.null(),
    ])
    .transform((value, ctx) => {
      if (value === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'builder.errorMessages.numericalValueRequired',
        });
      }
      return value;
    })
    .optional(),
});

export type BillingDetailsValues = z.infer<typeof billingDetailsValidationSchema>;
