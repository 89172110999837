import { DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';

import { DataTableState, SortField } from '@shared/types/table';

export const onPage =
  (dataTableState: DataTableState, setDataTableState: (newState: DataTableState) => void) =>
  (event: DataTablePageEvent) => {
    const newLazyState: DataTableState = { ...dataTableState };
    if (dataTableState.limit != event.rows) {
      newLazyState.limit = event.rows;
      newLazyState.page = 0;

      window.scrollTo(0, 0);
    } else if (dataTableState.page != event.page) {
      newLazyState.page = event.page ?? 0;
    }
    newLazyState.first = (event.page ?? 0) * dataTableState.limit;
    setDataTableState(newLazyState);
  };

export const onSort =
  (dataTableState: DataTableState, setDataTableState: (newState: DataTableState) => void) =>
  (event: DataTableSortEvent) => {
    const newLazyState: DataTableState = { ...dataTableState };
    if (!event.sortField) {
      newLazyState.sortField = undefined;
      newLazyState.sortOrder = undefined;
    } else if (event.sortField != dataTableState.sortField) {
      newLazyState.sortField = event.sortField as SortField;
      newLazyState.sortOrder = 1;
    } else {
      newLazyState.sortOrder = event.sortOrder;
    }
    newLazyState.page = 0;
    newLazyState.first = 0;
    setDataTableState(newLazyState);
  };
