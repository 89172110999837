import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import pageHeaderStyles from '@features/campaigns/shared/components/PageHeader/PageHeader.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { useLeaveGuardModalContext } from '@shared/providers/LeaveGuardModalProvider/useLeaveGuardModalContext';
import { routePaths } from '@shared/router/routePaths.ts';

function PageHeader() {
  const { t } = useTranslation([i18nNameSpace.USERS]);
  const navigate = useNavigate();

  const { toggleModal, isFormDirty } = useLeaveGuardModalContext();

  const handleCloseBuilderProcess = () => {
    if (isFormDirty) {
      return toggleModal();
    }

    navigate(routePaths.users);
  };

  return (
    <div className={cn(pageHeaderStyles.container, pageHeaderStyles.noAnimation)}>
      <div className='content-wrapper'>
        <header className={pageHeaderStyles.headerWrapper}>
          <h1 className={pageHeaderStyles.header}>{t('page.details.common.title')}</h1>
          <button
            className={pageHeaderStyles.close}
            data-testid='close-details-view-button'
            type='button'
            onClick={handleCloseBuilderProcess}
          >
            <i className='pi pi-times'></i>
          </button>
        </header>
      </div>
    </div>
  );
}

export default PageHeader;
