import cn from 'classnames';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { searchParams } from '@features/campaigns/builder/consts/searchParams.ts';
import { MediaTypeModal } from '@features/campaigns/list/components/MediaType/Modal/MediaTypeModal.tsx';
import { CampaignMediaType, CampaignMediaTypes } from '@features/campaigns/shared/types/campaign.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { routePaths } from '@shared/router/routePaths.ts';

import styles from './MediaTypeTrigger.module.scss';

interface MediaTypeTriggerProps {
  linkStyle?: boolean;
  className?: string;
}

export function MediaTypeTrigger({ linkStyle = false, className }: MediaTypeTriggerProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState<CampaignMediaType>(CampaignMediaTypes.CTV);

  const handleOpenModal = () => setIsModalOpen(!isModalOpen);

  const navigateToBuilderWithMediaType = () => {
    navigate({
      pathname: routePaths.campaignBuilderInitialize.url(),
      search: `?${searchParams.MEDIA_TYPE}=${selectedMediaType}`,
    });
  };

  return (
    <>
      <Button
        className={cn('p-button', styles.newCampaignButton, className, { [styles.linkStyle]: linkStyle })}
        link={linkStyle}
        data-testid='media-type-select-button'
        onClick={handleOpenModal}
      >
        <i className={cn('pi pi-plus', styles.newCampaignIcon)}></i>
        <span>{t('page.newCampaign')}</span>
      </Button>
      <MediaTypeModal
        isVisible={isModalOpen}
        toggleModalVisibility={handleOpenModal}
        selectedMediaType={selectedMediaType}
        setSelectedMediaType={setSelectedMediaType}
        submitCallback={navigateToBuilderWithMediaType}
      />
    </>
  );
}
