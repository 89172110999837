import { useTranslation } from 'react-i18next';

import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { ReviewSectionProps } from '@features/campaigns/shared/containers/Review/types.ts';
import { CampaignMediaType, CampaignMediaTypes } from '@features/campaigns/shared/types/campaign.ts';
import { Item } from '@shared/components/Review/Item/Item.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

export function MediaType({ headerText, data }: ReviewSectionProps<CampaignMediaType>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const mediaType = data === CampaignMediaTypes.CROSS ? 'Cross' : data;

  return (
    <SectionWrapper headerText={headerText}>
      <div className={'grid'} data-testid='media-type-section'>
        <Item label={t('review.labels.mediaType')} value={t('review.mediaType', { mediaType })} />
      </div>
    </SectionWrapper>
  );
}
