import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import { billingDetailsAccessRights } from '@features/campaigns/details/consts/billingDetailsAccessRights';
import { BillingDetailsData } from '@features/campaigns/details/containers/BillingDetails/BillingDetails.dto.ts';
import BillingDetails from '@features/campaigns/details/containers/BillingDetails/BillingDetails.tsx';
import { DetailsAndStatusData } from '@features/campaigns/details/containers/CampaignDetailsPage/CampaignDetailsPage.dto';
import StatusIcon from '@features/campaigns/shared/components/StatusIcon/StatusIcon';
import { AccessRight } from '@shared/api/commonApiInterfaces.ts';
import { Item } from '@shared/components/Review/Item/Item';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './DetailsAndStatus.module.scss';

interface DetailsAndStatusProps {
  data: DetailsAndStatusData;
  formData: BillingDetailsData;
  accessRights: AccessRight[];
}

const checkIfHasAnyBillingDetailsAccessRight = (accessRights: AccessRight[]) => {
  return billingDetailsAccessRights.some((billingDetailsAccessRight) =>
    accessRights.includes(billingDetailsAccessRight),
  );
};

function DetailsAndStatus({ data, formData, accessRights }: DetailsAndStatusProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const hasAnyBillingDetailsAccessRight = checkIfHasAnyBillingDetailsAccessRight(accessRights);

  return (
    <section className={styles.wrapper}>
      <header className={styles.header}>
        <h3 className={styles.text}>{t('page.campaignDetails.campaignDetailsTab.sectionTitles.detailsAndStatus')}</h3>
      </header>
      <div className={styles.cardContent} data-testid='details-and-status-section'>
        <div className={'col-12'}>
          <Item label={t('page.campaignDetails.campaignDetailsTab.labels.campaignStatus')}>
            <StatusIcon variant={data.campaignStatus} />
            {t(`page.list.campaignStatus.${data.campaignStatus.toLowerCase()}`)}
          </Item>
        </div>
        {/*<div className={'col-12'}>*/}
        {/*  /!* TODO: replace hardcoded billing status value *!/  */}
        {/* Placeholder temporary disabled, enable when billing status will be functional */}
        {/*  <Item label={t('page.campaignDetails.campaignDetailsTab.labels.billingStatus')} value={'Unpaid'} />*/}
        {/*</div>*/}
        <div className={'col-12 no-v-padding'}>
          <Item label={t('page.campaignDetails.campaignDetailsTab.labels.creator')}>
            <Avatar className={styles.avatar} icon='pi pi-user' shape='circle' />
            {data.creator}
          </Item>
        </div>

        <AllowAccess additionalCondition={hasAnyBillingDetailsAccessRight}>
          <BillingDetails data={formData} accessRights={accessRights} />
        </AllowAccess>
      </div>
    </section>
  );
}

export default DetailsAndStatus;
