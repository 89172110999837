import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ExternalUser } from '@features/users/list/types/externalUser';
import commonStyles from '@shared/components/Table/Table.module.scss';
import { routePaths } from '@shared/router/routePaths';

function NameColumnBodyTemplate(user: ExternalUser) {
  const navigate = useNavigate();
  return (
    <div className={cn(commonStyles.columnValues, commonStyles.nameValueContainer)}>
      <p className={commonStyles.nameValue} onClick={() => navigate(routePaths.userDetails.url(user.id))}>
        {user.givenName}
      </p>
    </div>
  );
}

export default NameColumnBodyTemplate;
