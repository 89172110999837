import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios, { isAxiosError, HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { toast } from 'react-toastify';

import { ErrorMessage } from '@shared/components/Toast/ErrorMessage/ErrorMessage.tsx';
import { WarningMessage } from '@shared/components/Toast/WarningMessage/WarningMessage';
import { i18nNameSpace } from '@shared/consts/i18n';
import { handleRetries } from '@shared/providers/TanstackQueryProvider/handleRetries';
import { routePaths } from '@shared/router/routePaths';
import { router } from '@shared/router/router.tsx';
import { isApiError } from '@shared/types/typeGuards';

const onErrorHandler = (error: unknown) => {
  if (isAxiosError(error) && isApiError(error) && error.response) {
    const apiError = error.response.data;

    toast.error(<ErrorMessage error={apiError} />, { toastId: apiError?.i18n?.key });
    return error;
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: handleRetries,
    },
  },
  queryCache: new QueryCache({
    onError: async (error) => {
      const isUuidMalformed =
        axios.isAxiosError(error) &&
        error.response?.status === HttpStatusCode.UnprocessableEntity &&
        error.response?.data?.details[0].fieldPath === 'campaignId';

      const is404Error = axios.isAxiosError(error) && error.response?.status === HttpStatusCode.NotFound;

      if (is404Error) {
        await router.navigate(routePaths.campaigns);
      }

      if (axios.isAxiosError(error) && error.response?.status === HttpStatusCode.Forbidden) {
        await router.navigate(routePaths.campaigns);
        return toast.warning(<WarningMessage message={t('api.warning.read.not_allowed', { ns: i18nNameSpace.API })} />);
      }

      if (isUuidMalformed) {
        await router.navigate(routePaths.campaigns);
        return toast.warning(
          <WarningMessage message={t('api.warning.campaign.not_found_uuid_malformed', { ns: i18nNameSpace.API })} />,
        );
      }

      onErrorHandler(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => onErrorHandler(error),
  }),
});

export function TanstackQueryProvider({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  );
}
