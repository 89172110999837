import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { duplicateCampaign } from '@features/campaigns/list/services/duplicateCampaign';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys';
import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { CampaignId } from '@shared/types/campaign';

import styles from './DuplicateCampaignModal.module.scss';

interface DuplicateCampaignModalProps {
  isVisible: boolean;
  toggleModalVisibility: () => void;
  campaignName: string | undefined;
  campaignId: CampaignId | undefined;
}

export function DuplicateCampaignModal({
  isVisible,
  toggleModalVisibility,
  campaignName,
  campaignId,
}: DuplicateCampaignModalProps) {
  const { t } = useTranslation([i18nNameSpace.CAMPAIGNS]);
  const queryClient = useQueryClient();

  const { mutate: duplicateCampaignMutation, isPending } = useMutation({
    mutationFn: (campaignId: CampaignId) => duplicateCampaign(campaignId),
    onSuccess: async () => {
      toast.success(
        <SuccessMessage
          message={t('api.success.campaign.campaignDuplicated', { ns: i18nNameSpace.API, campaignName })}
        />,
      );
      toggleModalVisibility();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGNS_LIST] });
    },
  });

  const handleSubmit = () => {
    if (!campaignId) {
      return null;
    }

    duplicateCampaignMutation(campaignId);
  };

  return (
    <Dialog
      visible={isVisible}
      onHide={toggleModalVisibility}
      draggable={false}
      resizable={false}
      header={t('page.list.modals.headers.duplicateCampaign')}
      className={styles.maxWidth}
      ariaCloseIconLabel={t('dialog.closeButton', { ns: i18nNameSpace.COMMON })}
      footer={
        <DefaultActionsFooter
          submitLabel={t('page.list.modals.duplicate')}
          cancelLabel={t('page.list.modals.cancel')}
          cancelCallback={toggleModalVisibility}
          submitCallback={handleSubmit}
          submitCallbackActionIsLoading={isPending}
        />
      }
    >
      <Trans t={t} i18nKey={'page.list.modals.content.duplicateConfirmation'} values={{ campaignName }} />
    </Dialog>
  );
}
