import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './WarningMessage.module.scss';

interface ToastMessageProps {
  message: string;
}

export function WarningMessage({ message }: ToastMessageProps) {
  const { t } = useTranslation(i18nNameSpace.API);

  return (
    <>
      <p className={styles.toastMessageTitle}>{t('api.warning.common.toastHeaderText')}</p>
      <span className={styles.toastMessageText}>{message}</span>
    </>
  );
}
