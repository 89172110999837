import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';
import { mockedCampaignDetails } from '@shared/mocks/requests/campaignDetails/campaignDetails.fixtures';

export const updateCampaignRequests = [
  http.put(`*${apiRoutes.updateCampaignStep01(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.put(`*${apiRoutes.updateCampaignCTVTargeting(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.put(`*${apiRoutes.updateCampaignCrossTargeting(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.put(`*${apiRoutes.updateCampaignCTVAdProducts(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.put(`*${apiRoutes.updateCampaignCrossAdProducts(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
  http.put(`*${apiRoutes.updateCampaignStep04(':id')}`, () => {
    return HttpResponse.json(mockedCampaignDetails);
  }),
];
