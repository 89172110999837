import { http, HttpResponse } from 'msw';

import { apiRoutes } from '@features/campaigns/builder/consts/apiRoutes';
import { mockedAdvertisersData } from '@shared/mocks/requests/advertisers/advertisers.fixtures';

export const advertisersRequests = [
  http.get(`*${apiRoutes.getAdvertisers()}`, () => {
    return HttpResponse.json(mockedAdvertisersData);
  }),
];
