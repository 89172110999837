import PageHeader from '@features/users/details/components/PageHeader/PageHeader';
import UserDetailsPage from '@features/users/details/containers/UserDetailsPage/UserDetailsPage.tsx';
import { LeaveGuardModalProvider } from '@shared/providers/LeaveGuardModalProvider/LeaveGuardModalProvider';

export default function UserDetailsPageLayout() {
  return (
    <LeaveGuardModalProvider>
      <PageHeader />
      <UserDetailsPage />
    </LeaveGuardModalProvider>
  );
}
