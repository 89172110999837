import cn from 'classnames';
import { format } from 'date-fns';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { Campaign } from '@shared/utils/interfaces/campaign';

function StartDateColumnBodyTemplate(campaign: Campaign) {
  const startDate = new Date(campaign.startDate);
  const formattedDate = format(startDate, 'dd/MM/yyyy');

  return <span className={cn(styles.columnValues, styles.startDateValue)}>{formattedDate}</span>;
}

export default StartDateColumnBodyTemplate;
