import cn from 'classnames';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { Campaign } from '@shared/utils/interfaces/campaign';
import { formatNumberWithCommas } from '@shared/utils/numberFormat';

function BudgetColumnBodyTemplate(campaign: Campaign) {
  return (
    <span className={cn(styles.columnValues, styles.budgetValue)}>€ {formatNumberWithCommas(campaign.budget)}</span>
  );
}

export default BudgetColumnBodyTemplate;
