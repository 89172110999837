import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileItemTemplate } from '@features/campaigns/builder/components/FormFields/common/FileUpload/components/FileItemTemplate/FileItemTemplate.tsx';
import { ImagePreviewModal } from '@features/campaigns/builder/components/FormFields/common/ImagePreviewModal/ImagePreviewModal';
import { CreativeImageType } from '@features/campaigns/builder/types/creativeImage';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface CreativePreviewProps {
  imageUrl: string;
  fileName?: string;
  creativeType: CreativeImageType;
  headerText: string;
}

export function CreativePreview({ imageUrl, fileName, creativeType, headerText }: CreativePreviewProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const currentFileName = fileName || 'builder.formFields.common.currentCreativeFile';

  const handleToggleModalVisibility = () => setIsPreviewModalVisible(!isPreviewModalVisible);

  return (
    <div>
      <FileItemTemplate
        fileName={t(currentFileName)}
        fileURL={imageUrl}
        fileFormatSize={''}
        togglePreviewModal={handleToggleModalVisibility}
      />
      <ImagePreviewModal
        isModalVisible={isPreviewModalVisible}
        toggleModalVisibility={handleToggleModalVisibility}
        imageUrl={imageUrl}
        creativeType={creativeType}
        headerText={headerText}
      />
    </div>
  );
}
