import { apiRoutes } from '@features/campaigns/shared/consts/apiRoutes.ts';
import { CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';
import { CampaignDetailsQuery } from '@features/campaigns/shared/types/campaignDetails';
import { apiClient } from '@shared/api/ApiClient.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export async function readCampaignDetails(
  campaignId: CampaignId,
  query: CampaignDetailsQuery,
): Promise<CampaignDetails | null> {
  return await apiClient.get<CampaignDetails>(apiRoutes.readCampaign(campaignId), { ...query });
}
