import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { TFunction } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { ChartNoData } from '@features/campaigns/performance/components/ChartNoData/ChartNoData.tsx';
import {
  CampaignPerformanceGeneralType,
  MetricChartProps,
  MetricChartValue,
  MetricChartValueType,
} from '@features/campaigns/performance/components/MetricChart/MetricChart.types.ts';
import {
  generateChartDataByDays,
  generateChartDataByHours,
  getBarChartOptions,
  getLineChartOptions,
} from '@features/campaigns/performance/components/MetricChart/utils/generateChartData.ts';
import commonDropdownStyles from '@features/campaigns/performance/components/shared/styles/GroupByDropdown.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './MetricChart.module.scss';

const METRIC_CHART_BY_DAYS_ID = 'metricChartByDays';
const METRIC_CHART_BY_HOURS_ID = 'metricChartByHours';

const tooltipVerticalLine: Plugin = {
  id: 'tooltipVerticalLine',
  afterDraw: (chart) => {
    if (
      (chart.canvas.id === METRIC_CHART_BY_DAYS_ID || chart.canvas.id === METRIC_CHART_BY_HOURS_ID) &&
      chart.tooltip?.opacity
    ) {
      const x = chart.tooltip.caretX;
      const yAxis = chart.scales.y;
      const ctx = chart.ctx;

      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#495057';
      ctx.stroke();
      ctx.restore();
    }
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  tooltipVerticalLine,
);

const getGroupByOptions = (t: TFunction<typeof i18nNameSpace.CAMPAIGNS>) => {
  return [
    {
      label: t('page.campaignDetails.performanceTab.metricChartSelectOptions.groupByDays'),
      value: 'days',
    },
    {
      label: t('page.campaignDetails.performanceTab.metricChartSelectOptions.groupByHours'),
      value: 'hours',
    },
  ];
};

function noDataForChart(data: CampaignPerformanceGeneralType, chartType: MetricChartValueType) {
  if (chartType === MetricChartValue.DEVICES) {
    return data?.summary.uniqueDevices === 0;
  }

  return data?.summary.impressions === 0;
}

function MetricChart({ data, selectedChartType, dateRange }: MetricChartProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const groupByOptions: SelectItemOptionsType = getGroupByOptions(t);
  const [groupBy, setGroupBy] = useState(groupByOptions[0].value);
  const chartDataByDays = generateChartDataByDays(data, selectedChartType, t);
  const chartDataByHours = generateChartDataByHours(data.impressionsByHourGraph, t);
  const isImpressionsChart = selectedChartType === MetricChartValue.IMPRESSIONS;
  const shouldRenderChartByHours = isImpressionsChart && groupBy === groupByOptions[1].value;

  const noDataForChartState = noDataForChart(data, selectedChartType);

  if (chartDataByDays) {
    return (
      <>
        {noDataForChartState ? (
          <ChartNoData />
        ) : (
          <div className={styles.wrapper}>
            {isImpressionsChart && (
              <Dropdown
                aria-label='group-by-date'
                value={groupBy}
                onChange={(event) => setGroupBy(event.value)}
                options={groupByOptions}
                className={commonDropdownStyles.groupByDropdown}
                data-testid='select-group-by-date'
              />
            )}
            {shouldRenderChartByHours ? (
              <Bar data={chartDataByHours} options={getBarChartOptions(dateRange)} id={METRIC_CHART_BY_HOURS_ID} />
            ) : (
              <Line
                data={chartDataByDays}
                options={getLineChartOptions(selectedChartType)}
                id={METRIC_CHART_BY_DAYS_ID}
              />
            )}
          </div>
        )}
      </>
    );
  }

  return <></>;
}

export default MetricChart;
