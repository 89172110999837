import { IconMap, IconVariants } from '@shared/components/Icon/IconMapper.ts';

import styles from './Icon.module.scss';

interface IconProps {
  stylesClassName?: 'accordionIcon' | 'emptyStateIcon' | 'highlightsIcon';
  iconAltText: string;
  variant: IconVariants;
}

export function Icon({ variant, stylesClassName, iconAltText }: IconProps) {
  const [icon, icon2x] = IconMap[variant];

  return (
    <picture>
      <source srcSet={icon2x} media='(min-resolution: 192dpi)' />
      <img src={icon} className={styles[stylesClassName || '']} alt={iconAltText} />
    </picture>
  );
}
