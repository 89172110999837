import { Button } from 'primereact/button';
import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface SectionWrapperProps {
  headerText: string;
  ctaButtonCallback?: () => void;
  additionalAction?: ReactElement;
}

export function SectionWrapper({
  children,
  headerText,
  ctaButtonCallback,
  additionalAction,
}: PropsWithChildren<SectionWrapperProps>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <section className={styles.wrapper}>
      <header className={styles.header}>
        <h3 className={styles.text}>{t(headerText)}</h3>
        {ctaButtonCallback && (
          <Button
            label={t('common.edit')}
            icon='pi pi-pencil'
            onClick={ctaButtonCallback}
            className={styles.button}
            text
          />
        )}
        {additionalAction && additionalAction}
      </header>
      <div className={styles.body}>{children}</div>
    </section>
  );
}
