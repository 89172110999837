import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys.ts';
import { deleteCampaign } from '@features/campaigns/shared/services/campaign/deleteCampaign.ts';
import { CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { CampaignId } from '@shared/types/campaign.ts';

interface UseDeleteCampaignProps {
  handleToggleDeleteModalVisibility: () => void;
  redirectToUrl?: string;
  refetchCampaignList?: boolean;
}

export default function useDeleteCampaign({
  handleToggleDeleteModalVisibility,
  redirectToUrl,
  refetchCampaignList = false,
}: UseDeleteCampaignProps) {
  const { t } = useTranslation([i18nNameSpace.API]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: deleteCampaignMutation, isPending: deleteCampaignIsPending } = useMutation({
    mutationFn: (campaignId: CampaignId) => deleteCampaign(campaignId) as Promise<CampaignDetails>,
    onError: () => {
      handleToggleDeleteModalVisibility();
    },
    onSuccess: async ({ name }: CampaignDetails) => {
      toast.success(<SuccessMessage message={t('api.success.campaign.deleted', { campaignName: name })} />);

      if (refetchCampaignList) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGNS_LIST] });
      }

      handleToggleDeleteModalVisibility();

      if (redirectToUrl) {
        navigate(redirectToUrl);
      }
    },
  });

  return { deleteCampaignMutation, deleteCampaignIsPending };
}
