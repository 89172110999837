import { advertisersRequests } from '@shared/mocks/requests/advertisers/advertisers';
import { agenciesRequests } from '@shared/mocks/requests/agencies/agencies.ts';
import { billingContactsRequests } from '@shared/mocks/requests/billingContacts/billingContacts';
import { brandsRequests } from '@shared/mocks/requests/brands/brands';
import { campaignDeleteRequests } from '@shared/mocks/requests/campaignDelete/campaignDelete.ts';
import { campaignDetailsRequests } from '@shared/mocks/requests/campaignDetails/campaignDetails';
import { campaignsListRequests } from '@shared/mocks/requests/campaignsList/campaignsList';
import { campaignSubmitRequests } from '@shared/mocks/requests/campaignSubmit/campaignSubmit';
import { campaignCreateRequests } from '@shared/mocks/requests/createCampaign/createCampaign';
import { creativesRequests } from '@shared/mocks/requests/creatives/creatives';
import { externalUsersListRequests } from '@shared/mocks/requests/externalUsersList/externalUsersList';
import { pricingRequests } from '@shared/mocks/requests/pricing/pricing';
import { reportingRequests } from '@shared/mocks/requests/reporting/reporting';
import { targetingRequests } from '@shared/mocks/requests/targeting/targeting';
import { updateCampaignRequests } from '@shared/mocks/requests/updateCampaign/updateCampaign';

export const handlers = [
  ...campaignsListRequests,
  ...campaignSubmitRequests,
  ...campaignDetailsRequests,
  ...advertisersRequests,
  ...brandsRequests,
  ...agenciesRequests,
  ...campaignCreateRequests,
  ...creativesRequests,
  ...pricingRequests,
  ...externalUsersListRequests,
  ...billingContactsRequests,
  ...reportingRequests,
  ...updateCampaignRequests,
  ...targetingRequests,
  ...campaignDeleteRequests,
];
