import { format } from 'date-fns';
import { Dispatch, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';

import { FilterName } from '@features/campaigns/list/components/Filters/consts/filtersName.ts';
import {
  Actions as campaignListActions,
  CampaignListReducerAction,
  CampaignListReducerState,
} from '@features/campaigns/list/types/campaignListReducer.ts';
import { CampaignFormat, CampaignStatus } from '@features/campaigns/shared/types/campaign.ts';
import { CampaignId } from '@shared/types/campaign.ts';

import { removeCampaignsListFilter, setCampaignsListFilter, useCampaignsListFilter } from './useFilters.ts';

interface FiltersURLSearchParamsType {
  state: CampaignListReducerState;
  searchParams: URLSearchParams;
  dispatch: Dispatch<CampaignListReducerAction>;
  setSearchParams: SetURLSearchParams;
  campaignId?: CampaignId;
}

export const useFiltersURLSearchParams = ({
  state,
  searchParams,
  setSearchParams,
  dispatch,
  campaignId,
}: FiltersURLSearchParamsType) => {
  const campaignsListState = state as CampaignListReducerState;

  const searchFilterValue = useCampaignsListFilter(FilterName.SEARCH);
  const campaignsListStatusFilterValue = useCampaignsListFilter(FilterName.STATUS);
  const campaignsListFormatFilterValue = useCampaignsListFilter(FilterName.FORMAT);
  const campaignsListStartDateFilterValue = useCampaignsListFilter(FilterName.START_DATE);
  const campaignsListEndDateFilterValue = useCampaignsListFilter(FilterName.END_DATE);

  useEffect(() => {
    if (searchFilterValue) {
      searchParams.set(FilterName.SEARCH, searchFilterValue);
      setSearchParams(searchParams);

      const dispatchTyped = dispatch as Dispatch<CampaignListReducerAction>;

      dispatchTyped({
        type: campaignListActions.CHANGE_SEARCH_QUERY,
        payload: { search: searchFilterValue },
      });
    }

    if (campaignsListStatusFilterValue) {
      searchParams.set(FilterName.STATUS, campaignsListStatusFilterValue);
      setSearchParams(searchParams);

      const dispatchTyped = dispatch as Dispatch<CampaignListReducerAction>;
      const splittedFilterValues = campaignsListStatusFilterValue.split(',') as CampaignStatus[];

      dispatchTyped({
        type: campaignListActions.CHANGE_STATUS_FILTER,
        payload: { filters: { status: splittedFilterValues } },
      });
    }

    if (campaignsListFormatFilterValue) {
      searchParams.set(FilterName.FORMAT, campaignsListFormatFilterValue);
      setSearchParams(searchParams);
      const splittedFilterValues = campaignsListFormatFilterValue.split(',') as CampaignFormat[];

      dispatch({
        type: campaignListActions.CHANGE_FORMAT_FILTER,
        payload: { filters: { format: splittedFilterValues } },
      });
    }

    if (campaignsListStartDateFilterValue) {
      searchParams.set(FilterName.START_DATE, campaignsListStartDateFilterValue);
      setSearchParams(searchParams);

      const startDate = new Date(campaignsListStartDateFilterValue);

      dispatch({
        type: campaignListActions.CHANGE_START_DATE_FILTER,
        payload: { filters: { startDate: startDate } },
      });
    }

    if (campaignsListEndDateFilterValue) {
      searchParams.set(FilterName.END_DATE, campaignsListEndDateFilterValue);
      setSearchParams(searchParams);

      const endDate = new Date(campaignsListEndDateFilterValue);

      dispatch({
        type: campaignListActions.CHANGE_END_DATE_FILTER,
        payload: { filters: { endDate: endDate } },
      });
    }
  }, []);

  useEffect(() => {
    if (campaignsListState.search && campaignsListState.search.length > 0) {
      searchParams.set(FilterName.SEARCH, campaignsListState.search);
      setSearchParams(searchParams);
      setCampaignsListFilter(FilterName.SEARCH, campaignsListState.search);
    } else {
      searchParams.delete(FilterName.SEARCH);
      setSearchParams(searchParams);
      removeCampaignsListFilter(FilterName.SEARCH);
    }
  }, [campaignsListState.search]);

  useEffect(() => {
    if (campaignsListState.filters?.status && campaignsListState.filters.status.length > 0) {
      searchParams.set(FilterName.STATUS, campaignsListState.filters.status.join(','));
      setSearchParams(searchParams);
      setCampaignsListFilter(FilterName.STATUS, campaignsListState.filters.status.join(','));
    } else {
      searchParams.delete(FilterName.STATUS);
      setSearchParams(searchParams);
      removeCampaignsListFilter(FilterName.STATUS);
    }
  }, [campaignsListState.filters?.status]);

  useEffect(() => {
    if (state.filters?.format && state.filters.format.length > 0) {
      searchParams.set(FilterName.FORMAT, `${state.filters.format}`);
      setSearchParams(searchParams);

      if (!campaignId) {
        return setCampaignsListFilter(FilterName.FORMAT, state.filters.format.join(','));
      }
    } else {
      searchParams.delete(FilterName.FORMAT);
      setSearchParams(searchParams);
      removeCampaignsListFilter(FilterName.FORMAT);
    }
  }, [state.filters?.format]);

  useEffect(() => {
    if (state.filters?.startDate) {
      const startDate = new Date(`${state.filters.startDate}`);
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');

      searchParams.set(FilterName.START_DATE, formattedStartDate);
      setSearchParams(searchParams);

      if (!campaignId) {
        return setCampaignsListFilter(FilterName.START_DATE, formattedStartDate);
      }
    } else {
      searchParams.delete(FilterName.START_DATE);
      setSearchParams(searchParams);
      removeCampaignsListFilter(FilterName.START_DATE);
    }
  }, [state.filters?.startDate]);

  useEffect(() => {
    if (state.filters?.endDate) {
      const endDate = new Date(`${state.filters.endDate}`);
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      searchParams.set(FilterName.END_DATE, formattedEndDate);
      setSearchParams(searchParams);

      if (!campaignId) {
        return setCampaignsListFilter(FilterName.END_DATE, formattedEndDate);
      }
    } else {
      searchParams.delete(FilterName.END_DATE);
      setSearchParams(searchParams);
      removeCampaignsListFilter(FilterName.END_DATE);
    }
  }, [state.filters?.endDate]);
};
