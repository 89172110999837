import { formatNumberAsMoney } from '@shared/utils/moneyFormat';
import {
  formatNumbersAsCompactNumbers,
  formatNumberWithCommas,
  formatNumberWithMaxTwoDigitsAfterSeparator,
} from '@shared/utils/numberFormat';

interface FormatValueType {
  isMoneyValue: boolean;
  isBudgetCase: boolean;
  isTimeSpent: boolean;
  isFrequencyValue: boolean;
  isEngagementValue?: boolean;
  isActivationValue?: boolean;
  value?: number;
}

export function formatValue({
  isMoneyValue,
  isBudgetCase,
  isTimeSpent,
  isFrequencyValue,
  isEngagementValue,
  isActivationValue,
  value,
}: FormatValueType): string {
  if (typeof value === 'undefined') {
    return '-';
  }

  if (isMoneyValue) {
    return formatNumberAsMoney(value, isBudgetCase ? 0 : 2);
  }

  if (isFrequencyValue) {
    return formatNumberWithMaxTwoDigitsAfterSeparator(value);
  }

  if (isEngagementValue) {
    return formatNumbersAsCompactNumbers(value);
  }

  if (isActivationValue) {
    return `${formatNumberWithMaxTwoDigitsAfterSeparator(value)} %`;
  }

  if (isTimeSpent) {
    return `${formatNumbersAsCompactNumbers(value)} sec`;
  }

  return formatNumberWithCommas(value);
}
