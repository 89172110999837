import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import ExportButton from '@features/campaigns/details/shared/components/ExportButton/ExportButton.tsx';
import { ExportMenu } from '@features/campaigns/details/shared/components/ExportMenu/ExportMenu.tsx';
import commonStyles from '@features/campaigns/shared/components/ExportMenu/ExportMenu.module.scss';
import ExportMenuItem from '@features/campaigns/shared/components/ExportMenu/ExportMenuItem/ExportMenuItem.tsx';
import {
  DownloadedFileExtension,
  DownloadedFileTypes,
} from '@features/campaigns/shared/types/exportFileDownloadType.ts';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Permissions } from '@shared/consts/permissions';
import { useExportFileDownload } from '@shared/hooks/useExportFileDownload.ts';

export default function ExportDetails() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const menu = useRef(null);
  const { setDownloadFileExtension, isLoading } = useExportFileDownload({
    fileType: DownloadedFileTypes.DETAILS,
  });

  const handleOnMenuItemClick = (fileExtension: DownloadedFileExtension) => {
    setDownloadFileExtension(fileExtension);
  };

  const items: MenuItem[] = [
    {
      items: [
        {
          label: t('page.campaignDetails.campaignDetailsTab.exportOptions.pptx'),
          className: commonStyles.menuItem,
          data: {
            fileExtension: 'pptx',
          },
          template: (item) => (
            <AllowAccess
              requiredPermissions={[
                Permissions.CAMPAIGN_EXPORT_ALL,
                Permissions.CAMPAIGN_EXPORT_OWNED,
                Permissions.CAMPAIGN_EXPORT_ASSIGNED_ADVERTISERS,
              ]}
            >
              <ExportMenuItem
                item={item}
                handleOnClick={() => handleOnMenuItemClick(item.data.fileExtension)}
                menuReference={menu}
              />
            </AllowAccess>
          ),
        },

        // TODO: handle in https://clearcodehq.atlassian.net/browse/PBLTL-563
        // {
        //   label: t('page.campaignDetails.campaignDetailsTab.exportOptions.oneFlight'),
        //   icon: 'pi pi-cloud-upload',
        //   className: commonStyles.menuItem,
        //   command: () =>
        //     alert(
        //       'Unhandled export functionality (One flight), don not worry,  we will take care of it in https://clearcodehq.atlassian.net/browse/PBLTL-563',
        //     ),
        // },
      ],
    },
  ];

  return (
    <AllowAccess
      requiredPermissions={[
        Permissions.CAMPAIGN_EXPORT_ALL,
        Permissions.CAMPAIGN_EXPORT_OWNED,
        Permissions.CAMPAIGN_EXPORT_ASSIGNED_ADVERTISERS,
      ]}
    >
      <ExportButton
        menuReference={menu}
        isLoading={isLoading}
        label='page.campaignDetails.campaignDetailsTab.labels.exportDetails'
        dataTestId='campaign-details-export-button'
      />
      <ExportMenu menuItems={items} menuRef={menu} dataTestId='export-details-menu' />
    </AllowAccess>
  );
}
