import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

import { DropdownItem, EnhancedDropdown } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { DropdownFooterTemplate } from '@features/campaigns/shared/components/FormFields/Dropdown/components/FooterTemplate/FooterTemplate';
import { DropdownItemTemplate } from '@features/campaigns/shared/components/FormFields/Dropdown/components/ItemTemplate/ItemTemplate';
import { LoadingItemTemplate } from '@features/campaigns/shared/components/FormFields/Dropdown/components/LoadingItemTemplate/LoadingItemTemplate';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';

interface DropdownWithSearchProps<T extends FieldValues, T2 extends Path<T>> {
  fieldName: string;
  componentRef: RefObject<EnhancedDropdown>;
  placeholder: string;
  disabled?: boolean;
  data: InfiniteData<AdManagerApiResponse<DropdownItem[]> | null> | undefined;
  isFetching: boolean;
  inputValue: string | undefined;
  setInputValue: Dispatch<SetStateAction<string | undefined>>;
  restoredInputValue: string | undefined;
  setSearchQueryValue: Dispatch<SetStateAction<string | undefined>>;
  fieldController: ControllerRenderProps<T, T2>;
  clearSelectedValuesHandler: () => void;
  setSelectedValueName?: (value: string | undefined) => void;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<AdManagerApiResponse<DropdownItem[]> | null, unknown>, Error>>;
}

export function DropdownWithSearch<T extends FieldValues, T2 extends Path<T>>({
  fieldName,
  componentRef,
  placeholder,
  disabled = false,
  data,
  isFetching = false,
  inputValue,
  setInputValue,
  restoredInputValue,
  setSearchQueryValue,
  fieldController,
  fetchNextPage,
  clearSelectedValuesHandler,
  setSelectedValueName,
}: DropdownWithSearchProps<T, T2>) {
  const items = data?.pages?.map((page) => page?.items).flat() || [];
  const currentItemsCount = items?.length;
  const totalItems = data?.pages?.at(-1)?.meta?.totalItems || 0;

  const handleOnUserInput = (event: DropdownChangeEvent) => {
    const value = event.target.value || '';
    const isUserInputEvent = !event.originalEvent;
    const canStoreQueryValue = value.length > 2;
    const shouldRestoreDefaultQuery = value.length === 0;

    if (isUserInputEvent) {
      setInputValue(event.target.value);
    }

    if (canStoreQueryValue && isUserInputEvent) {
      setSearchQueryValue(event.target.value);
    }

    if (shouldRestoreDefaultQuery) {
      clearSelectedValuesHandler();
      setInputValue(undefined);
      setSearchQueryValue(undefined);
    }
  };

  useEffect(() => {
    if (restoredInputValue) {
      setInputValue(restoredInputValue);
    }
  }, []);

  const handleOnChange = (event: DropdownChangeEvent, onChangeTrigger: (event: DropdownChangeEvent) => void) => {
    const pickedOptionLabel = items.find((item) => item?.value === event.value)?.name;

    const isValidUserSelectionEvent = event?.originalEvent?.type === 'click';

    const isClearFieldEvent = event?.originalEvent?.type === 'pointerup';

    if (isValidUserSelectionEvent || isClearFieldEvent) {
      onChangeTrigger(event);
      setInputValue(event.target.value);

      if (setSelectedValueName) {
        setSelectedValueName(pickedOptionLabel);
      }
    }

    if (isClearFieldEvent) {
      clearSelectedValuesHandler();
      setInputValue(undefined);
      setSearchQueryValue(undefined);
    }
  };

  return (
    <Dropdown
      {...fieldController}
      id={fieldName}
      inputId={fieldName}
      ref={componentRef}
      disabled={disabled}
      placeholder={placeholder}
      data-testid={`field-${fieldName}`}
      options={items}
      value={inputValue}
      optionLabel='nameWithCode'
      editable
      showClear
      clearIcon={'pi pi-times-circle'}
      onFocus={() => {
        componentRef.current?.show();
      }}
      onChange={(event) => {
        componentRef.current?.show();
        handleOnUserInput(event);

        if (event.originalEvent) {
          handleOnChange(event, fieldController.onChange);
        }
      }}
      virtualScrollerOptions={
        // virtualScrollerOptions doesn't work properly during test execution
        process.env.NODE_ENV !== 'test'
          ? {
              lazy: true,
              step: 20,
              onLazyLoad: async (event) => {
                const canFetchNextPage = event.last === currentItemsCount && currentItemsCount < totalItems;

                if (canFetchNextPage) {
                  await fetchNextPage();
                }
              },
              itemSize: 32,
              showLoader: true,
              loading: isFetching,
              loadingTemplate: <LoadingItemTemplate />,
            }
          : undefined
      }
      itemTemplate={DropdownItemTemplate}
      panelFooterTemplate={
        <DropdownFooterTemplate currentItemsCount={currentItemsCount} totalItemsCount={totalItems} />
      }
    />
  );
}
