interface CampaignBuilderRouteItem {
  url: (campaignId?: string) => `/campaigns/builder/${string}`;
  routerPath: string;
  activeStep: number;
}
export const campaignBuilderRoutePathsMap: Record<string, CampaignBuilderRouteItem> = {
  initialize: {
    url: () => `/campaigns/builder/campaign-details`,
    routerPath: `/campaigns/builder/campaign-details`,
    activeStep: 0,
  },
  step01: {
    url: (campaignId) => `/campaigns/builder/${campaignId}/campaign-details`,
    routerPath: `/campaigns/builder/:campaignId/campaign-details`,
    activeStep: 0,
  },
  step02: {
    url: (campaignId) => `/campaigns/builder/${campaignId}/target`,
    routerPath: `/campaigns/builder/:campaignId/target`,
    activeStep: 1,
  },
  step03: {
    url: (campaignId) => `/campaigns/builder/${campaignId}/ad-products`,
    routerPath: `/campaigns/builder/:campaignId/ad-products`,
    activeStep: 2,
  },
  step04: {
    url: (campaignId) => `/campaigns/builder/${campaignId}/availability-and-costs`,
    routerPath: `/campaigns/builder/:campaignId/availability-and-costs`,
    activeStep: 3,
  },
  step05: {
    url: (campaignId) => `/campaigns/builder/${campaignId}/review-and-submit`,
    routerPath: `/campaigns/builder/:campaignId/review-and-submit`,
    activeStep: 4,
  },
};

export const routePaths = {
  notFound: '*',
  serverDown: '/server-error',
  campaigns: '/',
  users: '/users',
  campaignBuilderInitialize: {
    routePath: campaignBuilderRoutePathsMap.initialize.routerPath,
    url: campaignBuilderRoutePathsMap.initialize.url,
  },
  campaignBuilderStep01: {
    routePath: campaignBuilderRoutePathsMap.step01.routerPath,
    url: campaignBuilderRoutePathsMap.step01.url,
  },
  campaignBuilderStep02: {
    routePath: campaignBuilderRoutePathsMap.step02.routerPath,
    url: campaignBuilderRoutePathsMap.step02.url,
  },
  campaignBuilderStep03: {
    routePath: campaignBuilderRoutePathsMap.step03.routerPath,
    url: campaignBuilderRoutePathsMap.step03.url,
  },
  campaignBuilderStep04: {
    routePath: campaignBuilderRoutePathsMap.step04.routerPath,
    url: campaignBuilderRoutePathsMap.step04.url,
  },
  campaignBuilderStep05: {
    routePath: campaignBuilderRoutePathsMap.step05.routerPath,
    url: campaignBuilderRoutePathsMap.step05.url,
  },
  campaignDetails: {
    routePath: '/campaigns/:campaignId/details',
    url: (campaignId: string) => `/campaigns/${campaignId}/details`,
  },
  campaignPerformance: {
    routePath: '/campaigns/:campaignId/details/performance',
    url: (campaignId: string) => `/campaigns/${campaignId}/details/performance`,
  },
  userDetails: {
    routePath: '/users/:userId/details',
    url: (userId: string) => `/users/${userId}/details`,
  },
} as const;
