import { FieldTypes } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { DEFAULT_CREATIVES_QUANTITY } from '@features/campaigns/builder/consts/common.ts';
import { UpdateCampaignFormStep03Values } from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import { Creative } from '@features/campaigns/builder/services/campaign/uploadCreatives.ts';
import {
  CampaignDetails,
  CampaignMediaType,
  CampaignMediaTypes,
  CampaignStatus,
  CampaignType,
  CampaignTypes,
  CREATIVE_FILE_TYPES,
  CreativeServices,
  InvalidSectionsItem,
} from '@features/campaigns/shared/types/campaign.ts';
import { isAdProductDisplayNonNull, isAdProductVideoNonNull } from '@features/campaigns/shared/types/typeGuards.ts';
import { ValueOf } from '@shared/types/common';

interface Step03PayloadDisplayComplex {
  enabled: boolean;
  creativeServices?: ValueOf<typeof CreativeServices>;
  numberOfCreatives?: number;
}
interface Step03PayloadDisplaySimple {
  enabled: boolean;
}
interface Step03PayloadVideo {
  enabled: boolean;
  vod?: boolean;
  linearAddressable?: boolean;
}
export type UpdateCampaignStep03Payload =
  | {
      displayComplex?: Step03PayloadDisplayComplex;
      displaySimple?: Step03PayloadDisplaySimple;
      video?: Step03PayloadVideo;
    }
  | {
      vod: true;
      linearAddressable: boolean;
    };

function step03VideoPayloadComposer(
  formValues: UpdateCampaignFormStep03Values,
  campaignType: CampaignType,
): Step03PayloadVideo {
  const { formatTypeVideo, videoProductsLinearAddressable } = formValues;

  if (!formatTypeVideo || campaignType === CampaignTypes.SELF_SERVICE) {
    return {
      enabled: false,
    };
  }

  return {
    enabled: true,
    vod: true,
    linearAddressable: videoProductsLinearAddressable,
  };
}

function step03DisplayComplexPayloadComposer({
  isCreativeServicesFull,
  numberOfCreatives,
  formatTypeDisplay,
}: UpdateCampaignFormStep03Values): Step03PayloadDisplayComplex {
  if (!formatTypeDisplay) {
    return {
      enabled: false,
    };
  }

  if (isCreativeServicesFull) {
    return {
      enabled: true,
      creativeServices: CreativeServices.FULL,
      numberOfCreatives: numberOfCreatives || DEFAULT_CREATIVES_QUANTITY,
    };
  }

  return {
    enabled: true,
    creativeServices: CreativeServices.LIGHT,
  };
}

export function updateCampaignStep03DTO(
  formValues: UpdateCampaignFormStep03Values,
  campaignType: CampaignType,
  mediaType: CampaignMediaType,
): UpdateCampaignStep03Payload {
  const { formatTypeDisplay, videoProductsLinearAddressable } = formValues;
  const video = step03VideoPayloadComposer(formValues, campaignType);

  if (mediaType === CampaignMediaTypes.CROSS) {
    return {
      vod: true,
      linearAddressable: Boolean(videoProductsLinearAddressable),
    };
  }

  if (campaignType === CampaignTypes.INTERNAL) {
    const displayComplex = step03DisplayComplexPayloadComposer(formValues);

    return {
      displayComplex,
      video,
    };
  } else {
    return {
      displaySimple: {
        enabled: !!formatTypeDisplay,
      },
      video,
    };
  }
}

interface UploadCreativesPayload {
  [CREATIVE_FILE_TYPES.LSHAPE]?: Creative;
  [CREATIVE_FILE_TYPES.LANDING]?: Creative;
}

function fileCanBeUploaded(file: File | 'string'): boolean {
  return file && typeof file !== 'string';
}
export function uploadCreativesDTO(formValues: UpdateCampaignFormStep03Values): UploadCreativesPayload {
  const { creativeImageLShaped, creativeImageLanding } = formValues;
  const uploadedLshapeImage = fileCanBeUploaded(creativeImageLShaped) ? creativeImageLShaped[0] : null;
  const uploadedLandingImage = fileCanBeUploaded(creativeImageLanding) ? creativeImageLanding[0] : null;

  const schema = {
    [CREATIVE_FILE_TYPES.LSHAPE]: { file: uploadedLshapeImage },
    [CREATIVE_FILE_TYPES.LANDING]: { file: uploadedLandingImage },
  };

  return Object.fromEntries(
    Object.entries(schema).filter(([, value]) => {
      return !!value.file;
    }),
  );
}

export interface Step03FormData {
  [FieldTypes.FORMAT_TYPE_VIDEO]: boolean;
  [FieldTypes.FORMAT_TYPE_DISPLAY]: boolean;
  [FieldTypes.VIDEO_PRODUCTS_LINEAR_ADDRESSABLE]: boolean;
  [FieldTypes.NUMBER_OF_CREATIVES]: number;
  [FieldTypes.IS_CREATIVE_SERVICES_FULL]: boolean;
  [FieldTypes.CREATIVE_L_SHAPED_IMAGE]: string | null;
  [FieldTypes.CREATIVE_LANDING_IMAGE]: string | null;
}

export interface RestoredStep03Data {
  formData: Step03FormData | undefined;
  invalidSections?: InvalidSectionsItem[];
  status: CampaignStatus;
}

export function restoreStep03DataDTO(data: CampaignDetails): RestoredStep03Data {
  const isFormatTypeVideoAvailable = isAdProductVideoNonNull(data.adProductVideo);
  const isFormatTypeDisplayAvailable = isAdProductDisplayNonNull(data.adProductDisplay);

  if (!isFormatTypeVideoAvailable && !isFormatTypeDisplayAvailable) {
    return {
      formData: undefined,
      invalidSections: data.invalidSections,
      status: data.status,
    };
  }

  const isLinearAddressableAvailable = isAdProductVideoNonNull(data.adProductVideo)
    ? data.adProductVideo.linearAddressable
    : true;

  const numberOfCreativesAvailable =
    isAdProductDisplayNonNull(data.adProductDisplay) && data.adProductDisplay.numberOfCreatives !== null
      ? data.adProductDisplay.numberOfCreatives
      : DEFAULT_CREATIVES_QUANTITY;

  const isCreativeServicesFullAvailable = isAdProductDisplayNonNull(data.adProductDisplay)
    ? data.adProductDisplay.creativeServices === CreativeServices.FULL
    : true;

  const lShapedImageUrl = isAdProductDisplayNonNull(data.adProductDisplay)
    ? data.adProductDisplay.lShapeImageUrl
    : null;

  const landingImageUrl = isAdProductDisplayNonNull(data.adProductDisplay)
    ? data.adProductDisplay.landingImageUrl
    : null;

  return {
    formData: {
      [FieldTypes.FORMAT_TYPE_VIDEO]: isFormatTypeVideoAvailable,
      [FieldTypes.FORMAT_TYPE_DISPLAY]: isFormatTypeDisplayAvailable,
      [FieldTypes.VIDEO_PRODUCTS_LINEAR_ADDRESSABLE]: isLinearAddressableAvailable,
      [FieldTypes.NUMBER_OF_CREATIVES]: numberOfCreativesAvailable,
      [FieldTypes.IS_CREATIVE_SERVICES_FULL]: isCreativeServicesFullAvailable,
      [FieldTypes.CREATIVE_L_SHAPED_IMAGE]: lShapedImageUrl,
      [FieldTypes.CREATIVE_LANDING_IMAGE]: landingImageUrl,
    },
    invalidSections: data.invalidSections,
    status: data.status,
  };
}
