import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes.ts';
import { AuthorizedBudgetPayload } from '@features/campaigns/details/containers/BillingDetails/BillingDetails.dto.ts';
import { CampaignDetails } from '@features/campaigns/shared/types/campaign.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export async function updateAuthorizedBudget(
  payload: AuthorizedBudgetPayload,
  campaignId: CampaignId,
): Promise<CampaignDetails | null> {
  return await apiClient.put<AuthorizedBudgetPayload, CampaignDetails>(
    apiRoutes.updateAuthorizedBudget(campaignId),
    payload,
  );
}
